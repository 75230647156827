import { FORM_ERROR } from "final-form";
import React, { Component } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import swal from "sweetalert";
import { DropdownListField } from "../../../../../components/field/DropdownListField";
import { HiddenField } from "../../../../../components/field/HiddenField";
import { TextInputField } from "../../../../../components/field/TextInputField";
import { LegacySubForm, SubForm } from "../../../../../components/SubForm";
import { resolverExibidos } from "../../../../../components/SubForm/SubForm";
import { TIPO_ROCHA_AFLORAMENTO_SEDIMENTAR } from "../../../../../util/constantes";
import { campoObrigatorioComMsgGenerica, campoObrigatorioDefinitivoComMsgGenerica, campoObrigatorioEmDefinitivo, validarSe } from "../../../../../util/validadores/index"
import { resolverRequestAmostras, resolverRequestBaseGeo } from "../AmostrasUtils/AmostraRequestUtils";
class AtividadesAssociadas extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tipoAtividade: [
        { id: "RECURSO_MINERAL", nome: "Recurso mineral" },
        { id: "AFLORAMENTO", nome: "Afloramento" }
      ],
      materialGeologico: []
    };

    this.colunasAtividadesAssociadas = [
      {
        text: "Tipo de atividade", datafield: "tipoAtividade", width: "15%",
        cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          let linhaFormatada = this.montarCellRenderPadrao(this.state.tipoAtividade, rowdata.tipoAtividade);
          return linhaFormatada;
        },
      },
      { text: "Número de campo", datafield: "numeroCampoAtividade", width: "15%" },
      {
        text: "Material geológico", datafield: "idMaterialGeologico", width: "61%",
        cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {

          let elemento = "";
          this.props.materiaisGeologicosAfloramento?.map(item => { if (item?.id?.toString() === rowdata?.idMaterialGeologico?.toString()) { elemento = item } });
          if (elemento === "") {
            this.props.materiaisGeologicosRecursoMineral?.map(item => { if (item?.id?.toString() === rowdata?.idMaterialGeologico?.toString()) { elemento = item } });
          }

          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${elemento.nome ?? ""}</div>`;
        },
      },
    ];

    this.refForm = null;

    this.datafieldsAtividadesAssociadas = [
      { name: "tipoAtividade", type: "string", map: "tipoAtividade" },
      { name: "numeroCampoAtividade", type: "string", map: "numeroCampoAtividade" },
      { name: "idMaterialGeologico", type: "string", map: "idMaterialGeologico" },
    ];
  }

  async componentDidMount() {
    this.setState({
      materialGeologico: await resolverRequestBaseGeo(`/materialgeologico/visita/${this.props.visitaSelecionada}`)
    })
  }



  onSubmitSubForm({ alteracoesForm }) {
    this.props.referenciaForm?.batch(() => {
      for (const [key, val] of Object.entries(alteracoesForm)) {
        this.props.referenciaForm?.mutators?.setValue(`${key}`, val);
      }
    });
  }

  montarCellRenderPadrao(items, nomeChave) {
    let nome = "";
    items?.map(item => {
      if (item?.id?.toString() === nomeChave?.toString()) {
        nome = item.nome;
      }
    });
    return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
  }

  atividadePermiteCompatibilizacao(entidade) {
    try{
      const amostrasDefinitivo = this.props?.referenciaForm?.getState()?.values?.modoCadastro === 'DEFINITIVO';
      const afloraDefinitivo = this.props.atividadeAflora && this.props.atividadeAflora.modoCadastro === 'DEFINITIVO';
      const idMaterialGeologico = entidade.idMaterialGeologico;

      return idMaterialGeologico && amostrasDefinitivo && afloraDefinitivo && entidade && entidade.tipoAtividade === 'AFLORAMENTO';
    }
    catch(err) {
      console.log('Erro ao verificar se o botão de compatibilização pode ser ativado', err);
      return false;
    }
  }

  async compatibilizarCampos(id, rowdata) {
    const idMaterialGeologico = parseInt(rowdata.rowData.idMaterialGeologico);

    if(!idMaterialGeologico) return;

    const classesPossiveis = [8, 9, 14, 16];

    const idClasse = this.props.referenciaForm.getState().values.idClasse;

    if(this.props.referenciaForm.getState().values.modoCadastro != 'DEFINITIVO')
      return;

   if(!classesPossiveis.find(id => id === idClasse))
    return swal({
        title: "A classe de amostra selecionada não possui compatibilização com o afloramento",
        icon: 'info',
        buttons: { confirm: 'Entendido' }
    }); 

    const alteracoesHidro = resolverExibidos(this.props.atividadeAflora.alteracoesHidrotermais);
    const alteracaoUtilizada = alteracoesHidro.find(a => a.idRocha === idMaterialGeologico);
    const tipoAlteracao = alteracaoUtilizada ? alteracaoUtilizada.idTipo : undefined;

    const intemperismos = resolverExibidos(this.props.atividadeAflora.intemperismos);
    const intemperismoUtilizado = intemperismos.find(i => i.idRocha === idMaterialGeologico)
    const grauIntemperismo = intemperismoUtilizado ? intemperismoUtilizado.idGrau : undefined;

    const rochas = resolverExibidos(this.props.atividadeAflora.rochas);
    const rochaUtilizada = rochas.find(r => r.id === idMaterialGeologico);
    const idsTexturasNovas = rochaUtilizada && rochaUtilizada.idsTexturas ? rochaUtilizada.idsTexturas : [];

    let listaTexturas = await resolverRequestAmostras("/biblioteca/texturarocha");
    let listaHidrotermal = await resolverRequestAmostras("/biblioteca/tipoalteracao"); 
    let listaIntemperismos = await resolverRequestAmostras("/biblioteca/grauintemperismo");

    let grauIntemperismoNovo = listaIntemperismos.find((item)=> item.id === grauIntemperismo);
    let tipoAlteracaoNovo = listaHidrotermal.find((item) => item.id === tipoAlteracao);
    let texturasNovo = idsTexturasNovas.map((id) => listaTexturas.find(item => item.id == id)); 

    let grauIntemperismoAntigo = undefined;
    let tipoAlteracaoAntigo = undefined;
    let texturasAntigas = [];

    let nomeClasse = 'rocha'
    let atributoGrauIntemperismo = 'idGrauIntemperismo';

    if(idClasse == 8)
      nomeClasse = 'minerio';

    else if(idClasse == 14)
      nomeClasse = 'outros';

    else if(idClasse == 16){
      nomeClasse = 'crosta';
      atributoGrauIntemperismo = 'idGrauIntemperismoCrosta';
    }

    let idsTexturasAntigas = this.props.formPropsPai.form.getState().values[nomeClasse]?.idsTexturasRocha;
    
    if(idsTexturasAntigas) 
      texturasAntigas = idsTexturasAntigas.map((id) => listaTexturas.find(item => item.id == id)); 

    grauIntemperismoAntigo = listaIntemperismos.find((item) => item.id == this.props.formPropsPai.form.getState().values[nomeClasse]?.[atributoGrauIntemperismo]);
    tipoAlteracaoAntigo = listaHidrotermal.find((item) => item.id == this.props.formPropsPai.form.getState().values[nomeClasse]?.idTipoAlteracao);
  
    let texto = '';
    
    texto = texto + "\nTextura";

    if (idClasse != 9 || rochaUtilizada?.idTipoRocha === TIPO_ROCHA_AFLORAMENTO_SEDIMENTAR.id)
      texto = texto + "\nNão se aplica";
    
    else {
      texto = texto + "\nComo está no Amostra: " + texturasAntigas.map(i => i.nome).toString().replace(/,/g, ', ');
      texto = texto + "\nComo ficará no Amostra: " + texturasNovo.map(i => i.nome).toString().replace(/,/g, ', ');
    }
   
    texto = texto + "\n\nGrau de intemperismo";
    texto = texto + "\nComo está no Amostra: " + (grauIntemperismoAntigo ? grauIntemperismoAntigo.nome : '');
    texto = texto + "\nComo ficará no Amostra: " + (grauIntemperismoNovo ? grauIntemperismoNovo.nome : '');

    texto = texto + "\n\nTipo de alteração hidrotermal";

    if(idClasse != 16){
      texto = texto + "\nComo está no Amostra: " + (tipoAlteracaoAntigo ? tipoAlteracaoAntigo.nome : '');
      texto = texto + "\nComo ficará no Amostra: " + (tipoAlteracaoNovo ? tipoAlteracaoNovo.nome : '');
    }

    else 
      texto = texto + "\nNão se aplica";

    const modalCompatibilizacao = (compatibilizarFunc) => {
      swal({
        title: `Há preenchimento no cadastro do Afloramento (${this.props.atividadeAflora.numeroCampo}).\n Deseja utilizar os dados ?`, 
        icon: 'info',
        buttons: { cancel: "Não, cancelar", confirm: { text: "Sim", value: true, className: 'btn-primary' } },
      })
      .then((value) => {
        if(value)
        swal({
          title: 'Resultado da compatibilização com o Afloramento',    
          text: `${texto}`,
          buttons: { cancel: "Não sincronizar", confirm: { text: "Confirmar", value: true, className: 'btn-primary' } },
        })
        .then((value) => {
          if(value)
            compatibilizarFunc();
        })
      })
    }

    const modalSemCamposParaCompatibilizar = () => {
      return swal({
        title: "O afloramento não possui campos para compatibilizar com a Amostra",
        icon: 'info',
        buttons: { confirm: 'Entendido' }
      }); 
    }

    switch (idClasse) {
      // Minerio
      case 8: {
        if((grauIntemperismo === null || grauIntemperismo === undefined || grauIntemperismo === '' ) && !tipoAlteracao)
          return modalSemCamposParaCompatibilizar(); 
        
        modalCompatibilizacao(() => {
          if(grauIntemperismo !== null && grauIntemperismo !== undefined && grauIntemperismo !== '')
            this.props.referenciaForm.mutators.setValue('minerio.idGrauIntemperismo', grauIntemperismo);

          if(tipoAlteracao)
            this.props.referenciaForm.mutators.setValue('minerio.idTipoAlteracao', tipoAlteracao);
        });
        break;
      }
    
      // Rocha
      case 9:
        if((grauIntemperismo === null || grauIntemperismo === undefined || grauIntemperismo === '') && !tipoAlteracao && (!idsTexturasNovas || !idsTexturasNovas.length))
          return modalSemCamposParaCompatibilizar(); 
      
        modalCompatibilizacao(() => {
          if(idsTexturasNovas && idsTexturasNovas.length)
            this.props.referenciaForm.mutators.setValue('rocha.idsTexturasRocha', idsTexturasNovas);

          if(grauIntemperismo !== null && grauIntemperismo !== undefined && grauIntemperismo !== '')
            this.props.referenciaForm.mutators.setValue('rocha.idGrauIntemperismo', grauIntemperismo);

          if(tipoAlteracao)
            this.props.referenciaForm.mutators.setValue('rocha.idTipoAlteracao', tipoAlteracao);
        });
        break;

      // Outros
      case 14:
        if((grauIntemperismo === null || grauIntemperismo === undefined || grauIntemperismo === '' ) && !tipoAlteracao)
          return modalSemCamposParaCompatibilizar(); 
      
        modalCompatibilizacao(() => {
          if(grauIntemperismo !== null && grauIntemperismo !== undefined && grauIntemperismo !== '')
            this.props.referenciaForm.mutators.setValue('outros.idGrauIntemperismo', grauIntemperismo);

          if(tipoAlteracao)
            this.props.referenciaForm.mutators.setValue('outros.idTipoAlteracao', tipoAlteracao);
        });
        break;

      // Crosta
      case 16:
        if(grauIntemperismo === null || grauIntemperismo === undefined || grauIntemperismo === '' )
          return modalSemCamposParaCompatibilizar(); 
    
        modalCompatibilizacao(() => {
          if(grauIntemperismo !== null && grauIntemperismo !== undefined && grauIntemperismo !== '')
            this.props.referenciaForm.mutators.setValue('crosta.idGrauIntemperismoCrosta', grauIntemperismo);
        });
        break;
    }
  }

  validacoesAtividadesAssociadas(valores, form) {
    let associacoes = form.getState().values.associacoes ?? [];
    associacoes = resolverExibidos(associacoes);
    let associacaoRepetida = false;
    const associacao = valores?.id;
    const material = valores?.idMaterialGeologico;
    if (valores) {
      associacoes.map((item) => {
        if (material) {
          if (item.idMaterialGeologico === material &&  item.id !== associacao){
            associacaoRepetida = true;
          }
        }
      })
    }
    if (associacaoRepetida) {
      return {
        [FORM_ERROR]: 'Não é possivel cadastrar associações repetidas.'
      }
    }
  }

  render() {
    return (
      <Card className="mb-3" style={{marginTop: '15px'}}>
        <Card.Body className="p-4 mt-3 mb-4">
          <Field  name={"modoCadastro"} subscription={{ value: true }}>
          {({ input: { value: modoCadastro = {} } }) => {

            const validar = modoCadastro === 'DEFINITIVO';

            return <Field name={"associacoes"} subscription={{ value: true }}>
            {({ input: { value: associacoes = {} } }) =>
              <LegacySubForm
                compatibilizar={this.compatibilizarCampos.bind(this)}
                podeCompatibilizar={this.atividadePermiteCompatibilizacao.bind(this)}
                permitirCompatibilizacao={true}
                exibirBotaoInsercao={this.props.permitirEdicao}
                permitirEdicao={this.props.permitirEdicao}
                permitirExclusao={this.props.permitirEdicao}
                permitirVisualizacao={!this.props.permitirEdicao}
                permitirInsercao={this.props.permitirAdicao ? true : false}
                tituloForm={
                  this.props.duplicata ? "Associação da amostra" : 
                  this.props.permitirAdicao
                    ? "Associação da amostra"
                    : <Card.Title>Associação de amostra <Card.Subtitle className="mt-2">{this.props.mensagemDesabilitado}</Card.Subtitle></Card.Title>
                    }
                nome="associacoes"
                onSubmit={this.onSubmitSubForm.bind(this)}
                colunas={this.colunasAtividadesAssociadas}
                campos={this.datafieldsAtividadesAssociadas}
                elementos={
                  this.props.formPropsPai.form.getState().values.associacoes
                }
                validar={(valores) => this.validacoesAtividadesAssociadas(valores, this.props.referenciaForm)}
                formSubscription={{}}
                renderForm={({ formProps }) => {
                  this.refForm = formProps.form;
                  return (
                    <>
                      <Row>
                        <Col md={12}>
                          <Card.Title>Associação da amostra</Card.Title>
                        </Col>
                      </Row>
                      <Field
                        component={HiddenField}
                        name="idAtividade"
                      />
                      <Row className="mb-4">
                        <Col md={4}>
                          <Field name={"tipoAtividade"} subscription={{ value: true }}>
                            {({ input: { value: tipoAtividade } }) => {

                              let elementos = [];

                              let hasAfloramento = false;
                              let hasRecmin = false;
                              try {
                                if (this.props.formPropsPai.form.getState().values?.associacoes){
                                  let associacoes = resolverExibidos(this.props.formPropsPai.form.getState().values?.associacoes);
                                  for (const aux of associacoes){
                                    if (aux.tipoAtividade === "AFLORAMENTO"){
                                      hasAfloramento = true;
                                    }
                                    if (aux.tipoAtividade === "RECURSO_MINERAL"){
                                      hasRecmin = true;
                                    }
                                  }
                                } 
                              } catch (error) {
                                console.log(error)
                              }

                              if (this.props.idAfloramentoDaAtividade && !hasAfloramento)
                                elementos.push({ id: "AFLORAMENTO", nome: "Afloramento" });                              

                              if (this.props.idRecursoMineralDaAtividade && !hasRecmin)
                                elementos.push({ id: "RECURSO_MINERAL", nome: "Recurso mineral" });
                              
                              if (tipoAtividade === "AFLORAMENTO") 
                                formProps.form.mutators.setValue("numeroCampoAtividade", this.props.numeroCampoAfloramento);
                              
                              if (tipoAtividade === "RECURSO_MINERAL") {
                                formProps.form.mutators.setValue("numeroCampoAtividade", this.props.numeroCampoRecursoMineral);
                                formProps.form.mutators.setValue("idMaterialGeologico", null);
                              }

                              return <Field
                                disabled={!this.props.permitirEdicao}
                                name="tipoAtividade"
                                component={DropdownListField}
                                label="Tipo de atividade"
                                dica="Selecione o tipo de atividade em que foi cadastrado o material geológico que você deseja associar a esta amostra"
                                elementos={elementos}
                                required
                                validate={campoObrigatorioComMsgGenerica(
                                  "Tipo de atividade"
                                )}
                              />
                            }}
                          </Field>
                        </Col>
                        <Col md={4}>
                          <Field name={"numeroCampoAtividade"} subscription={{ value: true }}>
                            {({ input: { value: numeroCampoAtividade } }) => {
                              let nomeVisita = "";
                              if (formProps.form.getState().values.tipoAtividade === "AFLORAMENTO") {
                                nomeVisita = this.props.numeroCampoAfloramento
                              }
                              if (formProps.form.getState().values.tipoAtividade === "RECURSO_MINERAL") {
                                nomeVisita = this.props.numeroCampoRecursoMineral
                              }
                              return <Field
                                name="numeroCampoAtividade"
                                component={TextInputField}
                                label="Número de campo"
                                dica="Número de campo para referência"
                                initialValue={nomeVisita}
                                disabled
                              />
                            }}
                          </Field>

                        </Col>
                        <Col md={4}>
                          <Field name={"tipoAtividade"} subscription={{ value: true }}>
                            {({ input: { value: tipoAtividade } }) => {

                              let elementos = [];
                              if (tipoAtividade === "AFLORAMENTO") {
                                formProps.form.mutators.setValue('idAtividade', this.props.idAfloramentoDaAtividade);
                                elementos = this.props.materiaisGeologicosAfloramento;
                                
                                return <Field
                                  disabled={!this.props.permitirEdicao}
                                  name="idMaterialGeologico"
                                  component={DropdownListField}
                                  label="Material geológico"
                                  dica="Selecione o material geológico cadastrado na atividade para associação a amostra descrita"
                                  elementos={elementos}
                                  required={validar}
                                  validate={validarSe(validar, campoObrigatorioComMsgGenerica("Material geológico"))}
                                />
                              }
                              if (tipoAtividade === "RECURSO_MINERAL") {
                                formProps.form.mutators.setValue('idAtividade', this.props.idRecursoMineralDaAtividade);
                                elementos = this.props.materiaisGeologicosRecursoMineral;
                              }

                              return <></>
                            }}
                          </Field>
                        </Col>
                      </Row>
                    </>
                  );
                }}
              />
            }
          </Field>
          }}
          </Field>
        </Card.Body>
      </Card>
    );
  }
}

export default AtividadesAssociadas;
