import axios from "axios";
import React, { useEffect, useState } from "react";

const Versao = () => {
  const sobreRecmin = axios.create({
    baseURL: process.env.REACT_APP_API_URL_RECMIN,
  });
  const [recminVersao, setRecminVersao] = useState();
  useEffect(() => {
    sobreRecmin
      .get("/versao/recmin")
      .then((response) => setRecminVersao(response.data))
      .catch((err) => {
        console.error("Ocorreu um erro" + err);
      });
  }, [sobreRecmin]);

  const sobreAflora = axios.create({
    baseURL: process.env.REACT_APP_API_URL_AFLORA,
  });
  const [afloraVersao, setAfloraVersao] = useState();
  useEffect(() => {
    sobreAflora
      .get("/versao/aflora")
      .then((response) => setAfloraVersao(response.data))
      .catch((err) => {
        console.error("Ocorreu um erro" + err);
      });
  }, [sobreAflora]);

  const sobreBasegeo = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });
  const [basegeoVersao, setBasegeoVersao] = useState();
  useEffect(() => {
    sobreBasegeo
      .get("/versao/basegeo")
      .then((response) => setBasegeoVersao(response.data))
      .catch((err) => {
        console.error("Ocorreu um erro" + err);
      });
  }, [sobreBasegeo]);

  const sobreAmostra = axios.create({
    baseURL:process.env.REACT_APP_AMOSTRAS_API_URL,
  });
  const [amostraVersao, setAmostraVersao] = useState();
  useEffect(() => {
    sobreAmostra
      .get("/versao/amostra")
      .then((response) => setAmostraVersao(response.data))
      .catch((err) => {
        console.error("Ocorreu um erro" + err);
      });
  }, [sobreAmostra]);
  var packageJsonInfo = require('./../../../package.json');
  const estilo = { margin: '35px'}

  return (
      <div style={estilo}>
        <h1>Versões dos módulos</h1>
        <h4>Versão do {packageJsonInfo.name}: {packageJsonInfo.version}</h4>
        <h4>Versão do Basegeo: {basegeoVersao}</h4>
        <h4>Versão do Amostra: {amostraVersao}</h4>
        <h4>Versão do Aflora: {afloraVersao}</h4>
        <h4>Versão do Recmin: {recminVersao}</h4>   
      </div>
  )
}

export default React.memo(Versao);