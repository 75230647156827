import React, {  useCallback, useState } from 'react';
import { Dropdown } from 'semantic-ui-react'
import "../menuLateral/MenuLateral.css";
import { useDispatch } from "react-redux";
import { efetuarLogoff } from "../../reducers/authReducers";
import { Modal } from "../../components/Sobre/modal/Modal/Modal";
import Footer from "../../components/Sobre/footer/Footer";
import ModalNavBar from '../Sobre/ModalNavBar/ModalNavBar';

const MenuLateral = ({}) => {
    const [modalInstitucionalAberto, setModalInstitucionalAberto] = useState(false);
    const [modalTecnicosAberto, setModalTecnicosAberto] = useState(false);

    const dispatch = useDispatch();

    const fecharModals = () => {
        setModalInstitucionalAberto(false);
        setModalTecnicosAberto(false);
    }

    const abrirModal = (modal) => {
        if(modal === 'tecnicos')
            setModalTecnicosAberto(true);
        else
            setModalInstitucionalAberto(true);
    }

    const logout = useCallback((e) => {
      dispatch(efetuarLogoff())
    }, [dispatch]);

    const navbar = <ModalNavBar exibirCompleto={false} exibirMenu={false} style={{padding: "unset",borderBottom: "none", height: "117px"}}/>
    const footer = <Footer />

    return (
        <div className='ajudaSair'>
          
          <div className='ajudaSair'>
            <div >
                  <Dropdown text='Ajuda' icon={null}>
                      <Dropdown.Menu  style={{ borderRadius: 0, marginTop: 8, marginLeft: -9 }} className='novoMenu'>
                          {/*
                            <Dropdown.Item style={{ borderRadius: 0, TextDecoration: 'none' }} className='item' text='Manuais' href={'/'} />
                            <Dropdown.Divider />
                            <Dropdown.Item style={{ borderRadius: 0, TextDecoration: 'none' }} className='item' text='Tutoriais' onClick={() => window.open('https://www.youtube.com/playlist?list=PLhUEW6fPG6Omt1x8MnaGFxJ0TVPOK5dyc', '_blank').focus()}/>
                            <Dropdown.Divider />  
                          */}   

                          <Dropdown style={{ borderRadius: 0 }} text='Créditos' pointing='left' className='link item'>
                              <Dropdown.Menu style={{ borderRadius: 0 }} className='novoMenu' >
                                  <Dropdown.Item style={{ borderRadius: 0, TextDecoration: 'none' }} className='item' text='Institucionais' onClick={() => abrirModal('institucionais')}/>
                                  <Dropdown.Item style={{ borderRadius: 0, TextDecoration: 'none' }} className='item' text='Técnicos' onClick={() => abrirModal('tecnicos')}/>
                              </Dropdown.Menu>
                          </Dropdown>
                           {/*
                          <Dropdown.Divider />
                          <Dropdown.Item style={{ borderRadius: 0, TextDecoration: 'none' }} className='item' text='Sobre'/> 
                          */}
                              

                      </Dropdown.Menu>
                  </Dropdown>


              <Modal
                tamanho="xl"
                aberto={modalInstitucionalAberto}
                aoFechar={fecharModals}
                titulo="Institucionais"
                scrollable={true}
                align="center"
                comNavBar={true}
                navbar={navbar}
                comFooter={false}
                footer={footer}
              >
                <table border="0" cellSpacing="0" cellPadding="0" width="100%">
                  <tbody><tr><td valign="top">

                      <p align="center"><font color="#082767"><strong>MINISTÉRIO DE MINAS E ENERGIA</strong></font></p>
                      
                      <div className="nome">Adolfo Sachsida</div>
                      <div className="cargo">Ministro de Estado</div>
                      
                      <p align="center"><font color="#082767"><strong>SECRETARIA DE GEOLOGIA, MINERAÇÃO E TRANSFORMAÇÃO MINERAL</strong></font></p>
                      
                      <div className="nome">Lilia Mascarenhas Sant´Agostinho</div>
                      <div className="cargo">Secretária Interina</div>

                      <p align="center"><font color="#082767"><strong>SERVIÇO GEOLÓGICO DO BRASIL - CPRM</strong></font></p>

                      <div className="nome">Pedro Paulo Dias Mesquita</div>
                      <div className="cargo">Diretor-Presidente</div>

                      <div className="nome">Márcio José Remédio</div>
                      <div className="cargo">Diretor de Geologia e Recursos Minerais</div>

                      <div className="nome">Paulo Afonso Romano</div>
                      <div className="cargo">Diretor de Infraestrutura Geocientífica</div>

                      <div className="nome">Alice Silva de Castilho</div>
                      <div className="cargo">Diretor de Hidrologia e Gestão Territorial</div>

                      <div className="nome">Valter Rodrigues Santos Sobrinho</div>
                      <div className="cargo">Chefe do Departamento de Geologia</div>

                      <div className="nome">Marcelo Esteves Almeida</div>
                      <div className="cargo">Chefe do Departamento de Recursos Minerais</div>

                      <div className="nome">Edgar Shinzato</div>
                      <div className="cargo">Chefe do Departamento de Informações Institucionais</div>

                      <div className="nome">Hiran Silva Dias</div>
                      <div className="cargo">Chefe da Divisão de Geoprocessamento</div>

                      <div className="nome">Ricardo Guimarães da Rocha Barcellos</div>
                      <div className="cargo">Chefe da Divisão de Informática</div>

                      <div className="nome">Guilherme Ferreira</div>
                      <div className="cargo">Chefe da Divisão de Geologia Econômica</div>

                      <div className="nome">Patrick Araujo dos Santos</div>
                      <div className="cargo">Chefe da Divisão de Geologia Básica</div>

                    </td></tr>
                  </tbody>
                </table>
              </Modal>
              
              <Modal
                tamanho="xl"
                aberto={modalTecnicosAberto}
                aoFechar={fecharModals}
                titulo="Técnicos"
                scrollable={true}
                comNavBar={true}
                navbar={navbar}
                comFooter={false}
                footer={footer}
              >
                <table border="0" cellSpacing="0" cellPadding="0" width="100%">
                  <tbody>
                  <tr>
                    <td width="100%" valign="top">
                      <div className="caixa_lov nome" align="center">
                        <h2>Coordenação Geral e desenvolvimento</h2>
                        <div className="nome">João Henrique Larizzatti</div>
                        <div className="cargo">Pesquisador em geociências, DSc./Escritório do Rio de Janeiro</div>

                        <div className="nome">Hiran Silva Dias</div>
                        <div className="cargo">Chefe da Divisão de Geoprocessamento</div>

                        <div className="nome">Tatiana Santos Araújo</div>
                        <div className="cargo">Analista de sistemas - Gerente de Projeto - Divisão de Geoprocessamento – DIGEOP</div>

                        <div className="nome">Iago Sousa Lima Costa</div>
                        <div className="cargo">Pesquisador em geociências, MSc.</div>

                        <div className="nome">Marcos Vinicius Ferreira</div>
                        <div className="cargo">Pesquisador em geociências, MSc.</div>
                      </div><br/>
                    </td>
                  </tr>
                  <tr>
                    <td width="100%" valign="top">
                      <div className="caixa_lov nome" align="center">
                        <h2>Desenvolvimento e Banco de Dados (SGB/CPRM)</h2>

                        <div className="nome">Alda Maria F. Rosa da Silva</div>
                        <div className="cargo">Analista de sistemas, MSc. - Desenvolvedor - Divisão de Geoprocessamento – DIGEOP</div>

                        <div className="nome">Ana Paula Naziozeno Ferreira</div>
                        <div className="cargo">Analista de sistemas - Administração de Dados/Banco de Dados – DIINFO</div>

                        <div className="nome">Isis Couto Pernambuco</div>
                        <div className="cargo">Analista de sistemas - Administração de Dados/Banco de Dados – DIINFO</div>

                        <div className="nome">Leonardo Brandão Araújo</div>
                        <div className="cargo">Analista de sistemas - Desenvolvedor - Divisão de Geoprocessamento – DIGEOP</div>

                        <div className="nome">Sandro Braga Maia</div>
                        <div className="cargo">Analista de sistemas - Desenvolvedor - Divisão de Geoprocessamento – DIGEOP</div>

                        <div className="nome">Vinício Silveira Balbi</div>
                        <div className="cargo">Analista de sistemas - Desenvolvedor - Divisão de Geoprocessamento – DIGEOP</div>

                      </div><br/>
                    </td>
                  </tr>
                  <tr>
                      <td width="100%" valign="top">
                        <div className="caixa_lov nome" align="center">
                          <h2 align="center"><font color="#082767"><strong>Colaboradores</strong></font></h2>

                          <h4 style={{marginTop: "20px"}}>DIVISÃO DE GEOLOGIA BÁSICA - DIGEOB</h4>

                          <div className="nome">Vladimir Cruz de Medeiros</div>
                          <div className="cargo">Pesquisador em geociências, DSc.</div>

                          <h4 style={{marginTop: "20px"}}>ESCRITÓRIO DO RIO DE JANEIRO</h4>
                          <div className="nome">Alessandra Pacheco Cardoso Moreira</div>
                          <div className="cargo">Técnica em geociências, MSc.</div>

                          <h4 style={{marginTop: "20px"}}>SEDE</h4>
                          <div className="nome">Evandro Luiz Klein</div>
                          <div className="cargo">Pesquisador em geociências, DSc.</div>

                          <h4 style={{marginTop: "20px"}}>SUREG - BE</h4>
                          <div className="nome">Lúcia Travassos da Rosa Costa</div>
                          <div className="cargo">Pesquisadora em geociências, DSc.</div>

                          <h4 style={{marginTop: "20px"}}>SUREG - GO</h4>
                          <div className="nome">Paulo César Barbosa Júnior</div>
                          <div className="cargo">Técnico em geociências,</div>

                          <h4 style={{marginTop: "20px"}}>SUREG - RE</h4>
                          <div className="nome">Francisco Valdir Silveira</div>
                          <div className="cargo">Pesquisador em geociências, DSc.</div>

                          <h4 style={{marginTop: "20px"}}>SUREG - SP</h4>
                          <div className="nome">Eduardo Jorge Pinto Azevedo</div>
                          <div className="cargo">Pesquisador em geociências,</div>

                          <div className="nome">Elizete Domingues Salvador</div>
                          <div className="cargo">Pesquisadora em geociências, MSc.</div>


                        </div><br/>
                      </td>
                    </tr>
                
                </tbody></table>
              </Modal> 
            </div>
          </div>
          <div className='ajudaSair'>|</div>
          <div className='ajudaSair'>
          <button className='botaoSair' onClick={logout}>Sair</button>
          </div>  
        </div>
    )
}

export default MenuLateral;
