import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import ModalBS from "react-bootstrap/Modal"
import BlockUi from "react-block-ui";
import { Table } from "../../../../components/Jqx/Table";
import { useRef, useState } from "react";
import Service, { VALIDACAO } from "../../../../service/Service";

import { datafieldsAtividades } from "../../DataFieldsAtividadesValidacao";
import { colunasAtividadesPendencias } from "./ColumnsAtividadesPendencias";
import { FocusableButton } from "../../../../components/Jqx/Button";

import './index.css';
const PendenciasModal = ({
    titulo,
    mostrarBotaoFechar = true,
    aberto,
    aoFechar,
    tamanho = "sm" }) => {

    const tableRefPendencias = useRef(null);
    const [listaAtividadesPendencias, setListaAtividadesPendencias] = useState();
    const atualizaAtividadesPendencias = async () => {
        const idProjeto = JSON.parse(sessionStorage.getItem("localidade")).idProjeto;
        const idArea = JSON.parse(sessionStorage.getItem("localidade")).idArea;
        const respostaAtividades = await Service(
            `/atividades/atividadesRascunho/${idProjeto}`,
            VALIDACAO
        ).get(idArea);
        const atividadesPendencias = respostaAtividades.data;

        const atividadesRascunhoAtualizadas = atividadesPendencias.map((atividade) => {
            return { ...atividade, flagRascunho: atividade.rascunho === true ? "Rascunho" : "Definitivo" };
        });

        setListaAtividadesPendencias(atividadesRascunhoAtualizadas);
    }

    useEffect(() => {
        if (listaAtividadesPendencias)
            return;
        atualizaAtividadesPendencias();
    }, [listaAtividadesPendencias])

    const TabelaPendencias = useCallback(() => {
        if (!listaAtividadesPendencias)
            return <></>
        return <Table
            jqxRef={tableRefPendencias}
            datafields={datafieldsAtividades}
            columns={colunasAtividadesPendencias}
            items={listaAtividadesPendencias}
            editable
        />

    }, [listaAtividadesPendencias]);

    return <>
        <ModalBS size={tamanho}
            show={aberto}
            onHide={aoFechar}
            scrollable={true}
            enforceFocus={false}
            centered
        >
            <ModalBS.Header closeButton={mostrarBotaoFechar}>
                {titulo &&
                    <ModalBS.Title>
                        {titulo}
                    </ModalBS.Title>
                }
            </ModalBS.Header>
            <ModalBS.Body>
                <div className="modal-pendencias-body">
                    <BlockUi>
                        <TabelaPendencias />
                    </BlockUi>
                </div>
            </ModalBS.Body>
            <ModalBS.Footer>
                <FocusableButton
                    className="m-2"
                    onClick={aoFechar}>
                    Voltar
                </FocusableButton>
            </ModalBS.Footer>
        </ModalBS>
    </>
}

PendenciasModal.propTypes = {
    componenteAtividade: PropTypes.node,
    titulo: PropTypes.string,
    mostrarBotaoFechar: PropTypes.bool,
    aberto: PropTypes.bool,
    aoFechar: PropTypes.func,
    tamanho: PropTypes.oneOf(['sm', 'lg', 'xl']),
}

export { PendenciasModal };
