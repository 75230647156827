// Arquivo utilizado para definir validadores do final-form, swals e outras funções
// utilitárias relacionadas a validação

import dayjs from "dayjs"
import { ARRAY_ERROR, FORM_ERROR } from "final-form"
import { array } from "prop-types"
import swal from "sweetalert"
import { resolverExibidos } from "../../components/SubForm/SubForm"

export const valorNumericoEntre = ({
  min = Number.MIN_SAFE_INTEGER,
  max = Number.MAX_SAFE_INTEGER,
}) => (value) => {
  if (!isFinite(value)) return "Digite um valor numérico"
  if (value < min || value > max) return `Digite um valor entre ${min} e ${max}`
}

export const campoObrigatorio = ({
  mensagem = "Valor obrigatório",
  funcaoVerificacaoExistencia = (v) => (typeof v === 'number'|| (undefined !== v && null != v && '' !== v && [] !== v && (Array.isArray(v) ? v.length > 0 : true)))
} = {}) => (value, allValues, meta) => {
  // this can return a promise

  const valorPresente = funcaoVerificacaoExistencia(value)
  if (!valorPresente) {
    return mensagem
  }
}

export const campoObrigatorioEmDefinitivo = ({
  mensagem = "Valor obrigatório",  
  funcaoVerificacaoExistencia = (v) => (typeof v === 'number'|| (undefined !== v && null != v && '' !== v && [] !== v && (Array.isArray(v) ? v.length > 0 : true)))
} = {}) => (value, allValues, meta) => {
  // this can return a promise
  console.log("TESTESSS : " )
  
  const valorPresente = funcaoVerificacaoExistencia(value)
  if (!valorPresente && allValues.modoCadastro == 'DEFINITIVO') {
    return mensagem
  }
}


export const campoObrigatorioDefinitivoComMsgGenerica = (nomeCampo, opts) => campoObrigatorioEmDefinitivo({
  ...opts,  
  mensagem: opts && opts.forcarMensagemEnviada ? `${nomeCampo}` : `O campo "${nomeCampo}" é obrigatório`
})

export const campoObrigatorioComMsgGenerica = (nomeCampo, opts) => campoObrigatorio({
  ...opts,
  mensagem: opts && opts.forcarMensagemEnviada ? `${nomeCampo}` : `O campo "${nomeCampo}" é obrigatório`
})

export const valorObrigatorioSeIndformarSelecionado = (valor)  => {
  if (!valor) {
    return `Um valor deve ser informado`;
  }
}

export const campoNumericoIntervaloDefinidoObrigatorio = (nomeCampo, min, max) => v => {
  const numero = Number(String(v).replace(',', '.'));
  
  if (undefined === v || null === v || '' === v || isNaN(numero)) return `O campo "${nomeCampo}" é obrigatório`;
  if (numero > max || numero < min) {
    return `O campo ${nomeCampo} deve possuir um valor entre ${min} e ${max}`;
  }
}

export const comporValidadores = (...validadores) => (value, allValues, meta) => {
  for (const validador of validadores) {
    const resultado = validador(value, allValues, meta)
    if (resultado) {
      return resultado
    }
  }
}

export const validarSe = (condicao, ...validadores) => {
  if (condicao) {
    return comporValidadores(...validadores)
  }
}

export const validarSeCampoFoiPreenchido = (valor) => {
  const funcaoVerificacaoExistencia = (v) => (typeof v === 'number'|| (undefined !== v && null != v && '' !== v && [] !== v && (Array.isArray(v) ? v.length > 0 : true)));
  return funcaoVerificacaoExistencia(valor);
}
export const geraNomeMineralizacao = (valor, modo) => {
  if (validarSeCampoFoiPreenchido(valor) && modo) {
    return '*';
  } else {
    return;
  }
}
export const camposDeClassificacaoDeposito = () => {
  return {
    [FORM_ERROR]: 'É obrigatório preencher os campos de Classificação do depósito em Metalogenia.'
  }
}

export const validacoesOcorrenciaMineral = ((valores, formPai) => {
  let qntFonteReferencia;
  if (valores.fontesReferencia !== undefined) {
    qntFonteReferencia = resolverExibidos(valores?.fontesReferencia);
  }

  let substanciaInvalida = false;
  let substanciasForm = resolverExibidos(valores.substancias ?? []);
  let substanciasGerais = resolverExibidos(formPai?.getState()?.values?.substancias ?? []);
  let arrayIdsSubstanciasForm = [];
  let arrayIdsSubstanciasGerais = [];

  substanciasForm?.forEach(substancia => {
    arrayIdsSubstanciasForm.push(substancia.idTipoSubstancia);
  })
  substanciasGerais.forEach(substancia => {
    arrayIdsSubstanciasGerais.push(substancia.idTipoSubstancia)
  })

  arrayIdsSubstanciasForm.forEach(item => {
    if (!arrayIdsSubstanciasGerais.includes(item)) {
      substanciaInvalida = true;
    }
  })

  if (substanciaInvalida) {
    return {
      [FORM_ERROR]: 'Existem substâncias invalidas ou excluidas associadas a ocorrência mineral.'
    }
  }

  if ((qntFonteReferencia?.length === 0 || valores.fontesReferencia === undefined || valores.fontesReferencia === null) && verificaSubstanciasVazias(valores)) {
    return {
      [FORM_ERROR]: 'Ao menos uma fonte de referência deve ser cadastrada. Ao menos uma substância deve ser cadastrada.'
    }
  }

  if (qntFonteReferencia?.length === 0 || valores.fontesReferencia === undefined || valores.fontesReferencia === null) {
    return {
      [FORM_ERROR]: 'Ao menos uma fonte de referência deve ser cadastrada'
    }
  }
  if (verificaSubstanciasVazias(valores)) {
    return {
      [FORM_ERROR]: 'Ao menos uma substância deve ser cadastrada na Ocorrência Mineral.'
    }
  }
  let duplicados = [];
  let ordens = [];
  if (valores.substancias) {
    let substancias = resolverExibidos(valores.substancias);
    substancias.map(substancia => {
      if (substancia.ordemNomenclatura !== undefined) {
        ordens.push(substancia.ordemNomenclatura)
      }
      return 0
    })
    duplicados = ordens.filter((item, index) => ordens.indexOf(item) !== index);
    if (duplicados.length > 0) {
      return {
        [FORM_ERROR]: 'Substâncias não podem ter ordens de nomenclatura repetidas'
      }
    }
  }
})

export const validacoesEstudosMetalogeneticos = ((valores) => {
  let qntFonteReferencia;
  let controleEstrutural = [];
  if (valores?.metalogenia?.dadosInterpretativos?.controleMineralizacao?.controlesEstruturais) {
    controleEstrutural = resolverExibidos(valores.metalogenia.dadosInterpretativos.controleMineralizacao.controlesEstruturais);
  }
  if (valores.fontesReferencia !== undefined) {
    qntFonteReferencia = resolverExibidos(valores?.fontesReferencia);
  }

  let mensagensErro = ""
  if (qntFonteReferencia?.length === 0 || valores.fontesReferencia === undefined || valores.fontesReferencia === null) {
    mensagensErro += "Ao menos uma fonte de referência deve ser cadastrada. ";
  }

  if (validarSeCampoFoiPreenchido(valores.metalogenia?.dadosInterpretativos?.idFormasHospedeiras)) {
    let estruturais;
    if(validarSeCampoFoiPreenchido(valores?.metalogenia?.dadosInterpretativos?.controleMineralizacao?.controlesEstruturais)){
      estruturais = resolverExibidos(valores?.metalogenia?.dadosInterpretativos?.controleMineralizacao?.controlesEstruturais);
    }

    if (( !estruturais > 0)
          && (!validarSeCampoFoiPreenchido(valores?.metalogenia?.dadosInterpretativos?.controleMineralizacao?.controleEstratigrafico?.idTipoControle))
            && (!validarSeCampoFoiPreenchido(valores?.metalogenia?.dadosInterpretativos?.controleMineralizacao?.controleLitologico)))
      mensagensErro += "Ao preencher Forma (relação hospedeira) é obrigatório preencher ao menos um Controle de mineralização. ";
  }

  if (mensagensErro.length > 0)
    return {
      [FORM_ERROR]: mensagensErro
    }

})

export const validacoesAtividadeExploracao = ((valores) => {

  if (valores.dataInicial && valores.dataFinal) {
    let dataInicial = dayjs(valores.dataInicial.split('/').reverse().join('-'));
    let dataFinal = dayjs(valores.dataFinal.split('/').reverse().join('-'));
    if (dataInicial.isAfter(dataFinal)) return { [FORM_ERROR]: "A data inicial não pode ser posterior a data final." };
    if (dataFinal.isBefore(dataInicial)) return { [FORM_ERROR]: "A data final não pode ser anterior a data inicial." };
  }

  let qntFonteReferencia;
  if (valores.fontesReferencia !== undefined) {
    qntFonteReferencia = resolverExibidos(valores?.fontesReferencia);
  }

  if (qntFonteReferencia?.length === 0 || valores.fontesReferencia === undefined || valores.fontesReferencia === null) {
    return {
      [FORM_ERROR]: 'Ao menos uma fonte de referência deve ser cadastrada'
    }
  }
}
);

export const validacoesAtividadeMineracao = ((valores) => {

  if (valores.inicioAtividade && valores.terminoAtividade) {
    let dataInicial = dayjs(valores.inicioAtividade.split('/').reverse().join('-'));
    let dataFinal = dayjs(valores.terminoAtividade.split('/').reverse().join('-'));
    if (dataInicial.isAfter(dataFinal)) return { [FORM_ERROR]: "A data de início da atividade não pode ser posterior a data final da atividade." };
    if (dataFinal.isBefore(dataInicial)) return { [FORM_ERROR]: "A data de término da atividade não pode ser anterior a data de início da atividade." };
  }

  let qntFonteReferencia;
  if (valores.fontesReferencia !== undefined) {
    qntFonteReferencia = resolverExibidos(valores?.fontesReferencia);
  }

  if (qntFonteReferencia?.length === 0 || valores.fontesReferencia === undefined || valores.fontesReferencia === null) {
    return {
      [FORM_ERROR]: 'Ao menos uma fonte de referência deve ser cadastrada'
    }
  }
}
);

export const validarVisitaEstacao = ((valores) => {

  if (valores.dataInicio && valores.dataTermino) {
    let dataInicial = dayjs(valores.dataInicio.split('/').reverse().join('-'));
    let dataFinal = dayjs(valores.dataTermino.split('/').reverse().join('-'));
    if (dataInicial.isAfter(dataFinal)) return { [FORM_ERROR]: "A data de inicio não pode ser posterior a data de término." };
    if (dataFinal.isBefore(dataInicial)) return { [FORM_ERROR]: "A data de término não pode ser anterior a data de inicio." };
  }
}

);

export const validacoesDeposito = ((valores) => {

  let qntFonteReferencia;
  if (valores.fontesReferencia !== undefined) {
    qntFonteReferencia = resolverExibidos(valores?.fontesReferencia);
  }


  if ((qntFonteReferencia?.length === 0 || valores.fontesReferencia === undefined || valores.fontesReferencia === null) && verificaSubstanciasVazias(valores)) {
    return {
      [FORM_ERROR]: 'Ao menos uma fonte de referência deve ser cadastrada. Ao menos uma substância deve ser cadastrada.'
    }
  }

})

export const fonteReferenciaVazia = ((valores) => {
  let qntFonteReferencia;
  if (valores.fontesReferencia !== undefined) {
    qntFonteReferencia = resolverExibidos(valores?.fontesReferencia);
  }

  if (qntFonteReferencia?.length === 0 || valores.fontesReferencia === undefined || valores.fontesReferencia === null) {
    return {
      [FORM_ERROR]: 'Ao menos uma fonte de referência deve ser cadastrada'
    }
  }
}
);

export const validaçãoBaseProjetosFonteReferencia = ((valores) => {
  if (valores.modoCadastro === "BASE_PROJETOS" && !valores.idProjeto && !valores.link) {
    return {
      [FORM_ERROR]: 'No modo base de projetos, ao menos um dos campos deve ser preenchido'
    }
  }
});

export const validacaoResiduosMineracaoAtividadeMineracao = ((valores) => {
  if (!valores.idTipoResiduo && !valores.idTipoArmazenamento) {
    return {
      [FORM_ERROR]: 'Ao menos Tipo de resíduo ou Tipo de armazenamento deve ser preenchido'
    }
  }
});

export const ordemNomenclaturaSubstancia = ((valores) => {
  let duplicados = [];
  let ordens = [];
  if (valores.substancia) {
    let substancias = resolverExibidos(valores.substancia);
    substancias.map(substancia => {
      if (substancia.ordemNomenclatura !== undefined) {
        ordens.push(substancia.ordemNomenclatura)
      }
      return 0
    })
    duplicados = ordens.filter((item, index) => ordens.indexOf(item) !== index);
    if (duplicados.length > 0) {
      return {
        [FORM_ERROR]: 'Substâncias não podem ter ordens de nomenclatura repetidas'
      }
    }
  }
});

export const substanciaPrincipalDiferente = ((valores) => {
  let possuiPrincipal = 0;
  if (valores.substancias !== undefined) {
    let substancias = resolverExibidos(valores.substancias);
    substancias.map(substancia => {
      if (substancia.importancia === "PRINCIPAL") {
        possuiPrincipal++;
      }
      return 0
    });
    if (possuiPrincipal > 1) {
      return {
        [FORM_ERROR]: 'Apenas uma substância principal deve ser cadastrada'
      }
    }
  } else {
    return {
      [FORM_ERROR]: 'Uma substância principal deve ser cadastrada'
    }
  }
});

export const unidadeMedidaSemTeor = ((valores) => {
  if (!valores.teor && valores.idUnidadeMedidaTeor) {
    return {
      [FORM_ERROR]: 'Não é possível cadastrar uma unidade de medida sem teor'
    }
  }

  if (valores.teor && !valores.idUnidadeMedidaTeor) {
    return {
      [FORM_ERROR]: 'Não é possível cadastrar um teor sem unidade de medida'
    }
  }
}
);

export const unidadeMedidaSemTeorCorte = ((valores) => {

  //Condição do Teor rec. para Unidade de medida do teor

  if (!valores.idUnidadeMedidaCalculo && (valores.recursoMedido || valores.recursoIndicado || valores.recursoInferido || valores.recursoMedidoIndicado || valores.recursoMedidoIndicadoInferido || valores.recursoIndicadoInferido || valores.totalRecurso) )  {
    return {
      [FORM_ERROR]: 'Unidade de medida do cálculo é obrigatório.'
    }
  }

  if (valores.idUnidadeMedidaCalculo && (!valores.recursoMedido && !valores.recursoIndicado && !valores.recursoInferido && !valores.recursoMedidoIndicado && !valores.recursoMedidoIndicadoInferido && !valores.recursoIndicadoInferido && !valores.totalRecurso) ) {
    return {
      [FORM_ERROR]: 'Um valor de recurso é obrigatório'
    }
  }
  if (!valores.idUnidadeMedidaTeor && (valores.recursoMedidoTeor || valores.recursoIndicadoTeor || valores.recursoInferidoTeor || valores.recursoMedidoIndicadoTeor || valores.recursoMedidoIndicadoInferidoTeor || valores.recursoIndicadoInferidoTeor || valores.totalRecursoTeor)) {
    return {
      [FORM_ERROR]: 'Unidade de medida do teor é obrigatório.'
    }
  }
  if (valores.idUnidadeMedidaTeor && (!valores.recursoMedidoTeor && !valores.recursoIndicadoTeor && !valores.recursoInferidoTeor && !valores.recursoMedidoIndicadoTeor && !valores.recursoMedidoIndicadoInferidoTeor && !valores.recursoIndicadoInferidoTeor && !valores.totalRecursoTeor)) {
    return {
      [FORM_ERROR]: 'Um valor de teor rec. é obrigatório'
    }
  }
  if (!valores.idUnidadeMedida && (valores.recursoMedidoSubstanciaContida || valores.recursoIndicadoSubstanciaContida || valores.recursoInferidoSubstanciaContida || valores.recursoMedidoIndicadoSubstanciaContida || valores.recursoMedidoIndicadoInferidoSubstanciaContida || valores.recursoIndicadoInferidoSubstanciaContida || valores.recursoMedidoIndicadoInferidoSubstanciaContida || valores.totalRecursoSubstanciaContida)) {
    return {
      [FORM_ERROR]: 'Unidade de medida da substância contida é obrigatório.'
    }
  }
  if (valores.idUnidadeMedida && (!valores.recursoMedidoSubstanciaContida && !valores.recursoIndicadoSubstanciaContida && !valores.recursoInferidoSubstanciaContida && !valores.recursoMedidoIndicadoSubstanciaContida && !valores.recursoMedidoIndicadoInferidoSubstanciaContida && !valores.recursoIndicadoInferidoSubstanciaContida && !valores.recursoMedidoIndicadoInferidoSubstanciaContida && !valores.totalRecursoSubstanciaContida)) {
    return {
      [FORM_ERROR]: 'Um valor de Subs. cont. rec. é obrigatório'
    }
  }
});


export const validacaoReserva = ((valores) => {

  // Condição unidade de medida do cálculo para

  if (!valores.idUnidadeMedidaCalculo && (valores.reservaProvavel || valores.reservaProvada || valores.reservaProvadaProvavel || valores.totalReserva)) {
    return {
      [FORM_ERROR]: 'Unidade de medida do cálculo é obrigatório.'
    }
  }

  if (valores.idUnidadeMedidaCalculo && !valores.reservaProvavel && !valores.reservaProvada && !valores.reservaProvadaProvavel && !valores.totalReserva) {
    return {
      [FORM_ERROR]: 'Um valor de reserva é obrigatório.'
    }
  }

  //Condição de unidade de medida do teor para teor reserva

  if (!valores.idUnidadeMedidaTeor && (valores.reservaProvavelTeor || valores.reservaProvadaTeor || valores.reservaProvadaProvavelTeor || valores.totalReservaTeor)) {
    return {
      [FORM_ERROR]:'Unidade de medida do teor é obrigatório.'
    }
  }
  if (valores.idUnidadeMedidaTeor && (!valores.reservaProvavelTeor && !valores.reservaProvadaTeor && !valores.reservaProvadaProvavelTeor && !valores.totalReservaTeor)) {
    return {
      [FORM_ERROR]: 'Um valor de teor reserva é obrigatório.'
    }
  }
  //Condição unidade de medida da substancia contida para substancia contida

  if (!valores.idUnidadeMedida && (valores.reservaProvavelSubstanciaContida || valores.reservaProvadaSubstanciaContida || valores.reservaProvadaProvavelSubstanciaContida || valores.totalReservaSubstanciaContida)) {
    return {
      [FORM_ERROR]: 'Unidade de medida da substância contida é obrigatório.'
    }
  }
  if (valores.idUnidadeMedida && (!valores.reservaProvavelSubstanciaContida && !valores.reservaProvadaSubstanciaContida && !valores.reservaProvadaProvavelSubstanciaContida && !valores.totalReservaSubstanciaContida)) {
    return {
      [FORM_ERROR]: 'Um valor de substância contida reserva é obrigatório.'
    }
  }
});

export const preenchimentoControleMineiracao = ((valores) => {
  if ((valores.metalogenia?.dadosInterpretativos?.controleMineralizacao?.controleEstratigrafico?.idTipoControle === undefined || valores.metalogenia?.dadosInterpretativos?.controleMineralizacao?.controleEstratigrafico?.idTipoControle === '') &&
    (valores.metalogenia?.dadosInterpretativos?.controleMineralizacao?.controleLitologico?.idTipoControle === undefined || valores.metalogenia.dadosInterpretativos.controleMineralizacao.controleLitologico.idTipoControle === '') &&
    (valores.metalogenia?.dadosInterpretativos?.controlesEstruturais === undefined || valores.metalogenia.dadosInterpretativos.controleMineralizacao.controlesEstruturais === '')
  ) {
    return {
      [FORM_ERROR]: 'É obrigatório cadastrar um tipo de controle de mineiração ou um controle estrutural'
    }
  }

}
);

export const contemValoresDe = (array1, array2) => {
  return array2?.every(elemento => array1?.includes(elemento))
}

export const rochaContemMinerais = ((valores, modoDefinitivoSelecionado) => {
  if(modoDefinitivoSelecionado) {
    if (!valores.mineraisNomenclatura || contemValoresDe([null], resolverExibidos(valores?.mineraisNomenclatura))) {
      return {
        [FORM_ERROR]: 'Rochas devem possuir ao menos um mineral.'
      }
    }

    const proporcaoTotalMinerais = mineraisSomamCemPorCento(valores);

    if (proporcaoTotalMinerais > 100) {
      return {
        [FORM_ERROR]: 'Minerais selecionados devem compor no máximo até 100% da rocha.'
      }
    }
  }

  return 0;
})

export const totalizarProporcaoMinerais = ((minerais) => {
  const somaPorcentagem = minerais?.length > 0 ?
    minerais
      ?.map(it => it?.proporcao || 0)
      ?.reduce((accumulator, currentValue,) => accumulator + currentValue)
    : 0;
  return parseFloat(somaPorcentagem? somaPorcentagem.toFixed(2): 0);
})

export const mineraisSomamCemPorCento = ((valores) => {
  const minerais = resolverExibidos(valores?.mineraisNomenclatura || {}) || [];
  return totalizarProporcaoMinerais(minerais);
})

export const temMineraisComProporcaoOpcional = ((valores) => {
  const minerais = resolverExibidos(valores?.mineraisNomenclatura || {}) || [];
  return minerais?.some((mineral) => (
    [null, undefined].includes(mineral?.proporcao)
  ));
})

export const temMineraisComProporcaoOpcionalExceto = ((mineralAtual, valores) => {
  const minerais = resolverExibidos(valores?.mineraisNomenclatura || {}) || [];
  return minerais?.some((mineral) => (
    [null, undefined].includes(mineral?.proporcao) && mineral?.id !== mineralAtual?.id
  ));
})

export const temMineraisComProporcaoPreenchida = ((valores) => {
  const minerais = resolverExibidos(valores?.mineraisNomenclatura || {}) || [];
  return minerais?.some((mineral) => (
    ![null, undefined].includes(mineral?.proporcao) && mineral?.proporcao > 0
  ));
})

export const validacoesGeraisAfloramento = (valores) => {
  const formValues = valores;
  let naoPossuiRochas = false;


  if(valores.modoCadastro === "DEFINITIVO") {
    let erros = [];
    let errosAfloramento = [];
    let erroPrincipal = undefined;

    // Afloramento
    if(!formValues.comprimentoAfloramento)
      errosAfloramento.push('O campo "Comprimento" é obrigatório');

    if(typeof formValues.idTipoAfloramento !== 'number')
      errosAfloramento.push('O campo "Tipo do afloramento" é obrigatório');

    if(!formValues.informacoesRelevantes)
      errosAfloramento.push('O campo "Informações relevantes" é obrigatório');

    if(!formValues.larguraAfloramento)
      errosAfloramento.push('O campo "Largura/Altura (m)" é obrigatório');

    if(!formValues.rochas){
      errosAfloramento.push('Afloramentos devem possuir ao menos uma rocha.');
      erroPrincipal = 'Afloramentos devem possuir ao menos uma rocha.'
    }
    else {
      const rochas = resolverExibidos(formValues.rochas).filter(i => i != null);

      if(rochas.length) {
        const somatoria = rochas
        .map((rocha) => rocha?.estimativaPercentual)
        .reduce((acumulador, valorAtual) => acumulador + valorAtual);

        if(somatoria != 100){
          errosAfloramento.push('A estimativa percentual das rochas não somam 100%. Será necessário revisar o cadastro de rochas');
          erroPrincipal = 'A estimativa percentual das rochas não somam 100%. Será necessário revisar o cadastro de rochas';
        }
      }
      else {
        errosAfloramento.push('Afloramentos devem possuir ao menos uma rocha.');
        erroPrincipal = 'Afloramentos devem possuir ao menos uma rocha.';
      }
    }

    // Rochas
    if(formValues.rochas) {
      const rochas = resolverExibidos(formValues.rochas).filter(i => i != null);

      let erroRochas = false;

      rochas.forEach(r => {
        if(r.idTipoRocha === 1 || r.idTipoRocha === 8) {
          if(!r.idRochaNomeada || !r.estimativaPercentual || !r.idsGranulacoes ||
            !r.idsGranulacoes.length || !r.idsTexturas || !r.idsTexturas.length || !r.idsIndicesCor || !r.idsIndicesCor.length) {
              erroRochas = true;
          }
        }

        else if(r.idTipoRocha === 2) {
          if(!r.idRochaNomeada || !r.estimativaPercentual || !r.idsGranulacoes ||
            !r.idsGranulacoes.length || !r.idsTexturas || !r.idsTexturas.length) {
              erroRochas = true;
          }
        }

        else if(r.idTipoRocha === 7) {
          if(!r.idRochaNomeada || !r.estimativaPercentual || !r.idsGranulacoes ||
            !r.idsGranulacoes.length || !r.idsTexturas || !r.idsTexturas.length) {
              erroRochas = true;
          }

          if(r.clastos) {
            const clastos = resolverExibidos(r.clastos).filter(i => i != null);

            clastos.forEach(c => {
              if(!c.idRochaNomeada || !c.idsMinerais || !c.idsMinerais.length)
                erroRochas = true;
            });
          }
        }

        else if(r.idTipoRocha === 5) {
          if(!r.idRochaNomeada || !r.estimativaPercentual) {
              erroRochas = true;
          }

          if(r.clastos) {
            const clastos = resolverExibidos(r.clastos).filter(i => i != null);

            clastos.forEach(c => {
              if(!c.idRochaNomeada || !c.idsMinerais || !c.idsMinerais.length)
                erroRochas = true;
            });
          }
        }

      });

      if(erroRochas)
        erros.push("Rochas")
    }

    // Estruturas primarias
    if(formValues.estruturasPrimarias) {
      const estruturasPrimarias = resolverExibidos(formValues.estruturasPrimarias).filter(i => i != null);
      let erroEstruturasPrimarias = false;
      
      estruturasPrimarias.forEach(p => {
        if(!p.idsRochas || !p.idsRochas.length || !p.tipoEstruturaPrimaria || !typeof p.idTipo ==='number')
          erroEstruturasPrimarias = true;
      });

      if(erroEstruturasPrimarias)
        erros.push("Estruturas primarias");
    }

    // Estruturas tectonicas
    if(formValues.estruturasTectonicas) {
      const estruturasTectonicas = resolverExibidos(formValues.estruturasTectonicas).filter(i => i != null);
      let erroEstruturasTectonicas = false;

      estruturasTectonicas.forEach(t => {
        if(!t.idsRochas || !t.idsRochas.length || !typeof t.idTipo ==='number')
          erroEstruturasTectonicas = true;
      });

      if(erroEstruturasTectonicas)
        erros.push("Estruturas tectonicas");
    }

    // Metamorfismos
    if(formValues.metamorfismos) {
      const metamorfismos = resolverExibidos(formValues.metamorfismos).filter(i => i != null);
      let erroMetamorfismos = false;

      metamorfismos.forEach(m => {
        if(!m.idsRochas || !m.idsRochas.length || !typeof m.idTipo ==='number'|| ! typeof m.idFacies === 'number')
        erroMetamorfismos = true;
      });

      if(erroMetamorfismos)
        erros.push("Metamorfismos");
    }

    // Alterações hidrotermais
    if(formValues.alteracoesHidrotermais) {
      const alteracoesHidrotermais = resolverExibidos(formValues.alteracoesHidrotermais).filter(i => i != null);
      let erroAlteracoesHidrotermais = false;
      
      alteracoesHidrotermais.forEach(r => {
        if(!r.idRocha || !r.idsMinerais|| !r.idsMinerais?.length || !typeof r.idIntensidadeAlteracao === 'number' )
          erroAlteracoesHidrotermais = true;
      });

      if(erroAlteracoesHidrotermais)
        erros.push("Alterações hidrotermais");
    }

    // Rochas de enclave
    if(formValues.rochasEnclave) {
      const rochasEnclave = resolverExibidos(formValues.rochasEnclave).filter(i => i != null);
      let erroRochasEnclave = false;

      rochasEnclave.forEach(r => {
        if(!r.idsRochas || !r.idsRochas.length || !r.idRochaHospedeira || ! typeof r.idTipo === 'number')
          erroRochasEnclave = true;
      });

      if(erroRochasEnclave)
        erros.push("Rochas de enclave");
    }

    // Relações de contato
    if(formValues.relacoesContato) {
      const relacoesContato = resolverExibidos(formValues.relacoesContato).filter(i => i != null);
      let erroRelacoesContato = false;

      relacoesContato.forEach(r => {
        if(!r.idsRochas || !r.idsRochas.length || !typeof r.idTipoContato ==='number' || !r.descricaoRelacaoContato)
          erroRelacoesContato = true;
      });

      if(erroRelacoesContato)
        erros.push("Relações de contato");
    }

    // Intemperismos
    if(formValues.intemperismos) {
      const intemperismos = resolverExibidos(formValues.intemperismos).filter(i => i != null);
      let erroInt = false;

      intemperismos.forEach(i => {
        if(!i.idRocha || i.idGrau === undefined || i.idGrau === null || i.idGrau === '')
          erroInt = true;
      });


      if(erroInt)
        erros.push("Intemperismos");
    }

    if(erros.length) {
      errosAfloramento.push('Existem ' + erros.toString().replace(/,/g, ', ') + ' com campos obrigatórios não preenchidos.');

      if(!erroPrincipal)
        erroPrincipal = 'Existem ' + erros.toString().replace(/,/g, ', ') + ' com campos obrigatórios não preenchidos.'
    }

    if(errosAfloramento.length)
      return {
        [FORM_ERROR]: erroPrincipal,
        [ARRAY_ERROR]: errosAfloramento
      }
  }else{
    let errosAfloramento = [];
    let erroPrincipal = undefined;

    if(!formValues.rochas || !resolverExibidos(formValues.rochas).length)
      naoPossuiRochas = true;

    if(naoPossuiRochas){
      errosAfloramento.push('Afloramentos devem possuir ao menos uma rocha.');
      erroPrincipal = 'Afloramentos devem possuir ao menos uma rocha.'
    }
    if(errosAfloramento.length)
    return {
      [FORM_ERROR]: erroPrincipal,
      [ARRAY_ERROR]: errosAfloramento
    }
  }
};

export const validacoesGeraisRecmin = (valores) => {
  const formValues = valores;

  if(formValues.substancias) {
    let listaSubs = resolverExibidos(formValues.substancias);
    let possuemOrdem = listaSubs.find(sub => sub.importancia === 'SECUNDARIA' && sub.ordemNomenclatura) ? true : false;


    if(listaSubs.filter(sub => sub.importancia === 'PRINCIPAL').length > 1){
      return {
        [FORM_ERROR]: 'Deve haver uma única substância principal cadastrada.',
      }
    }

    if(possuemOrdem && listaSubs.find(sub => sub.importancia === 'SECUNDARIA' && !sub.ordemNomenclatura)){
      return {
        [FORM_ERROR]: 'Existem substâncias secundarias cadastradas sem ordem de nomenclatura.',
      }
    }
  }

  let erros = [];
  if(valores.modoCadastro === "DEFINITIVO") {

    // Atividades Exploração
    if(formValues.atividadesExploracao) {
      const itens = resolverExibidos(formValues.atividadesExploracao).filter(i => i != null);

      let erro = false;

      itens.forEach(i => {

        if(!i.dataInicial || ! typeof i.idTipoAtividade ==='number' || !i.fontesReferencia)
          erro = true;

        if(i.fontesReferencia){
          const fontesRef = resolverExibidos(i.fontesReferencia).filter(i => i != null);

          if(!fontesRef || !fontesRef.length)
            erro = true;

          fontesRef.forEach(fonte => {
            if(fonte.modoCadastro === 'BASE_PROJETOS' && !fonte.idProjeto)
              erro = true;

            if(fonte.modoCadastro === 'DETALHADO' && (!fonte.autores || !fonte.ano || !fonte.titulo || !fonte.idTipo))
              erro = true;

            if(fonte.modoCadastro === 'SIMPLIFICADO' && (!fonte.link || !fonte.descricao))
              erro = true;
          })
        }
      });

      if(erro)
        erros.push("Atividades de exploração");
    }

    // Atividades Mineração
    if(formValues.atividadesMineracao) {
      const itens = resolverExibidos(formValues.atividadesMineracao).filter(i => i != null);

      let erro = false;

      itens.forEach(i => {

        if(!i.tipoDataInicioAtividade || !i.tipoDataTerminoAtividade ||! typeof i.idMetodoLavra ==='number' || !typeof i.idMetodoBeneficiamento ==='number' || !i.fontesReferencia)
          erro = true;

        if(i.tipoDataInicioAtividade != 'INDETERMINADO' && !i.inicioAtividade)
          erro = true;

        if(i.tipoDataTerminoAtividade != 'INDETERMINADO' && !i.terminoAtividade)
          erro = true;

        if(i.dadosEconomicos) {
          const dadosEconomicos = resolverExibidos(i.dadosEconomicos).filter(d => d != null);

          dadosEconomicos.forEach(dados => {

            if(!dados.idTipoMinerioProcessado)
              erro = true;

            if(dados.produtos) {
              const produtos = resolverExibidos(dados.produtos).filter(p => p != null);

              produtos.forEach(produto => {

                if(!produto.idTipoSubstancia)
                  erro = true;

                if(produto.teor && !produto.idMedidaTeor || !produto.teor && produto.idMedidaTeor)
                  erro = true;

                if(produto.producao && !produto.idMedidaProducao || !produto.producao && produto.idMedidaProducao)
                  erro = true;

              });
            }

            if(!dados.data)
              erro = true;

            if(!dados.periodoProducao)
              erro = true;

          });
        }

        if(i.residuosMineracao) {
          const residuos = resolverExibidos(i.residuosMineracao).filter(d => d != null);

          residuos.forEach(residuo => {
            if(!residuo.idTipoResiduo && !residuo.idTipoArmazenamento)
              erro = true;

            if(!residuo.teor || !residuo.idUnidadeMedida || !residuo.idTipoSubstancia)
              erro = true;

            if(residuo.impactosAmbientais) {
              const impactos = resolverExibidos(residuo.impactosAmbientais).filter(d => d != null);

              impactos.forEach(impacto => {
                if(! typeof impacto.idTipoImpactoAmbiental ==='number')
                  erro = true;
              });
            }
          });
        }

        if(i.fontesReferencia){
          const fontesRef = resolverExibidos(i.fontesReferencia).filter(i => i != null);

          if(!fontesRef || !fontesRef.length)
            erro = true;

          fontesRef.forEach(fonte => {
            if(fonte.modoCadastro === 'BASE_PROJETOS' && !fonte.idProjeto)
              erro = true;

            if(fonte.modoCadastro === 'DETALHADO' && (!fonte.autores || !fonte.ano || !fonte.titulo || !fonte.idTipo))
              erro = true;

            if(fonte.modoCadastro === 'SIMPLIFICADO' && (!fonte.link || !fonte.descricao))
              erro = true;
          })
        }
      });

      if(erro)
        erros.push("Atividades de mineração");
    }

    // Metalogenia
    if(formValues.estudoMetalogenetico) {
      const itens = resolverExibidos(formValues.estudoMetalogenetico).filter(i => i != null);

      let erro = false;

      itens.forEach(i => {

        if(i.metalogenia && i.metalogenia.dadosDescritivos) {

          // Alteracoes hidro
          if(i.metalogenia.dadosDescritivos.alteracoesHidrotermais){
            const altHidro = resolverExibidos(i.metalogenia.dadosDescritivos.alteracoesHidrotermais).filter(i => i != null);

            altHidro.forEach(alt => {
              if(!alt.profundidadeSuperficial || ! typeof alt.idGrauAlteracao ==='number' || !alt.idZonaAlteracao)
                erro = true;
              if(alt.minerais){
                const minerais = resolverExibidos(alt.minerais).filter(i => i != null);
                if(minerais.length >0) {
                  let somatoria = 0;

                  minerais.forEach(m => {
                    if(!m.ordemAbundancia && m.percentual){
                      erro = true;
                    }
                    if(m.ordemAbundancia && !m.percentual){
                      erro = true;
                    }

                    somatoria += m.percentual ? m.percentual : 0;
                  });

                  if(somatoria !== 100)
                    erro = true;
                }
              }
            });
          }

          // Intemperismos
          if(i.metalogenia.dadosDescritivos.intemperismos){
            const intemperismos = resolverExibidos(i.metalogenia.dadosDescritivos.intemperismos).filter(i => i != null);

            intemperismos.forEach(intem => {
              if(!intem.idTipoMaterialProduto || !typeof intem.idTipoProcesso==='number')
                erro = true;
            });

          }

          // Rocha Encaixantes
          if(i.metalogenia.dadosDescritivos.rochasEncaixantes) {
            const enca = resolverExibidos(i.metalogenia.dadosDescritivos.rochasEncaixantes).filter(i => i != null);

            enca.forEach(e => {
              if(e.cadastrada === 'NAO') {
                if(!e.rochaNaoCadastrada)
                  erro = true;

                if(e.rochaNaoCadastrada){

                  if(!e.rochaNaoCadastrada.idRocha || !e.rochaNaoCadastrada.mineraisRocha)
                    erro = true;
                  else {
                    const minerais = resolverExibidos(e.rochaNaoCadastrada.mineraisRocha).filter(i => i != null);
                    let somatoria = 0;

                    minerais.forEach(m => {
                      if(!typeof m.idMineral==='number')
                        erro = true;

                      somatoria += m.proporcao ? m.proporcao : 0;
                    });

                    if(somatoria != 100)
                      erro = true;
                  }
                }
              }
              else if(e.cadastrada === 'SIM') {
                if(!e.idAfloramento || !e.idRochaAfloramento)
                  erro = true;
              }
            });
          }

          // Rocha Hospe
          if(i.metalogenia.dadosDescritivos.rochasHospedeiras) {
            const hosp = resolverExibidos(i.metalogenia.dadosDescritivos.rochasHospedeiras).filter(i => i != null);

            hosp.forEach(e => {
              if(e.cadastrada === 'NAO') {
                if(!e.rochaNaoCadastrada)
                  erro = true;

                if(e.rochaNaoCadastrada){

                  if(!e.rochaNaoCadastrada.idRocha || !e.rochaNaoCadastrada.mineraisRocha)
                    erro = true;
                  else {
                    const minerais = resolverExibidos(e.rochaNaoCadastrada.mineraisRocha).filter(i => i != null);
                    let somatoria = 0;

                    minerais.forEach(m => {
                      if(! typeof m.idMineral ==='number')
                        erro = true;

                      somatoria += m.proporcao ? m.proporcao : 0;
                    });

                    if(somatoria != 100)
                      erro = true;
                  }
                }
              }
              else if(e.cadastrada === 'SIM') {
                if(!e.idAfloramento || !e.idRochaAfloramento)
                  erro = true;
              }
            });
          }
        }

        if(i.metalogenia && i.metalogenia.dadosInterpretativos) {

          if(i.metalogenia.dadosInterpretativos.controleMineralizacao && i.metalogenia.dadosInterpretativos.controleMineralizacao.controlesEstruturais){
            const controles = resolverExibidos(i.metalogenia.dadosInterpretativos.controleMineralizacao.controlesEstruturais).filter(i => i != null);

            controles.forEach(c => {
              if(!typeof c.idTipoControleEstrutural==='number')
                erro = true;
            })
          }
        }

        if(!i.fontesReferencia)
          erro = true;

        if(i.fontesReferencia){
          const fontesRef = resolverExibidos(i.fontesReferencia).filter(i => i != null);

          if(!fontesRef || !fontesRef.length)
            erro = true;

          fontesRef.forEach(fonte => {
            if(fonte.modoCadastro === 'BASE_PROJETOS' && !fonte.idProjeto)
              erro = true;

            if(fonte.modoCadastro === 'DETALHADO' && (!fonte.autores || !fonte.ano || !fonte.titulo || !fonte.idTipo))
              erro = true;

            if(fonte.modoCadastro === 'SIMPLIFICADO' && (!fonte.link || !fonte.descricao))
              erro = true;
          })
        }
      });

      if(erro)
        erros.push("Metalogenias");
    }

    // Minas garimpo
    if(formValues.minasGarimpos) {
      const itens = resolverExibidos(formValues.minasGarimpos).filter(i => i != null);

      let erro = false;

      itens.forEach(i => {

        if(!i.idStatusEconomico || !i.nome || !i.idSituacao || !i.fontesReferencia || !i.substancias || !i.data)
          erro = true;

        if(i.fontesReferencia){
          const fontesRef = resolverExibidos(i.fontesReferencia).filter(i => i != null);

          if(!fontesRef || !fontesRef.length)
            erro = true;

          fontesRef.forEach(fonte => {
            if(fonte.modoCadastro === 'BASE_PROJETOS' && !fonte.idProjeto)
              erro = true;

            if(fonte.modoCadastro === 'DETALHADO' && (!fonte.autores || !fonte.ano || !fonte.titulo || !fonte.idTipo))
              erro = true;

            if(fonte.modoCadastro === 'SIMPLIFICADO' && (!fonte.link || !fonte.descricao))
              erro = true;
          })
        }

        if(i.substancias) {
          const substancias = resolverExibidos(i.substancias).filter(i => i != null);

          if(!substancias || !substancias.length)
            erro = true;

          substancias.forEach(subs => {
            if(subs.idClasseUtilitaria === undefined || subs.idClasseUtilitaria === null || !subs.idTipoSubstancia)
              erro = true;
          });
        }
      });

      if(erro)
        erros.push("Minas ou Garimpos");
    }

    // Ocorrencia mineral
    if(formValues.ocorrenciaMineral) {
      const itens = resolverExibidos(formValues.ocorrenciaMineral).filter(i => i != null);

      let erro = false;

      itens.forEach(i => {

        if(!typeof i.idExpressaoGeografica ==='number' || !typeof i.idFormaGeometrica ==='number' || !i.comprimento == null || !i.comprimento == undefined || !i.largura == null || !i.largura == undefined || !i.fontesReferencia || !i.substancias)
          erro = true;

        if(i.fontesReferencia){
          const fontesRef = resolverExibidos(i.fontesReferencia).filter(i => i != null);

          if(!fontesRef || !fontesRef.length)
            erro = true;

          fontesRef.forEach(fonte => {
            if(fonte.modoCadastro === 'BASE_PROJETOS' && !fonte.idProjeto)
              erro = true;

            if(fonte.modoCadastro === 'DETALHADO' && (!fonte.autores || !fonte.ano || !fonte.titulo || !fonte.idTipo))
              erro = true;

            if(fonte.modoCadastro === 'SIMPLIFICADO' && (!fonte.link || !fonte.descricao))
              erro = true;
          })
        }

        if(i.substancias) {
          const substancias = resolverExibidos(i.substancias).filter(i => i != null);

          if(!substancias || !substancias.length)
            erro = true;

          substancias.forEach(subs => {
            if(subs.idClasseUtilitaria === undefined || subs.idClasseUtilitaria === null  || !subs.idTipoSubstancia)
              erro = true;
          });
        }
      });

      if(erro)
        erros.push("Ocorrência mineral");
    }

    // deposito
    if(formValues.depositos) {
      const itens = resolverExibidos(formValues.depositos).filter(i => i != null);

      let erro = false;

      itens.forEach(i => {

        if(!i.nome || !i.fontesReferencia || !i.substancias)
          erro = true;

        if(i.fontesReferencia){
          const fontesRef = resolverExibidos(i.fontesReferencia).filter(i => i != null);

          if(!fontesRef || !fontesRef.length)
            erro = true;

          fontesRef.forEach(fonte => {
            if(fonte.modoCadastro === 'BASE_PROJETOS' && !fonte.idProjeto)
              erro = true;

            if(fonte.modoCadastro === 'DETALHADO' && (!fonte.autores || !fonte.ano || !fonte.titulo || !fonte.idTipo))
              erro = true;

            if(fonte.modoCadastro === 'SIMPLIFICADO' && (!fonte.link || !fonte.descricao))
              erro = true;
          })
        }

        if(i.substancias) {
          const substancias = resolverExibidos(i.substancias).filter(i => i != null);

          if(!substancias || !substancias.length)
            erro = true;

          substancias.forEach(subs => {
            if(subs.idClasseUtilitaria === undefined || subs.idClasseUtilitaria === null || !subs.idTipoSubstancia)
              erro = true;
          });
        }
      });

      if(erro)
        erros.push("Depósitos");
    }
  }

  if(erros.length) {
    return {
      [FORM_ERROR]: 'Existem ' + erros.toString().replace(/,/g, ', ') + ' com campos obrigatórios não preenchidos.',
    }
  }
}

/**
 * Factory de função que recebe valores para verificar se um campo controlado por um SubForm filho contém registros
 * @param {function} acessarElementos - Função que recebe valores do final-form e retorna o valor do campo
 * ou seja, simplesmente "acessa" a propriedade no objeto. Ex: `valores => valores.nomeCampoSubForm`
 * @param {object} mensagens - Objeto com propriedades `max` e `min`, com as mensagens de erro
 * que devem ser exibidas ao possuir uma quantidade inválida de registros
 * @param {number} min - Número mínimo de registros, padrão 1. Deve ser maior que 0
 * @param {number} max - Número máximo de registros, deve ser maior ou igual a min
 * @returns função para ser passada para a prop validar do SubForm
 */
export const possuiRegistros = (acessarElementos, mensagens = {}, min = 1, max = Number.MAX_SAFE_INTEGER) => {
  // Mensagens padrão
  if (!mensagens.max) mensagens.max = `Deve possuir no máximo ${max} registro(s)`;
  if (!mensagens.min) mensagens.min = `Deve possuir ao menos ${min} registro(s)`;

  return (valores => {
    const elementos = acessarElementos(valores);
    if (!elementos) return { [FORM_ERROR]: mensagens.min };

    const elementosExibidos = resolverExibidos(elementos);

    if (elementosExibidos.length < min) {
      return { [FORM_ERROR]: mensagens.min };
    } else if (elementosExibidos.length > max) {
      return { [FORM_ERROR]: mensagens.max };
    }

    return 0;
  });
};

export const verificarConexaoErro = async (err) => {
  if (err?.message === "Network Error" || err?.code == 'ECONNABORTED') {
    await swal({
      title: "Não foi possível estabelecer uma conexão com o servidor.",
      icon: "info",
      buttons: {
        confirm: {
          text: "Retornar a página inicial",
          className: "btn-primary",
        },
      },
    }).then((result) => {
      if (result) {
        window.location.pathname = "/";
      }
    });
  }
  throw err;
};

export const swalConfirmarExclusao = async () => {
  return swal({
    title: 'Deseja realmente excluir esse registro?',
    icon: 'warning',
    buttons: { cancel: "Não, cancelar", confirm: { text: "Sim, desejo excluir!", className: "btn-danger" } },
  });
};

export const swalConfirmarExclusaoParaDadosAnaliticos = async () => {
  return swal({
    title: 'Deseja realmente excluir esse registro?',
    text: 'Todas as atividades relacionadas a ele serão apagadas',
    icon: 'warning',
    buttons: { cancel: "Não, cancelar", confirm: { text: "Sim, desejo excluir!", className: "btn-danger" } },
  });
};

/**
 * Procura o primeiro span de erro e realiza scroll até ele
 * @param formElem - Elemento HTML onde será procurado o span de erro. Padrão document (página inteira)
 */
export const scrollAoErro = (formElem = document) => {
  formElem?.querySelector('.erro-campo')?.scrollIntoView({
    behavior: "smooth",
    block: "center",
  });
}

export const swalErro = async (swalOptions = {}) => {
  return swal({
    title: "Ocorreu um erro",
    icon: "error",
    buttons: {
      confirm: { text: "Ok", className: "btn-primary" },
    },
    ...swalOptions,
  });
}

export const substanciaObrigatoria = (valores) => {
  if (!valores.substancias || valores.substancias.length === 0) {
    return {
      [FORM_ERROR]: 'Ao menos uma substância deve ser cadastrada no Recurso mineral.'
    }
  }
}

export const substanciaObrigatoriaDeposito = (valores) => {
  if (verificaSubstanciasVazias(valores)) {
    return {
      [FORM_ERROR]: 'Ao menos uma substância deve ser cadastrada no Deposito.'
    }
  }

}

export const substanciaOcorrenciaMineral = (valores) => {
  if (verificaSubstanciasVazias(valores)) {
    return {
      [FORM_ERROR]: 'Ao menos uma substância deve ser cadastrada na Ocorrência Mineral.'
    }
  }
}

export const substanciaObrigatoriaMinaGarimpo = (valores) => {
  if (verificaSubstanciasVazias(valores)) {
    return {
      [FORM_ERROR]: 'Ao menos uma substância deve ser cadastrada em Mina/Garimpo.'
    }
  }
}

const verificaSubstanciasVazias = (valores) => {
  if (!valores.substancias || !resolverExibidos(valores.substancias) || !resolverExibidos(valores.substancias).length) {
    return true;
  }
  return false;
}

export const validacoesMinaGarimpo = (valores, formPai) => {
  let qntFonteReferencia;
  if (valores.fontesReferencia !== undefined) {
    qntFonteReferencia = resolverExibidos(valores?.fontesReferencia);
  }

  let substanciaInvalida = false;
  let substanciasForm = resolverExibidos(valores.substancias ?? []);
  let substanciasGerais = resolverExibidos(formPai?.getState()?.values?.substancias ?? []);
  let arrayIdsSubstanciasForm = [];
  let arrayIdsSubstanciasGerais = [];

  substanciasForm?.forEach(substancia => {
    arrayIdsSubstanciasForm.push(substancia.idTipoSubstancia);
  })
  substanciasGerais.forEach(substancia => {
    arrayIdsSubstanciasGerais.push(substancia.idTipoSubstancia)
  })

  arrayIdsSubstanciasForm.forEach(item => {
    if (!arrayIdsSubstanciasGerais.includes(item)) {
      substanciaInvalida = true;
    }
  })

  if (substanciaInvalida) {
    return {
      [FORM_ERROR]: 'Existem substâncias invalidas ou excluidas associadas a ocorrência mineral.'
    }
  }

  if ((qntFonteReferencia?.length === 0 || valores.fontesReferencia === undefined || valores.fontesReferencia === null) && verificaSubstanciasVazias(valores)) {
    return {
      [FORM_ERROR]: 'Ao menos uma fonte de referência deve ser cadastrada. Ao menos uma substância deve ser cadastrada.'
    }
  }

  if (qntFonteReferencia?.length === 0 || valores.fontesReferencia === undefined || valores.fontesReferencia === null) {
    return {
      [FORM_ERROR]: 'Ao menos uma fonte de referência deve ser cadastrada'
    }
  }
  if (verificaSubstanciasVazias(valores)) {
    return {
      [FORM_ERROR]: 'Ao menos uma substância deve ser cadastrada em Mina/Garimpo.'
    }
  }
  let possuiPrincipal = 0;
  if (valores.substancias !== undefined) {
    let substancias = resolverExibidos(valores.substancias);
    substancias.map(substancia => {
      if (substancia.importancia === "PRINCIPAL") {
        possuiPrincipal++;
      }
      return 0
    });
    if (possuiPrincipal > 1) {
      return {
        [FORM_ERROR]: 'Apenas uma substância principal deve ser cadastrada'
      }
    }
  } else {
    return {
      [FORM_ERROR]: 'Uma substância principal deve ser cadastrada'
    }
  }
  let arrayId = [];
  let substancias = [];

  if (valores.substancias) {
    substancias = resolverExibidos(valores.substancias);
  }
  substancias.map(item => {
    arrayId.push(item.idTipoSubstancia)
  })

  let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index)
  if (findDuplicates(arrayId).length > 0) {
    return {
      [FORM_ERROR]: 'Não devem existir substâncias repetidas.'
    }
  }
}



export const teorSemUnidadeDeMedida = (valores) => {
  if ((valores.teor && !valores.idMedidaTeor) || (!valores.teor && valores.idMedidaTeor)) {
    return {
      [FORM_ERROR]: 'Não é possível cadastrar um produto sem o teor e a unidade de medida do teor.'
    }
  }
}
export const valorSemUnidade = (valores) =>{
  let Erros = [];
  if ((valores.producao && !valores.idMedidaProducao) || (!valores.producao && valores.idMedidaProducao)) {
    Erros.push('Não é possível cadastrar um produto sem a produção e a unidade de medida de produção.');
  }

  if ((valores.teor && !valores.idMedidaTeor) || (!valores.teor && valores.idMedidaTeor)) {
    Erros.push( 'Não é possível cadastrar um produto sem o teor e a unidade de medida do teor.');
  }

  if(Erros.length == 2){
    let erro = `Não é possível cadastrar um produto sem a produção e a unidade de medida de produção e não é possível cadastrar um produto sem o teor e a unidade de medida do teor.`;

    return{
      [FORM_ERROR]: erro,
      [ARRAY_ERROR]: Erros
    }
  }
  else if(Erros.length == 1) {
    return {
      [FORM_ERROR]: Erros[0],
    }
  }
}

export const quantidadeSemUnidadeDeMedida = (valores) => {
  if ((valores.quantidade && !valores.idUnidadeMedida) || (!valores.quantidade && valores.idUnidadeMedida)) {
    return {
      [FORM_ERROR]: 'Não é possível cadastrar dados econômicos do minério sem a quantidade e a unidade de medida de quantidade.'
    }
  }
}
export const campoSemTipoEstrutura = (nomeCampo, opts) => campoObrigatorio({
  ...opts,
  mensagem: opts && opts.forcarMensagemEnviada ? `${nomeCampo}` : `Não possui um tipo de estrutura primária para o campo "${nomeCampo}".`
})

export const substanciasIguais = (valores) => {
  let arrayId = [];
  let substancias = [];

  if (valores.substancias) {
    substancias = resolverExibidos(valores.substancias);
  }
  substancias.map(item => {
    arrayId.push(item.idTipoSubstancia)
  })

  let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index)
  if (findDuplicates(arrayId).length > 0) {
    return {
      [FORM_ERROR]: 'Não devem existir substâncias repetidas.'
    }
  }
}

export const unidadeMedidaSemTeorDeCorte = ((valores) => {
  if ((valores.teorCorte === undefined || valores.teorCorte === '') && (valores.idUnidadeMedidaTeorCorte !== undefined && valores.idUnidadeMedidaTeorCorte !== '')) {
    return {
      [FORM_ERROR]: 'Não é possível cadastrar uma unidade de medida do teor de corte sem teor de corte'
    }
  }

  if ((valores.teorCorte !== undefined && valores.teorCorte !== '') && (valores.idUnidadeMedidaTeorCorte === undefined || valores.idUnidadeMedidaTeorCorte === '')) {
    return {
      [FORM_ERROR]: 'Não é possível cadastrar um teor de corte sem unidade de medida do teor de corte'
    }
  }
}
);

export const rochaSemEstrutura = ((valores) => {
  let tipoEstrutura;
  if (valores.idsRochas !== undefined) {
    tipoEstrutura = resolverExibidos(valores?.idsRochas);
  }

  if (tipoEstrutura?.length === 0 || valores.idsRochas === undefined || valores.idsRochas === null) {
    return {
      [FORM_ERROR]: 'Ao menos um tipo de rocha deve ser cadastrada'
    }
  }
}
);

export const verificaSubstanciaExisteObrigatoria = (valor, listaSubstancias, opts) => validaSubstanciasObrigatoriasFiltradas({
  ...opts,
  valorSelecionado: valor,
  listaSubstancias: listaSubstancias,
  mensagem: "O campo Substância é obrigatório"
})

export const validaSubstanciasObrigatoriasFiltradas = ({
  mensagem = "O campo Substância é obrigatório",
  valorSelecionado = null,
  listaSubstancias = null,
  funcaoValidaSubstanciasFiltradas = substanciasObrigatoriasFiltradas,
} = {}) => (value, allValues, meta) => {
  const valorPresente = funcaoValidaSubstanciasFiltradas(valorSelecionado, listaSubstancias)
  if (!valorPresente) {
    return mensagem
  }
}

export const substanciasObrigatoriasFiltradas = (valor, listaSubstancias) => {
  if (valor && listaSubstancias) {
    let exists = listaSubstancias.some(substancia => valor == substancia.id);
    return exists;
  }
}

export const validacoesCamposRelacionadosAtividadeMineracao = ((valores) => {
  let erros = [];

  if (!valores.idTipoResiduo && !valores.idTipoArmazenamento) {
    erros.push('Ao menos Tipo de resíduo ou Tipo de armazenamento deve ser preenchido');
  }

  if(erros.length == 1) {
    return {
      [FORM_ERROR]: erros[0]
    }
  }

});