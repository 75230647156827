import React from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { DropdownListField } from "../../../../../components/field/DropdownListField";
import { NumberInputField } from "../../../../../components/field/NumberInputField";
import { TextAreaField } from "../../../../../components/field/TextAreaField";
import { campoObrigatorioDefinitivoComMsgGenerica, comporValidadores } from "../../../../../util/validadores";
import { resolverRequestAmostras } from "../AmostrasUtils/AmostraRequestUtils";
import { validaProfundidadeMinima, validaProfundidadeMaxima } from "../validadores";

class CamposComuns extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tipoAmostragem: [],
      fonteAmostra: [],
      materialColetado: []

    }
  }

  async componentDidMount() {
    this.setState({
      tipoAmostragem: await resolverRequestAmostras("/amostra/agua/tipoAmostragem"),
      fonteAmostra: await resolverRequestAmostras("/amostra/agua/fonteAmostra"),
      materialColetado: await resolverRequestAmostras("/biblioteca/materialcoletado"),
    })
  }

  render() {
    return <Field subscription={{ value: true }} name={'modoCadastro'}>
      {({ input: { value: modoCadastro } }) => {

        const validar = modoCadastro === 'DEFINITIVO';

        return <>
        <Row >
          <Col md={6}>
            <Field
              disabled={!this.props.permitirEdicao}
              name={`${this.props.prefixo}.idTipoAmostragem`}
              component={DropdownListField}
              label="Tipo de amostragem"
              dica="Selecione o tipo de amostragem utilizado"
              elementos={this.state.tipoAmostragem}
              required={validar}
              validate={campoObrigatorioDefinitivoComMsgGenerica(
                "Tipo de amostragem")
              }
            />
          </Col>
          <Col md={6}>
            <Field
              disabled={!this.props.permitirEdicao}
              name={`${this.props.prefixo}.idFonteAmostra`}
              component={DropdownListField}
              label="Fonte da amostra"
              dica="Selecione a fonte da amostra coletada"
              elementos={this.state.fonteAmostra}
              required={validar}
              validate={campoObrigatorioDefinitivoComMsgGenerica(
                "Fonte da amostra"
              )}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={6}>
            <Field name={`${this.props.prefixo}.materialColetado`} subscription={{ value: true }}>
              {({ input: { value: materialColetado } }) => {
                let idInicial = null;
                if (materialColetado === null || materialColetado === undefined || materialColetado === "") {
                  if (this.props.materialColetadoPreenchido) {
                    idInicial = 'ALUV';
                  } 
                }
                return <Field
                  disabled={!this.props.permitirEdicao}
                  subscription={{ value: true }}
                  name={`${this.props.prefixo}.materialColetado`}
                  component={DropdownListField}
                  label="Material coletado"
                  dica="Selecione o tipo de material coletado para compor a amostra"
                  elementos={this.state.materialColetado}
                  initialValue={idInicial}
                />
              }}
            </Field>
          </Col>
          <Col md={6}>
            {this.props.prefixo === "agua" ?
              <Field
                disabled={this.props.visualizar ? true : this.props.duplicata ? false : !this.props.permitirEdicao}
                name={`${this.props.prefixo}.volumeAmostra`}
                component={NumberInputField}
                label={this.props.labelCampo ?? ""}
                dica={this.props.dicaCampo ?? ""}
                required={validar}
                casasDecimais={4}
                casasInteiros={4}
                permitirNegativos={false}
                validate={campoObrigatorioDefinitivoComMsgGenerica(
                  `${this.props.labelCampo ?? ""}`
                )}
              />
              :
              <Field
                disabled={this.props.visualizar ? true : this.props.duplicata ? false : !this.props.permitirEdicao}
                name={`${this.props.prefixo}.pesoAmostra`}
                component={NumberInputField}
                label={this.props.labelCampo ?? ""}
                dica={this.props.dicaCampo ?? ""}
                casasDecimais={4}
                casasInteiros={4}
                permitirNegativos={false}
              />
            }
          </Col>
        </Row>
        {this.props.profundidadeObrigatoria ?
        <Row className="mt-3">
          <Col md={6}>
            <Field
              disabled={!this.props.permitirEdicao}
              name={`${this.props.prefixo}.profundidadeMinima`}
              component={NumberInputField}
              label="Profundidade mínima (m)"
              dica="Informe a profundidade mínima da coleta, se aplicável"
              required={validar}
              casasDecimais={4}
              casasInteiros={4}
              permitirNegativos={false}
              validate={
                comporValidadores(
                  campoObrigatorioDefinitivoComMsgGenerica("Profundidade mínima (m)"),
                  validaProfundidadeMinima
                )
              }
            />
          </Col>
          <Col md={6}>
            <Field
              disabled={!this.props.permitirEdicao}
              name={`${this.props.prefixo}.profundidadeMaxima`}
              component={NumberInputField}
              label="Profundidade máxima (m)"
              dica="Informe a profundidade máxima da coleta, se aplicável"
              required={validar}
              casasDecimais={4}
              casasInteiros={4}
              permitirNegativos={false}
              validate={
                comporValidadores(
                  campoObrigatorioDefinitivoComMsgGenerica("Profundidade máxima (M)"),
                  validaProfundidadeMaxima
                )
              }
            />
          </Col>
        </Row> :
        <Row className="mt-3">
          <Col md={6}>
            <Field
              disabled={!this.props.permitirEdicao}
              name={`${this.props.prefixo}.profundidadeMinima`}
              component={NumberInputField}
              permitirNegativos={false}
              label="Profundidade mínima (m)"
              dica="Informe a profundidade mínima da coleta, se aplicável"
              casasDecimais={4}
              casasInteiros={4}
              validate={comporValidadores(validaProfundidadeMinima)}
            />
          </Col>
          <Col md={6}>
            <Field
              disabled={!this.props.permitirEdicao}
              name={`${this.props.prefixo}.profundidadeMaxima`}
              component={NumberInputField}
              permitirNegativos={false}
              label="Profundidade máxima (m)"
              dica="Informe a profundidade máxima da coleta, se aplicável"
              casasDecimais={4}
              casasInteiros={4}
              validate={comporValidadores(validaProfundidadeMaxima)}
            />
          </Col>
        </Row>
      }

      <Row className="mt-3">
        <Col md={12}>
          <Field
            maxLength={250}
            disabled={!this.props.permitirEdicao}
            name={`${this.props.prefixo}.observacoes`}
            component={TextAreaField}
            label="Informações relevantes"
            dica="Descrever, com o maior detalhe possível, as características da amostra cadastrada e do seu local de coleta"
            required={validar && this.props.informacoesRelevantesObrigatorias ? true : false }
            validate={this.props.informacoesRelevantesObrigatorias ? campoObrigatorioDefinitivoComMsgGenerica("Informações relevantes") : false}
          />
        </Col>
      </Row>
        </>
      }}
      </Field>
  }

}

export default CamposComuns;
