import React, { Component } from 'react';
import uniqueId from 'lodash/uniqueId'
import PropTypes from 'prop-types';
import Iframe from 'react-iframe';
import './AppMapComponent.css';
import 'bootstrap/dist/css/bootstrap.css';

//Repositorio do aflora tem o README do arcgis-iframe

class AppMapComponent extends Component {
  static propTypes = {
    config: PropTypes.object,
    onSelecionarPontos: PropTypes.func,
    onSelecionarEstacao: PropTypes.func,
    onCarregarMapa: PropTypes.func,
    onCriarNovaEstacao: PropTypes.func,
    onAssociarPontosEstacao: PropTypes.func,
    onSelecaoEstacaoMudar: PropTypes.func,
    onCriarPonto: PropTypes.func,
    onCriarUnicoPonto: PropTypes.func,
    onSelecaoOutrosMudar: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.uid = uniqueId('mapa-')
    this.state = {
      latitude: props.latitude || -23,
      longitude: props.longitude || -46,
      distance: props.distance || 100000,
      layer_checked: [
        true,
        true
      ],
      pontosSelecionados: []
    };

    this.selecionarPontos = this.selecionarPontos.bind(this);
    this.addOrRemoveLayer = this.addOrRemoveLayer.bind(this);
    this.sendInitialize = this.sendInitialize.bind(this);
    this.handleOperationFinished = this.handleOperationFinished.bind(this);
    this.onMessage = this.onMessage.bind(this);
  }

  componentDidMount() {
    window.addEventListener('message', this.onMessage);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.onMessage);
  }

  onMessage(evt) {
    const { target, state } = evt.data ?? {};

    // Ignorar outros eventos 'message' (ex: react)
    if (!target) return;
    // Ignorar eventos de outro iframe (https://stackoverflow.com/a/47676007/11138267)
    if (document.getElementById(this.uid).contentWindow === evt.source)

      if (this.props.debugMensagens) console.info(`%cMensagem do #${this.uid}: %c${target}`, 'color: orange', `color: ${target === 'operationFinished' ? 'grey' : '#76af57'}; font-weight: bold`, state)

    if (target === "stationToBeUsed") {
      if (this.props.onSelecionarEstacao) {
        this.props.onSelecionarEstacao({
          idEstacao: state.object_id,
          latitude: state.latitude,
          longitude: state.longitude,
        });
      }

      if (this.props.onAssociarPontosEstacao) {
        this.props.onAssociarPontosEstacao(state.object_id);
      }
    } else if (target === "selectionChanged") {
      if (this.props.onSelecionarPontos) {
        this.props.onSelecionarPontos(state.map(e => ({
          id: e.object_id,
          selecionado: e.isSelected,
        })));
      }
    } else if (target === "pointToBeCreated") {
      if (this.props.onCriarNovaEstacao) {
        this.props.onCriarNovaEstacao({
          latitude: state.latitude,
          longitude: state.longitude,
        });
      }
    } else if (target === "pointTobeUsed") {
      if (this.props.onCriarNovaEstacaoPontoGPS) {
        this.props.onCriarNovaEstacaoPontoGPS({
          id: state.object_id,
          altitude: state.altitude,
          latitude: state.latitude,
          longitude: state.longitude,
        });
      }
    } else if(target === "singlePointCreated"){
      this.props.onCriarUnicoPonto(state.object_id);
    } else if (target === "operationFinished") {
      this.handleOperationFinished(evt.data);
    } else if (target === "stationSelectionChanged" && this.props.onSelecaoEstacaoMudar) {
      this.props.onSelecaoEstacaoMudar(state.object_id);
    } else if(target === "otherLayerSelectionChanged" && this.props.onSelecaoOutrosMudar){
      this.props.onSelecaoOutrosMudar(state.selectedLayerId, state.object_id, state.selectedRow);
    }
  }

  handleOperationFinished(data) {
    const { target, state } = data;
    if ("operationFinished" === target) {
      if ("initializeMap" === state && this.props.onCarregarMapa) {
        this.props.onCarregarMapa();
      } else if ("OnClearGraphics" === state && this.props.onLimparMapa) {
        this.props.onLimparMapa();
      } else if ("onFilter" === state && this.props.onFiltrar) {
        this.props.onFiltrar();
      } else if("onCreatePoint" === state && this.props.onCriarPonto){
         this.props.onCriarPonto();
      }
    }
  }

  limparSelecaoMapa(){
    const payload = {
      target: 'onClearSelection',
      state: {}
    };

    if (this.props.debugMensagens) console.info(`%cEnviando ao #${this.uid}: %c${payload.target}`, 'color: orange', 'color: #e772d4; font-weight: bold', payload.state)

    document.getElementById(this.uid).contentWindow.postMessage(payload, "*");
  }

  /** adiciona um array de pontos GPS. cada elemento do vetor deve estar no formato {latitude: int, longitude: int, id: int} */
  adicionarPontosGPS(pontos = []) {
    if (pontos.length === 0) return;
    const payload = {
      target: 'onCreatePointGPS',
      state: pontos.map(p => ({
        latitude: Number.isFinite(p.latitudeWGS84) ? p.latitudeWGS84 : p.latitude,
        longitude: Number.isFinite(p.longitudeWGS84) ? p.longitudeWGS84 : p.longitude,
        altitude: p.altitude,
        object_id: p.id,
        symbolType: p.tipoSimbolo || 'default',
        name: p.nome,
      }))
    };

    if (this.props.debugMensagens) console.info(`%cEnviando ao #${this.uid}: %c${payload.target}`, 'color: orange', 'color: #e772d4; font-weight: bold', payload.state)

    document.getElementById(this.uid).contentWindow.postMessage(payload, "*");
  }

  adicionarPontoEstacao(latitude, longitude, distancia = 100000, tipoSimbolo = 'default') {
    // this.limparPontos();
    const payload = {
      target: 'onCreatePoint',
      state: [{
        latitude,
        longitude,
        distance: distancia,
        symbolType: tipoSimbolo,
      }]
    };

    if (this.props.debugMensagens) console.info(`%cEnviando ao #${this.uid}: %c${payload.target}`, 'color: orange', 'color: #e772d4; font-weight: bold', payload.state)

    document.getElementById(this.uid).contentWindow.postMessage(payload, "*");

    // setTimeout(() => this.sendSelectPoint(latitude, longitude),1000);
  }

  selecionarEstacao(id) {
    const payload = {
      target: 'OnStationSelectionChange',
      state: {
        object_id: id,
        isSelected: true,
      }
    };
    if (this.props.debugMensagens) console.info(`%cEnviando ao #${this.uid}: %c${payload.target}`, 'color: orange', 'color: #e772d4; font-weight: bold', payload.state)

    document.getElementById(this.uid).contentWindow.postMessage(payload, "*");
  }

  selecionarOutro(layerUrl, id){
    const payload = {
      target: 'OnOtherLayerSelectionChanged',
      state: {
        object_id: id,
        layerUrl: layerUrl,
        isSelected: true,
      }
    };
    if (this.props.debugMensagens) console.info(`%cEnviando ao #${this.uid}: %c${payload.target}`, 'color: orange', 'color: #e772d4; font-weight: bold', payload.state)

    document.getElementById(this.uid).contentWindow.postMessage(payload, "*");
  }

  filtrarOutro(layerUrl, query){
    const payload = {
      target: 'onFilterOtherLayers',
      state: {
        layerUrl: layerUrl,
        query: query
      }
    };
    if (this.props.debugMensagens) console.info(`%cEnviando ao #${this.uid}: %c${payload.target}`, 'color: orange', 'color: #e772d4; font-weight: bold', payload.state)

    document.getElementById(this.uid).contentWindow.postMessage(payload, "*");    
  }

  adicionarPontoEstacaoGeologica(pontos = []) {

    if (pontos.length === 0) return;

    const payload = {
      target: 'onCreatePoint',
      state: pontos.map(p => ({
        object_id: p.id,
        latitude: p.latitude,
        longitude: p.longitude,
        altitude: p.altitude,
        distance: p.distancia || 100000,
        symbolType: p.tipoSimbolo || 'default',
        name: p.nome,
      }))
    };

    if (this.props.debugMensagens) console.info(`%cEnviando ao #${this.uid}: %c${payload.target}`, 'color: orange', 'color: #e772d4; font-weight: bold', payload.state)

    document.getElementById(this.uid).contentWindow.postMessage(payload, "*");

  }

  // Remove todos os pontos GPS, pontos de estação e polígono do filtro
  limparPontos(limparGeometrias = false) {
    const payload = {
      target: 'OnClearGraphics',
    };
    if (limparGeometrias) payload.state = { clearAll: true }; // Limpar geometrias

    if (this.props.debugMensagens) console.info(`%cEnviando ao #${this.uid}: %c${payload.target}`, 'color: orange', 'color: #e772d4; font-weight: bold', payload.state)

    document.getElementById(this.uid).contentWindow.postMessage(payload, "*");
  }

  /**
   * Usado para marcar pontos de arquivos GPS ou pontos de estação como marcados :)
   *
   * @param pontos um array de objetos no formato {id: number, selecionado: boolean}
   * */
  selecionarPontos(pontos) {
    const payload = {
      "target": "OnSelectionChange",
      "state": pontos.map(p => ({
        "object_id": p.id,
        "isSelected": p.selecionado,
      })),
    }

    if (this.props.debugMensagens) console.info(`%cEnviando ao #${this.uid}: %c${payload.target}`, 'color: orange', 'color: #e772d4; font-weight: bold', payload.state)

    document.getElementById(this.uid).contentWindow.postMessage(payload, "*");
  }

  sendInitialize() {
    const payload = this.props.config;

    if (this.props.debugMensagens) console.info(`%cIniciando #${this.uid}: %c${payload.target}`, 'color: orange', 'color: #e772d4; font-weight: bold', payload.state)

    document.getElementById(this.uid).contentWindow.postMessage(this.props.config, "*");
  }

  addOrRemoveLayer(event) {
    let index = (event.target.id.substr((event.target.id.length - 15) * -1, 1));

    let layer_checked = this.state.layer_checked;
    layer_checked[index] = event.target.checked;

    this.setState({
      layer_checked: layer_checked
    });

    let payload = {
      target: 'onShowOrHideLayer',
      state: {
        index: index,
        show: event.target.checked
      }
    }

    if (this.props.debugMensagens) console.info(`%cEnviando ao #${this.uid}: %c${payload.target}`, 'color: orange', 'color: #e772d4; font-weight: bold', payload.state)

    document.getElementById(this.uid).contentWindow.postMessage(payload, "*");
  }

  filtrar(idProjeto, geometryFilter, exibirEstacoesHistoricas = false, limparPontos = true) {
    const payload = {
      target: 'onFilter',
      state: {
        project: idProjeto,
        geometryFilter,
        showHistory: exibirEstacoesHistoricas
      }
    }
    if (this.props.debugMensagens) console.info(`%cEnviando ao #${this.uid}: %c${payload.target}`, 'color: orange', 'color: #e772d4; font-weight: bold', payload.state)

    document.getElementById(this.uid).contentWindow.postMessage(payload, "*");
  }

  filtrarAmostras(idAmostras, idAmostrasHistoricas, geometryFilter, exibirEstacoesHistoricas = false, limparPontos = true) {

    const payload = {
      target: 'filtro',
      state: {
        idAmostras: idAmostras,
        idAmostrasHistoricas: idAmostrasHistoricas,
        geometryFilter,
        showHistory: true
      }
    }
    if (this.props.debugMensagens) console.info(`%cEnviando ao #${this.uid}: %c${payload.target}`, 'color: orange', 'color: #e772d4; font-weight: bold', payload.state)

    document.getElementById(this.uid).contentWindow.postMessage(payload, "*");
  }

  render() {
    return (
      <>
        <Iframe
          url={"/iframe_arcgis/index.html?logMessages=false"}
          id={this.uid}
          display="initial"
          onLoad={this.sendInitialize}
          position="relative"
          scrolling="no"
          className="mapa"
        />
      </>
    );
  }
}
export default AppMapComponent;
