import React, { Component } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Field, FormSpy } from "react-final-form";
import { DropdownListField } from "../../../../../components/field/DropdownListField";
import { NumberInputField } from "../../../../../components/field/NumberInputField";
import {
  RadioButtonSetField,
  RadioButtonWithFieldSetField,
} from "../../../../../components/field/RadioButtonSetField";
import { TextInputField } from "../../../../../components/field/TextInputField";
import TextAreaField from "../../../../../components/field/TextAreaField/TextAreaField";
import { SubForm } from "../../../../../components/SubForm";
import Hint from "../../../../../components/hint/Hint";
import {
  campoObrigatorioComMsgGenerica,
  comporValidadores,
  geraNomeMineralizacao,
  validacoesEstudosMetalogeneticos,
  validarSe,
} from "../../../../../util/validadores";
import FonteReferencia from "../FonteReferencia/FonteReferencia";
import { setValue } from "../../../../../util/mutadores";
import {
  getPropsPermissao,
  resolverExibidos,
} from "../../../../../components/SubForm/SubForm";
import { FORM_ERROR } from "final-form";
import Atitudes from "../../afloramento/Atitudes";
import {
  resolverMultiRequestRecmin,
  resolverRequestRecmin,
  resolverRequestRecminLitoestratigrafia,
} from "../../../../../util/ResolverRequestRecmin";
import { HiddenField } from "../../../../../components/field/HiddenField";
import BlockUi from "react-block-ui";
import { validaProfundidadeFinal, validaProfundidadeInicial } from "../validadores";
import { Table } from "../../../../../components/Jqx/Table";
import { isEqual } from "lodash";
import { string } from "prop-types";
import { CounterField } from "../../../../../components/field/CounterField";
import Service, { AFLORA } from "../../../../../service/Service";

class EstudosMetalogeneticos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      necessitaValidacao: [
        {
          id: "SIM",
          nome: "Sim",
        },
        {
          id: "NAO",
          nome: "Não",
        },
      ],
      rochaHospedeiraNaoCadastrada: [
        {
          id: "SIM",
          nome: "Sim",
        },
        {
          id: "NAO",
          nome: "Não",
        },
      ],
      rochaEncaixanteNaoCadastrada: [
        {
          id: "SIM",
          nome: "Sim",
        },
        {
          id: "NAO",
          nome: "Não",
        },
      ],
      tratamentoMitigatorio: [
        {
          nome: "sim",
          id: "SIM",
        },
        {
          nome: "não",
          id: "NAO",
        },
      ],
      preenchimentoProfundidade: [
        {
          nome: "Intervalo",
          id: "INTERVALO",
        },
        {
          nome: "Absoluto",
          id: "ABSOLUTO",
        },
      ],
      profundidadeSuperficial: [
        {
          nome: "Sim",
          id: "SIM",
        },
        {
          nome: "Não",
          id: "NAO",
        },
      ],
      compoeNomeRochas: [
        {
          nome: "Sim",
          id: "SIM",
        },
        {
          nome: "Não",
          id: "NAO",
        },
      ],
      nomeUnidadeEstratigrafica: undefined,
      grauAlteracao: [],
      campo: [],
      polo: [],
      textura: [],
      idadeMinima: [],
      idadeMaxima: [],
      associacaoMineralogica: [],
      estruturaMineralizacao: [],
      zonaAlteracao: [],
      tipoAlteracao: [],
      distribuicaoAlteracao: [],
      mineral: [],
      ordemAbundancia: [],
      tipoProcesso: [],
      tipoMaterialProduto: [],
      afloramento: [],
      rochas: [],
      prefixoRocha: [],
      complementoRocha: [],
      nomenclaturaRocha: [],
      formaRelacaoHospedeira: [],
      notacao: [],
      tipoControleEstrutural: [],
      estratigrafico: [],
      litologico: [],
      processoFundamental: [],
      sistemas: [],
      classe: [],
      tipoModelo: [],
      permitirInsercao: true,
      tiposNotacao: [],
      regimeTectonico: [],
      ambienteTectonico: [],
      subambienteTectonico: [],
      provinciaEstruturalGeologica: [],
      dadosAflora: [],
      craton: [],
      faixaMovelBacia: [],
      unidadeLito: "",
      siglasUnidadeLito: [],
      dominio: [],
      provinciaMineral: [],
      distrito: [],
      valorLito: null,
      escala: "",
      metodo: "",
      exibirNecessitaValidacao: false,
      carregamentoGlobal: true,
      parametrosParaSelects: {
        longitude:
          this.props.referenciaForm.getState().values.coordenadas.longitude,
        latitude:
          this.props.referenciaForm.getState().values.coordenadas.latitude,
        tipoEntrada:
          this.props.referenciaForm.getState().values.coordenadas.tipoEntrada,
        hemisferio:
          this.props.referenciaForm.getState().values.coordenadas.hemisferio,
        idDatum:
          this.props.referenciaForm.getState().values.coordenadas.idDatum,
      },
      // Nota: Estes datafields estão todos no estado, mas eu recomendo evitar
      // de mudá-los se possível. Mudar os datafields de uma tabela faz a tabela
      // renderizar novamente.
      datafieldsEstudosMetalogeneticos: [
        {
          name: "labelFixoEstudosMetalogeneticos",
          type: "string",
          map: "labelFixoEstudosMetalogeneticos",
        },
      ],
      datafieldsAlteracaoHidrotermal: [
        { name: "idGrauAlteracao", type: "string", map: "idGrauAlteracao" },
        { name: "idZonaAlteracao", type: "string", map: "idZonaAlteracao" },
        { name: "idTipoAlteracao", type: "string", map: "idTipoAlteracao" },
        { name: "profundidade", type: "number", map: "profundidade" },
        {
          name: "profundidadeInicial",
          type: "number",
          map: "profundidadeInicial",
        },
        { name: "profundidadeFinal", type: "number", map: "profundidadeFinal" },
      ],
      datafieldsMinerais: [
        { name: "idTipoMineral", type: "string", map: "idTipoMineral" },
        { name: "ordemAbundancia", type: "number", map: "ordemAbundancia" },
        { name: "percentual", type: "number", map: "percentual" },
      ],
      dataFieldsIntemperismo: [
        { name: "idTipoProcesso", type: "string", map: "idTipoProcesso" },
        {
          name: "idTipoMaterialProduto",
          type: "string",
          map: "idTipoMaterialProduto",
        },
        { name: "profundidade", type: "number", map: "profundidade" },
      ],
      datafieldsRochaHospedeira: [
        { name: "id", type: "number", map: "id" },
        { name: "idAfloramento", type: "string", map: "idAfloramento" },
        {
          name: "nomeRochaNaoCadastrada",
          type: "string",
          map: "nomeRochaNaoCadastrada",
        },
        {
          name: "idRochaAfloramento",
          type: "string",
          map: "idRochaAfloramento",
        },
        { name: "cadastrada", type: "string", map: "cadastrada" },
      ],
      datafieldsRochaEncaixante: [
        { name: "idAfloramento", type: "string", map: "idAfloramento" },
        {
          name: "nomeRochaNaoCadastrada",
          type: "string",
          map: "nomeRochaNaoCadastrada",
        },
        {
          name: "idRochaAfloramento",
          type: "string",
          map: "idRochaAfloramento",
        },
        { name: "cadastrada", type: "string", map: "cadastrada" },
      ],
      datafieldsEstrutural: [
        {
          name: "idTipoControleEstrutural",
          type: "string",
          map: "idTipoControleEstrutural",
        },
      ],
      datafieldsRochaNaoCadastrada: [
        { name: "idMineral", type: "string", map: "idMineral" },
        {
          name: "proporcao",
          type: "number",
          map: "proporcao",
        },
      ],
      rochasLito: []
    };

    this.colunasEstudosMetalogeneticos = [
      {
        text: "",
        datafield: "labelFixoEstudosMetalogeneticos",
        width: "94%",
        cellsrenderer: (
          row,
          columnfield,
          value,
          defaulthtml,
          columnproperties,
          rowdata
        ) => {
          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${"Metalogenia"}</div>`;
        },
      },
    ];

    this.colunasMinerais = [
      {
        text: "Mineral",
        datafield: "idTipoMineral",
        width: "20%",
        cellsrenderer: (
          row,
          columnfield,
          value,
          defaulthtml,
          columnproperties,
          rowdata
        ) => {
          let minerais = this.state.mineral;
          let nome = "";
          minerais?.map((mineral) => {
            if (mineral.id?.toString() === rowdata.idTipoMineral?.toString()) {
              nome = mineral.nome;
            }
          });
          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
        },
      },
      {
        text: "Ordem de abundância",
        datafield: "ordemAbundancia",
        width: "54%",
      },
      {
        text: "Percentual",
        datafield: "percentual",
        width: "20%",
        cellsrenderer: (
          row,
          columnfield,
          value,
          defaulthtml,
          columnproperties,
          rowdata
        ) => {
          if (
            rowdata.percentual === "" ||
            rowdata.percentual === null ||
            rowdata.percentual === undefined
          ) {
            return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${""}</div>`;
          } else {
            return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${rowdata.percentual}%</div>`;
          }
        },
      },
    ];

    this.colunasIntemperismo = [
      {
        text: "Tipo de processo",
        datafield: "idTipoProcesso",
        width: "34%",
        cellsrenderer: (
          row,
          columnfield,
          value,
          defaulthtml,
          columnproperties,
          rowdata
        ) => {
          let processos = this.state.tipoProcesso;
          let nome = "";        
          processos?.map((processo) => {
            if (
              processo.id?.toString() === rowdata.idTipoProcesso?.toString()
            ) {
              nome = processo.nome;
            }
          });
          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
        },
      },
      {
        text: "Tipo de material ou produto",
        datafield: "idTipoMaterialProduto",
        width: "30%",
        cellsrenderer: (
          row,
          columnfield,
          value,
          defaulthtml,
          columnproperties,
          rowdata
        ) => {
          let materiais = this.state.tipoMaterialProduto;
          let nome = "";
          materiais?.map((material) => {
            if (
              material.id?.toString() ===
              rowdata.idTipoMaterialProduto?.toString()
            ) {
              nome = material.nome;
            }
          });
          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
        },
      },
      { text: "Profundidade", datafield: "profundidade", width: "30%" },
    ];

    this.colunasAlteracaoHidrotermal = [
      {
        text: "Intensidade da alteração",
        datafield: "idGrauAlteracao",
        cellsrenderer: (
          row,
          columnfield,
          value,
          defaulthtml,
          columnproperties,
          rowdata
        ) => {
          let alteracoes = this.state.grauAlteracao;
          let nome = "";
          alteracoes?.map((alteracao) => {
            if (
              alteracao.id?.toString() === rowdata.idGrauAlteracao?.toString()
            ) {
              nome = alteracao.nome;
            }
          });
          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
        },
      },
      {
        text: "Zona de alteração",
        datafield: "idZonaAlteracao",
        cellsrenderer: (
          row,
          columnfield,
          value,
          defaulthtml,
          columnproperties,
          rowdata
        ) => {
          let zonas = this.state.zonaAlteracao;
          let nome = "";
          zonas?.map((zona) => {
            if (zona.id?.toString() === rowdata.idZonaAlteracao?.toString()) {
              nome = zona.nome;
            }
          });
          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
        },
      },
      {
        text: "Tipo de alteração",
        datafield: "idTipoAlteracao",
        cellsrenderer: (
          row,
          columnfield,
          value,
          defaulthtml,
          columnproperties,
          rowdata
        ) => {
          let alteracoes = this.state.tipoAlteracao;
          let nome = "";
          alteracoes?.map((alteracao) => {
            if (
              alteracao.id?.toString() === rowdata.idTipoAlteracao?.toString()
            ) {
              nome = alteracao.nome;
            }
          });
          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
        },
      },
      { text: "Profundidade", datafield: "profundidade" },
      { text: "Profundidade inicial", datafield: "profundidadeInicial" },
      { text: "Profundidade final", datafield: "profundidadeFinal" },
    ];

    this.colunasRochaHospedeira = [
      {
        text: "Nome",
        datafield: "nomeRochaNaoCadastrada",
        width: "94%",
        cellsrenderer: (
          row,
          columnfield,
          value,
          defaulthtml,
          columnproperties,
          rowdata
        ) => {
          let rochas = [];
          let rochasOrganizadas = [];
          this.state.dadosAflora?.map((dado) => {
            if (dado.id?.toString() === rowdata?.idAfloramento?.toString()) {
              rochas = dado.rochas;
            }
            if (rochas.length > 0) {
              rochas?.map((item) => {
                rochasOrganizadas.push({
                  id: item.idRocha,
                  nome: item.descricao,
                });
              });
            }
          });
          let nome = "";
          rochasOrganizadas?.map((rocha) => {
            if (
              rocha.id?.toString() === rowdata.idRochaAfloramento?.toString()
            ) {
              nome = rocha.nome;
            }
          });

          if (rowdata.cadastrada === "SIM") {
            return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
          }
          if (
            (rowdata.nomeRochaNaoCadastrada !== null ||
              rowdata.nomeRochaNaoCadastrada !== undefined ||
              rowdata.nomeRochaNaoCadastrada !== "") &&
            rowdata.cadastrada === "NAO"
          ) {
            return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${rowdata.nomeRochaNaoCadastrada}</div>`;
          }
        },
      },
    ];

    this.colunasRochaEncaixante = [
      {
        text: "Nome",
        datafield: "nomeRochaNaoCadastrada",
        width: "94%",
        cellsrenderer: (
          row,
          columnfield,
          value,
          defaulthtml,
          columnproperties,
          rowdata
        ) => {
          let rochas = [];
          let rochasOrganizadas = [];
          this.state.dadosAflora?.map((dado) => {
            if (dado.id?.toString() === rowdata?.idAfloramento?.toString()) {
              rochas = dado.rochas;
            }
            if (rochas.length > 0) {
              rochas?.map((item) => {
                rochasOrganizadas.push({
                  id: item.idRocha,
                  nome: item.descricao,
                });
              });
            }
          });
          let nome = "";
          rochasOrganizadas?.map((rocha) => {
            if (
              rocha.id?.toString() === rowdata.idRochaAfloramento?.toString()
            ) {
              nome = rocha.nome;
            }
          });

          if (rowdata.cadastrada === "SIM") {
            return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
          }
          if (
            (rowdata.nomeRochaNaoCadastrada !== null ||
              rowdata.nomeRochaNaoCadastrada !== undefined ||
              rowdata.nomeRochaNaoCadastrada !== "") &&
            rowdata.cadastrada === "NAO"
          ) {
            return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${rowdata.nomeRochaNaoCadastrada}</div>`;
          }
        },
      },
    ];

    this.colunasEstrutural = [
      {
        text: "Tipo",
        datafield: "idTipoControleEstrutural",
        width: "94%",
        cellsrenderer: (
          row,
          columnfield,
          value,
          defaulthtml,
          columnproperties,
          rowdata
        ) => {
          let controles = this.state.tipoControleEstrutural;
          let nome = "";
          controles?.map((controle) => {
            if (
              controle.id?.toString() ===
              rowdata.idTipoControleEstrutural?.toString()
            ) {
              nome = controle.nome;
            }
          });
          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
        },
      },
    ];

    this.colunasRochaNaoCadastrada = [
      {
        text: "Mineral",
        datafield: "idMineral",
        width: "54%",
        cellsrenderer: (
          row,
          columnfield,
          value,
          defaulthtml,
          columnproperties,
          rowdata
        ) => {
          let minerais = this.state.mineral;
          let nome = "";
          minerais?.map((mineral) => {
            if (mineral.id?.toString() === rowdata.idMineral?.toString()) {
              nome = mineral.nome;
            }
          });
          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
        },
      },
      {
        text: "Proporção",
        datafield: "proporcao",
        width: "40%",
        cellsrenderer: (
          row,
          columnfield,
          value,
          defaulthtml,
          columnproperties,
          rowdata
        ) => {
          if (rowdata.proporcao !== undefined && rowdata.proporcao !== null) {
            return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${rowdata.proporcao}%</div>`;
          }
        },
      },
    ];

    this.formUnsubscribe = this.props.referenciaForm.subscribe(
      async (change) => {
        if (
          !isNaN(change.values.coordenadas.latitudeSIRGAS2000) &&
          !isNaN(change.values.coordenadas.longitudeSIRGAS2000) &&
          change.values.coordenadas.latitudeSIRGAS2000 != 0 &&
          change.values.coordenadas.longitudeSIRGAS2000 != 0 &&
          (Number(this.latitudeSIRGAS2000).toFixed(6) !==
            Number(change.values.coordenadas.latitudeSIRGAS2000).toFixed(6) ||
            Number(this.longitudeSIRGAS2000).toFixed(6) !==
            Number(change.values.coordenadas.longitudeSIRGAS2000).toFixed(6))
        ) {
          const atributosGeoreferencia = {
            longitude:
              change.values.coordenadas.longitude ||
              change.values.coordenadas.longitudeSIRGAS2000,
            latitude:
              change.values.coordenadas.latitude ||
              change.values.coordenadas.latitudeSIRGAS2000,
            tipoEntrada: "DECIMAL",
            hemisferio: change.values.coordenadas.hemisferio || 0,
            idDatum: change.values.coordenadas.idDatum || 0,
          };

          this.longitudeSIRGAS2000 =
            change.values.coordenadas.longitudeSIRGAS2000;
          this.latitudeSIRGAS2000 =
            change.values.coordenadas.latitudeSIRGAS2000;

          const responses = await resolverMultiRequestRecmin({
            provinciaEstruturalGeologica: {
              uri: "/arcaboucoTectonico/provinciaEstrutural",
              parameters: atributosGeoreferencia,
            },
            craton: {
              uri: "/arcaboucoTectonico/craton",
              parameters: atributosGeoreferencia,
            },
            faixaMovelBacia: {
              uri: "/arcaboucoTectonico/faixaMovelBacia",
              parameters: atributosGeoreferencia,
            },
            dominio: {
              uri: "/arcaboucoTectonico/dominio",
              parameters: atributosGeoreferencia,
            },
            provinciaMineral: {
              uri: "/provinciaMineral",
              parameters: atributosGeoreferencia,
            },
            distrito: { uri: "/distrito", parameters: atributosGeoreferencia },
            unidadeLito: {
              uri: "/unidadeLitoestratigrafica",
              parameters: atributosGeoreferencia,
              moduleName: "litoestatigrafia",
            },
          });

          this.setState({ ...responses, carregamentoGlobal: false });
        }
      },
      ["modified"]
    );

    this.latitudeSIRGAS2000 = -13.290006;
    this.longitudeSIRGAS2000 = -41.751276;


    this.tabelaIdadeMaximaRef = React.createRef();

    this.idadeMaximaColumns = [
      { text: "Idade", datafield: "idadeMaxima", width: "33%", align: 'center' },
      { text: "Erro", datafield: "erroIdadeMaxima", width: "33%", align: 'center' },
      { text: "Método", datafield: "metodoIdadeMaxima", width: "34%", align: 'center' },
    ];

    this.idadeMaximaDataField = [
      { name: "id", type: "number", map: "id" },
      { name: "idadeMaxima", type: "number", map: "idadeMaxima" },
      { name: "erroIdadeMaxima", type: "number", map: "erroIdadeMaxima" },
      { name: "metodoIdadeMaxima", type: "string", map: "metodoIdadeMaxima" },
    ];

    this.tabelaIdadeMinimaRef = React.createRef();

    this.idadeMinimaColumns = [
      { text: "Idade", datafield: "idadeMinima", width: "33%", align: 'center' },
      { text: "Erro", datafield: "erroIdadeMinima", width: "33%", align: 'center' },
      { text: "Método", datafield: "metodoIdadeMinima", width: "34%", align: 'center' },
    ];

    this.idadeMinimaDataField = [
      { name: "id", type: "number", map: "id" },
      { name: "idadeMinima", type: "number", map: "idadeMinima" },
      { name: "erroIdadeMinima", type: "number", map: "erroIdadeMinima" },
      { name: "metodoIdadeMinima", type: "string", map: "metodoIdadeMinima" },
    ];

  }

  atualizarValidacaoRochas() {
    this.setState({ valorLito: Math.random() })
  }

  podeExcluirRochaLito(entidade, formProps) {
    const atualValorLito = formProps?.form?.getState()?.values?.metalogenia?.dadosInterpretativos?.controleMineralizacao?.controleLitologico;

    if (!entidade || atualValorLito === null || atualValorLito === undefined) return true;

    const itemAtual = this.state.rochasLito.find((item) => item.id === atualValorLito);

    if (entidade.cadastrada === 'NAO') {
      if (entidade.idExibir === itemAtual.idRochaNaoCadastrada)
        return false;
      else
        return true;
    }

    if (entidade.cadastrada === 'SIM') {
      if (!itemAtual || !itemAtual.idAfloramento || !itemAtual.idRochaAfloramento)
        return true;

      if (itemAtual.idAfloramento.toString() === entidade.idAfloramento &&
        itemAtual.idRochaAfloramento.toString() === entidade.idRochaAfloramento) {
        return false;
      }

      else
        return true;
    }

    return true;
  }

  async componentWillUnmount() {
    this.formUnsubscribe();
  }

  async componentDidMount() {
    let dadosRecurso = this.props.referenciaForm.getState().values;
    if (dadosRecurso.estudoMetalogenetico) {
      let estudos = resolverExibidos(dadosRecurso.estudoMetalogenetico);
      if (
        (estudos && estudos.length > 0) ||
        this.props.permitirEdicao === false
      ) {
        this.setState({
          permitirInsercao: false,
        });
      }
    }

    const unidadeNaoCadastradaItem = {
      id: -1,
      nome: 'Unidade não cadastrada'
    }

    let siglasList = await resolverRequestRecminLitoestratigrafia('/unidadeLitoestratigrafica/api/sigla');

    siglasList = [unidadeNaoCadastradaItem, ...siglasList];

    let dadosAflora = [];
    try {
      const afloramentosRes = await Service('/afloramentos', AFLORA).query({ idEstacao: this.props.idEstacao });
      dadosAflora = afloramentosRes.data.dados;
    }
    catch (err) {
      console.log("Erro ao pegar afloramentos", err);
    }

    const responses = await resolverMultiRequestRecmin({
      campo: { uri: "/campo" },
      polo: { uri: "/polo" },
      textura: { uri: "/rochas/textura" },
      associacaoMineralogica: { uri: "/mineral" },
      estruturaMineralizacao: { uri: "/estruturaMineralizacao" },
      grauAlteracao: { uri: "/alteracaoHidrotermal/grau" },
      zonaAlteracao: { uri: "/alteracaoHidrotermal/zona" },
      tipoAlteracao: { uri: "/alteracaoHidrotermal/tipo" },
      distribuicaoAlteracao: { uri: "/alteracaoHidrotermal/distribuicao" },
      mineral: { uri: "/mineral" },
      tipoProcesso: { uri: "/intemperismo/tipoProcesso" },
      tipoMaterialProduto: { uri: "/intemperismo/tipoMaterialProduto" },
      prefixoRocha: { uri: "/rochas/prefixos", moduleName: "aflora" },
      complementoRocha: { uri: "/rochas/complementos", moduleName: "aflora" },
      formaRelacaoHospedeira: { uri: "/formaRelacaoHospedeira" },
      tipoControleEstrutural: { uri: "/tipoControleEstrutural" },
      estratigrafico: { uri: "/tipoControleEstrutural" },
      litologico: { uri: "/litologico" },
      classe: { uri: "/deposito/classe" },
      regimeTectonico: { uri: "/regimeTectonico" },
      ambienteTectonico: { uri: "/ambienteTectonico" },
      subambienteTectonico: { uri: "/subAmbienteTectonico" },
      rochas: { uri: "/rochas/nomeadas", moduleName: "aflora" },
      escala: { uri: "/escalaAnos" },
      metodo: { uri: "/sistemasIsotopicos" },
      provinciaMineral: { uri: "/provinciaMineral" },
      distrito: { uri: "/distrito" },
    });

    this.setState({
      ...responses,
      dadosAflora: dadosAflora,
      siglasUnidadeLito: siglasList,
      afloramento: [],
      tiposNotacao: [
        {
          id: 2,
          nome: "Direção/Rumo – Quadrante",
        },
        {
          id: 3,
          nome: "Notação Clar",
        },
      ],
    });

    if (
      this.state.parametrosParaSelects?.latitude &&
      this.state.parametrosParaSelects?.longitude
    ) {
      const responses = await resolverMultiRequestRecmin({
        provinciaEstruturalGeologica: {
          uri: "/arcaboucoTectonico/provinciaEstrutural",
          parameters: this.state.parametrosParaSelects,
        },
        craton: {
          uri: "/arcaboucoTectonico/craton",
          parameters: this.state.parametrosParaSelects,
        },
        faixaMovelBacia: {
          uri: "/arcaboucoTectonico/faixaMovelBacia",
          parameters: this.state.parametrosParaSelects,
        },
        dominio: {
          uri: "/arcaboucoTectonico/dominio",
          parameters: this.state.parametrosParaSelects,
        },
        unidadeLito: {
          uri: "/unidadeLitoestratigrafica",
          parameters: this.state.parametrosParaSelects,
          moduleName: "litoestatigrafia",
        },
      });
      this.setState({
        ...responses,
        carregamentoGlobal: false,
      });

      if (this.state.unidadeLito.length === 1)
        this.updateIdadeRochaTable(this.state.unidadeLito[0].nome)
    }
  }

  onSubmit({ alteracoesForm }) {
    this.props.referenciaForm.batch(() => {
      for (const [key, val] of Object.entries(alteracoesForm)) {
        this.props.referenciaForm.mutators.setValue(`${key}`, val);
      }
    });

    if (this.props.referenciaForm.getState().values.estudoMetalogenetico) {
      let arrayEstudos = resolverExibidos(
        this.props.referenciaForm.getState().values.estudoMetalogenetico
      );
      let arrayFonteReferencia = [];
      arrayEstudos?.map((estudo) => {
        if (estudo.fontesReferencia) {
          let exibidos = resolverExibidos(estudo.fontesReferencia);
          exibidos?.map((item) => {
            if (item.modoCadastro === "SIMPLIFICADO") {
              item.referencia = item.descricao;
            }
            if (item.modoCadastro === "DETALHADO") {
              const autores = item.autores.split(",");
              if (autores.length === 1)
                item.referencia = `${autores[0].split(" ").pop()}. (${item.ano
                  }). ${item.titulo}.`;
              if (autores.length === 2)
                item.referencia = `${autores[0].split(" ").pop()} e ${autores[1]
                  .split(" ")
                  .pop()}. (${item.ano}). ${item.titulo}.`;
              if (autores.length >= 3)
                item.referencia = `${autores[0].split(" ").pop()} et al. (${item.ano
                  }). ${item.titulo}.`;
            }
            if (item.modoCadastro === "BASE_PROJETOS" && item.idProjeto !== "")
              item.referencia = item.idProjeto;
            if (item.modoCadastro === "BASE_PROJETOS" && item.idProjeto === "")
              item.referencia = item.link;
          });
          arrayFonteReferencia.push(exibidos);
        }
        return 0;
      });
      this.props.atualizarTabelaFonteReferenciaRecursoMineral(
        arrayFonteReferencia.flat(),
        "ESTUDO_METALOGENETICO"
      );
    }

    if (this.props.referenciaForm.getState().values.estudoMetalogenetico) {
      if (
        resolverExibidos(
          this.props.referenciaForm.getState().values.estudoMetalogenetico
        ).length === 1
      ) {
        this.setState({
          permitirInsercao: false,
        });
      } else {
        this.setState({
          permitirInsercao: true,
        });
      }
    }
  }

  onSubmitSubForm({ alteracoesForm }, formProps) {
    formProps?.batch(() => {
      for (const [key, val] of Object.entries(alteracoesForm)) {
        formProps?.mutators?.setValue(`${key}`, val);
      }
    });
  }

  onSubmitSubFormMinerais({ alteracoesForm }, formProps) {
    formProps?.batch(() => {
      for (const [key, val] of Object.entries(alteracoesForm)) {
        formProps?.mutators?.setValue(`${key}`, val);
      }
    });

    let totalPorcentagem = 0;

    if (formProps.getState().values.minerais) {
      let minerais = resolverExibidos(formProps.getState().values.minerais);
      if (minerais && minerais.length > 0) {
        minerais.map((mineral) => {
          if (mineral.percentual) {
            totalPorcentagem += mineral.percentual;
          }
        });
        if (totalPorcentagem > 0) {
          formProps?.mutators?.setValue(
            `mensagemTotalPorcentagem`,
            totalPorcentagem
          );
        }
        if (totalPorcentagem === 0)
          formProps?.mutators?.setValue(`mensagemTotalPorcentagem`, '');
      }
      else {
        formProps?.mutators?.setValue(`mensagemTotalPorcentagem`, null);
      }
    }
  }

  onSubmitSubFormRocha({ alteracoesForm }, formProps) {
    formProps?.batch(() => {
      for (const [key, val] of Object.entries(alteracoesForm)) {
        formProps?.mutators?.setValue(`${key}`, val);
      }
    });

    let totalPorcentagem = 0;

    if (formProps.getState().values.rochaNaoCadastrada.mineraisRocha) {
      let minerais = resolverExibidos(
        formProps.getState().values.rochaNaoCadastrada.mineraisRocha
      );
      if (minerais && minerais.length > 0) {
        minerais.map((mineral) => {
          if (mineral.proporcao) {
            totalPorcentagem += mineral.proporcao;
          }
        });
        if (totalPorcentagem > 0) {
          formProps?.mutators?.setValue(
            `mensagemTotalPorcentagem`,
            totalPorcentagem
          );
        }
      } else {
        formProps?.mutators?.setValue(`mensagemTotalPorcentagem`, null);
      }
    }
  }

  preencherNomenclaturaRochaHospedeira(values, formProps) {
    let prefixoRocha = values?.rochaNaoCadastrada?.idPrefixoRocha || "";
    let nomeRocha = values?.rochaNaoCadastrada?.idRocha || "";
    let complementoRocha = values?.rochaNaoCadastrada?.idComplementoRocha || "";

    this.state.prefixoRocha?.map((prefixo) => {
      if (prefixo.id === values?.rochaNaoCadastrada?.idPrefixoRocha) {
        prefixoRocha = prefixo.nome;
      }
    });
    this.state.rochas?.map((rocha) => {
      if (rocha.id === values?.rochaNaoCadastrada?.idRocha) {
        nomeRocha = rocha.nome;
      }
    });
    this.state.complementoRocha?.map((complemento) => {
      if (complemento.id === values?.rochaNaoCadastrada?.idComplementoRocha) {
        complementoRocha = complemento.nome;
      }
    });
    if (values?.rochaNaoCadastrada?.mineraisRocha) {
      let mineraisExibidosTemp = [];
      let minerais = resolverExibidos(
        values?.rochaNaoCadastrada?.mineraisRocha
      );
      let mineraisExibidos = [];
      minerais = minerais.sort((a, b) => a.proporcao - b.proporcao);
      minerais?.map((mineral) => {
        if (mineral.proporcao > 5 && mineral.compoeNomeRocha === "SIM") {
          for (let i = 0; i < this.state.mineral.length; i++) {
            if (
              mineral.idMineral?.toString() ===
              this.state.mineral[i].id?.toString()
            ) {
              mineraisExibidos.push(this.state.mineral[i].nome);
            }
          }
        }
      });
      if (mineraisExibidos.length > 4) {
        mineraisExibidosTemp = mineraisExibidos.slice(-4)
      } else {
        mineraisExibidosTemp = mineraisExibidos;
      }
      formProps.form?.mutators?.setValue(
        `nomeRochaNaoCadastrada`,
        mineraisExibidosTemp?.toString().split(",").join(" ") +
        " " +
        prefixoRocha +
        " " +
        nomeRocha +
        " " +
        complementoRocha
      );
    } else {
      formProps.form?.mutators?.setValue(
        `nomeRochaNaoCadastrada`,
        prefixoRocha + " " + nomeRocha + " " + complementoRocha
      );
    }
  }

  preencherNomenclaturaRochaEncaixante(values, formProps) {
    let prefixoRocha = values?.rochaNaoCadastrada?.idPrefixoRocha || "";
    let nomeRocha = values?.rochaNaoCadastrada?.idRocha || "";
    let complementoRocha = values?.rochaNaoCadastrada?.idComplementoRocha || "";

    this.state.prefixoRocha?.map((prefixo) => {
      if (prefixo.id === values?.rochaNaoCadastrada?.idPrefixoRocha) {
        prefixoRocha = prefixo.nome;
      }
    });
    this.state.rochas?.map((rocha) => {
      if (rocha.id === values?.rochaNaoCadastrada?.idRocha) {
        nomeRocha = rocha.nome;
      }
    });
    this.state.complementoRocha?.map((complemento) => {
      if (complemento.id === values?.rochaNaoCadastrada?.idComplementoRocha) {
        complementoRocha = complemento.nome;
      }
    });
    if (values?.rochaNaoCadastrada?.mineraisRocha) {
      let mineraisExibidosTemp = [];
      let minerais = resolverExibidos(
        values?.rochaNaoCadastrada?.mineraisRocha
      );
      let mineraisExibidos = [];
      minerais = minerais.sort((a, b) => a.proporcao - b.proporcao);
      minerais?.map((mineral) => {
        if (mineral.proporcao > 5 && mineral.compoeNomeRocha === "SIM") {
          for (let i = 0; i < this.state.mineral.length; i++) {
            if (
              mineral.idMineral?.toString() ===
              this.state.mineral[i].id?.toString()
            ) {
              mineraisExibidos.push(this.state.mineral[i].nome);
            }
          }
        }
      });
      if (mineraisExibidos.length > 4) {
        mineraisExibidosTemp = mineraisExibidos.slice(-4)
      } else {
        mineraisExibidosTemp = mineraisExibidos;
      }

      formProps.form?.mutators?.setValue(
        `nomeRochaNaoCadastrada`,
        mineraisExibidosTemp?.toString().split(",").join(" ") +
        " " +
        prefixoRocha +
        " " +
        nomeRocha +
        " " +
        complementoRocha
      );
    } else {
      formProps.form?.mutators?.setValue(
        `nomeRochaNaoCadastrada`,
        prefixoRocha + " " + nomeRocha + " " + complementoRocha
      );
    }
  }

  validacoesAlteracaoHidrotermal(valores, formProps) {
    const minerais = valores.minerais ? resolverExibidos(valores.minerais) : undefined;
    if (!minerais) return;

    let erros = [];
    let mineraisComOrdem = 0;
    let mineraisComPercentual = 0;
    let totalPorcentagem = 0;

    minerais.forEach(mineral => {
      if (mineral.ordemAbundancia)
        mineraisComOrdem += 1;

      if (mineral.percentual) {
        mineraisComPercentual += 1;
        totalPorcentagem += mineral.percentual;
      }
    });

    if (mineraisComOrdem && mineraisComOrdem < minerais.length)
      erros.push('Se um mineral possuir ordem de abundância, todos devem possuir');

    if (mineraisComPercentual && mineraisComPercentual < minerais.length)
      erros.push('Se um mineral possuir percentual, todos devem possuir');

    if (mineraisComPercentual && totalPorcentagem != 100)
      erros.push('A soma dos percentuais dos minerais deve totalizar 100%');

    if (erros.length)
      return {
        [FORM_ERROR]: erros.toString().replace(/,/g, ', ')
      }
  }

  validarPorcentagemMineraisRochaNaoCadastradaHospedeira(valores) {
    let qntMinerais;
    let totalPorcentagem = 0;
    if (valores.cadastrada === "NAO") {
      if (valores?.rochaNaoCadastrada?.mineraisRocha) {
        qntMinerais = resolverExibidos(
          valores.rochaNaoCadastrada.mineraisRocha
        );
        if (qntMinerais.length === 0) {
          return {
            [FORM_ERROR]: "Ao menos um mineral deve ser associado a rocha.",
          };
        }
        qntMinerais?.map((mineral) => {
          if (mineral.proporcao) {
            totalPorcentagem += mineral.proporcao;
          }
          return 0;
        });
        if (
          this.props.referenciaForm.getState().values.modoCadastro ===
          "DEFINITIVO" &&
          totalPorcentagem !== 100
        ) {
          return {
            [FORM_ERROR]:
              "A soma dos percentuais dos minerais deve totalizar 100%",
          };
        }
      } else {
        return {
          [FORM_ERROR]: "Ao menos um mineral deve ser associado a rocha.",
        };
      }
      if (valores.mensagemTotalPorcentagem < 100 || !valores.mensagemTotalPorcentagem) {
        return {
          [FORM_ERROR]:
            "A soma dos percentuais dos minerais deve totalizar 100%",
        };
      }
    }
  }

  validarPorcentagemMineraisRochaNaoCadastradaEncaixante(valores) {
    let qntMinerais;
    let totalPorcentagem = 0;
    if (valores.cadastrada === "NAO") {
      if (valores?.rochaNaoCadastrada?.mineraisRocha) {
        qntMinerais = resolverExibidos(
          valores.rochaNaoCadastrada.mineraisRocha
        );
        if (qntMinerais.length === 0) {
          return {
            [FORM_ERROR]: "Ao menos um mineral deve ser associado a rocha.",
          };
        }
        qntMinerais?.map((mineral) => {
          if (mineral.proporcao) {
            totalPorcentagem += mineral.proporcao;
          }
          return 0;
        });
        if (
          this.props.referenciaForm.getState().values.modoCadastro ===
          "DEFINITIVO" &&
          totalPorcentagem !== 100
        ) {
          return {
            [FORM_ERROR]:
              "A soma dos percentuais dos minerais deve totalizar 100%",
          };
        }
      } else {
        return {
          [FORM_ERROR]: "Ao menos um mineral deve ser associado a rocha.",
        };
      }
      if (valores.mensagemTotalPorcentagem < 100 || !valores.mensagemTotalPorcentagem) {
        return {
          [FORM_ERROR]:
            "A soma dos percentuais dos minerais deve totalizar 100%",
        };
      }
    }
  }

  validarOrdemAbundanciaMinerais(valores, form) {
    let erros = [];

    if (!valores.ordemAbundancia && !valores.percentual) {
      erros.push(' Não é possível cadastrar um mineral sem a ordem de abundância ou percentual.');
    }

    if (erros.length) {
      let message = '';
      [...new Set(erros)].forEach(e => message += e);
      return {
        [FORM_ERROR]: message
      }
    }

    if (form.getState().values.minerais) {
      let minerais = resolverExibidos(form.getState().values.minerais);
      let isError = false;
      let temAbundancia = 0;
      let erroPercentual = false;
      let erros = [];


      minerais.forEach((mineral) => {
        if (mineral.percentual && !valores.percentual)
          erroPercentual = true;

        if (mineral.ordemAbundancia)
          temAbundancia++;

        if (
          mineral.id === valores.id &&
          mineral.ordemAbundancia === valores.ordemAbundancia
        ) {
          isError = false;
        } else if (
          mineral.ordemAbundancia &&
          valores.ordemAbundancia &&
          mineral.ordemAbundancia === valores.ordemAbundancia
        ) {
          isError = true;
        }
      });

      if (temAbundancia > 0 && !valores.ordemAbundancia)
        erros.push("Se um mineral possuir ordem de abundância, todos devem possuir");

      if (isError)
        erros.push("Minerais não podem ter ordem de abundância repetidas");

      if (erroPercentual)
        erros.push("Se um mineral possuir percentual, todos devem possuir")

      if (erros.length)
        return {
          [FORM_ERROR]: erros.toString().replace(/,/g, ', ')
        }
    }



  }

  validarAssociacaoMineralogica(dados) {
    if (dados?.length > 20) {
      return (
        "Associação mineralógica deve ter no máximo 20 minerais selecionados. Total de minerais selecionados atualmente: " +
        dados.length +
        "."
      );
    }
  }

  async selecionarSistemaDeposito(formProps, idClasseClassificacaoDeposito) {


    if (!idClasseClassificacaoDeposito)
      return;

    let id = formProps.form.getState().values.metalogenia.dadosInterpretativos.classificacaoDeposito.idSistemaClassificacaoDeposito;
    let sistemasClassificacao = await resolverRequestRecmin("/deposito/sistema/" + idClasseClassificacaoDeposito);
    formProps.form.getState().values.tempSistemasClassificacaoDeposito = sistemasClassificacao;

    if (formProps.form.getState().values.tempSistemasClassificacaoDeposito.length >= 1) {
      let modelos = formProps.form.getState().values.tempSistemasClassificacaoDeposito;
      if (!id)
        id = formProps.form.getState().values.tempSistemasClassificacaoDeposito[0].id;
      let existe = modelos.some((m) => m.id == id);
      if (!existe) {
        id = formProps.form.getState().values.tempSistemasClassificacaoDeposito[0].id;
      }

      formProps.form.getState().values.metalogenia.dadosInterpretativos.classificacaoDeposito.idSistemaClassificacaoDeposito = id;
      formProps.form?.mutators?.setValue(
        "idSistemaClassificacaoDeposito",
        formProps.form.getState().values.tempSistemasClassificacaoDeposito[0].id
      );
      this.selecionarProcessoFundamentalDeposito(formProps, idClasseClassificacaoDeposito, formProps.form.getState().values?.metalogenia?.dadosInterpretativos?.classificacaoDeposito?.idSistemaClassificacaoDeposito)
    }
  }

  async selecionarProcessoFundamentalDeposito(formProps, idClasseClassificacaoDeposito, idSistemaClassificacaoDeposito) {


    if (!idClasseClassificacaoDeposito || !idSistemaClassificacaoDeposito)
      return;

    let id = formProps.form.getState().values.metalogenia.dadosInterpretativos.classificacaoDeposito.idProcessoFundamental;
    let processosFundamentais = await resolverRequestRecmin("/processoFundamental/" + idClasseClassificacaoDeposito + "/" + idSistemaClassificacaoDeposito);
    formProps.form.getState().values.tempProcessosClassificacaoDeposito = processosFundamentais;

    if (formProps.form.getState().values.tempProcessosClassificacaoDeposito.length >= 1) {
      let modelos = formProps.form.getState().values.tempProcessosClassificacaoDeposito;
      if (!id)
        id = formProps.form.getState().values.tempProcessosClassificacaoDeposito[0].id;
      let existe = modelos.some((m) => m.id == id);
      if (!existe) {
        id = formProps.form.getState().values.tempProcessosClassificacaoDeposito[0].id;
      }

      formProps.form.getState().values.metalogenia.dadosInterpretativos.classificacaoDeposito.idProcessoFundamental = id;
      formProps.form?.mutators?.setValue(
        "idProcessoFundamental",
        formProps.form.getState().values.tempProcessosClassificacaoDeposito[0].id
      );
    }
    else {
      formProps.form.getState().values.metalogenia.dadosInterpretativos.classificacaoDeposito.idProcessoFundamental = null;
    }
    this.selecionarTipoModeloDeposito(formProps, idClasseClassificacaoDeposito, idSistemaClassificacaoDeposito, formProps.form.getState().values?.metalogenia?.dadosInterpretativos?.classificacaoDeposito?.idProcessoFundamental);
  }

  async selecionarTipoModeloDeposito(formProps, idClasseClassificacaoDeposito, idSistemaClassificacaoDeposito, idProcessoFundamental) {


    if (!idClasseClassificacaoDeposito || !idSistemaClassificacaoDeposito || !idProcessoFundamental)
      return;

    let idModelo = formProps.form.getState().values.metalogenia.dadosInterpretativos.classificacaoDeposito.idModeloClassificacaoDeposito;
    let tiposModelosDeposito = await resolverRequestRecmin("/deposito/modelo/" + idClasseClassificacaoDeposito + "/" + idSistemaClassificacaoDeposito + "/" + idProcessoFundamental);
    formProps.form.getState().values.tempModeloClassificacaoDeposito = tiposModelosDeposito;

    if (formProps.form.getState().values.tempModeloClassificacaoDeposito.length >= 1) {
      let modelos = formProps.form.getState().values.tempModeloClassificacaoDeposito;
      if (!idModelo)
        idModelo = formProps.form.getState().values.tempModeloClassificacaoDeposito[0].id;
      let existe = modelos.some((m) => m.id == idModelo);
      if (!existe) {
        idModelo = formProps.form.getState().values.tempModeloClassificacaoDeposito[0].id;
      }

      formProps.form.getState().values.metalogenia.dadosInterpretativos.classificacaoDeposito.idModeloClassificacaoDeposito = idModelo;
      formProps.form?.mutators?.setValue(
        "idModeloClassificacaoDeposito",
        formProps.form.getState().values.tempModeloClassificacaoDeposito[0].id
      );
    }
    else {
      formProps.form.getState().values.metalogenia.dadosInterpretativos.classificacaoDeposito.idModeloClassificacaoDeposito = null;
      formProps.form?.mutators?.setValue(
        "idModeloClassificacaoDeposito",
        null
      );
    }
  }

  async updateIdadeRochaTable(nomeUnidadeLito) {
    const selected = this.state.unidadeLito.find(item => item.nome === nomeUnidadeLito);

    const res = await resolverRequestRecminLitoestratigrafia(`/unidadeLitoestratigrafica/api/idade/${selected.idUnidadeEstratigrafica}`);

    if (res && res.length) {

      const idadeMaxima = [{
        idadeMaxima: res[0].idadeMaxima,
        erroIdadeMaxima: res[0].erroIdadeMaxima,
        metodoIdadeMaxima: res[0].metodoIdadeMaxima
      }];
      const idadeMinima = [{
        idadeMinima: res[0].idadeMinima,
        erroIdadeMinima: res[0].erroIdadeMinima,
        metodoIdadeMinima: res[0].metodoIdadeMinima
      }];


      this.setState({
        idadeMaxima,
        idadeMinima,
        nomeUnidadeEstratigrafica: nomeUnidadeLito
      })
    } else {
      this.setState({
        idadeMaxima: [],
        idadeMinima: [],
        nomeUnidadeEstratigrafica: nomeUnidadeLito
      });

    }
  }

  render() {
    return (
      <BlockUi blocking={this.state.carregamentoGlobal}>
        <Field name={"estudoMetalogenetico"} subscription={{ value: true }}>
          {({ input: { value: estudoMetalogenetico = {} } }) => (
            <SubForm
              keepDirtyOnReinitialize
              exibirBotaoInsercao={this.props.permitirEdicao && this.state.permitirInsercao}
              permitirEdicao={this.props.permitirEdicao}
              permitirExclusao={this.props.permitirEdicao}
              permitirVisualizacao={!this.props.permitirEdicao}
              tituloForm="Metalogenia"
              nome="estudoMetalogenetico"
              onSubmit={this.onSubmit.bind(this)}
              colunas={this.colunasEstudosMetalogeneticos}
              campos={this.state.datafieldsEstudosMetalogeneticos}
              validar={(valores) => this.props.modoDefinitivo ? validacoesEstudosMetalogeneticos(valores) : undefined}
              elementos={estudoMetalogenetico}
              naoValidarCamposVoltar={['tempRegimesTectonicos', 'tempAmbientesTectonicos', 'tempSistemasClassificacaoDeposito', 'tempProcessosClassificacaoDeposito', 'tempModeloClassificacaoDeposito']}

              valoresIniciais={{
                unidadeLito: {
                  necessitaValidacao: "NAO",
                },
                idRegimeTectonico: null,
                idAmbienteTectonico: null,
              }}
              formSubscription={{}}
              renderForm={({ formProps }) => {
                let rochasCadastradasEncaixantes =[];
                let rochasCadastradasHospedeiras =[];
                if (formProps.form.getState().values?.metalogenia?.dadosDescritivos?.rochasHospedeiras){
                  rochasCadastradasHospedeiras = resolverExibidos(formProps.form.getState().values?.metalogenia?.dadosDescritivos?.rochasHospedeiras);
                }
                if (formProps.form.getState().values?.metalogenia?.dadosDescritivos?.rochasEncaixantes){
                  rochasCadastradasEncaixantes = resolverExibidos(formProps.form.getState().values?.metalogenia?.dadosDescritivos?.rochasEncaixantes);
                }
                
                return (
                  <>
                    <Field
                      component={HiddenField}
                      name={`labelFixoEstudosMetalogeneticos`}
                      initialValue="Metalogenia"
                    />
                    <Field
                      component={HiddenField}
                      name={`tempRegimesTectonicos`}
                    />
                    <Field
                      component={HiddenField}
                      name={`tempSistemasClassificacaoDeposito`}
                    />

                    <Field
                      component={HiddenField}
                      name={`tempProcessosClassificacaoDeposito`}
                    />
                    <Field
                      component={HiddenField}
                      name={`tempModeloClassificacaoDeposito`}
                    />

                    <Row>
                      <Col md={12}>
                        <Card.Title>Metalogenia</Card.Title>
                      </Col>
                      <Col>
                        <Field
                          component={HiddenField}
                          name="unidadeLito.id"
                          initialValue={
                            formProps.form.getState().values?.unidadeLito?.id
                          }
                        />
                      </Col>
                    </Row>

                    <Card className="mt-4">
                      <Card.Body>
                        <Row>
                          <Col md={12}>
                            <Field
                              name="unidadeLito.unidadeLito"
                              subscription={{ value: true }}
                            >
                              {({ input: { value: unidadeLito } }) => {

                                if (unidadeLito && unidadeLito != this.state.nomeUnidadeEstratigrafica) {
                                  if (formProps?.form?.getState()?.values?.unidadeLito?.unidadeLito) {
                                    this.updateIdadeRochaTable(formProps?.form?.getState()?.values?.unidadeLito?.unidadeLito)
                                  }
                                }

                                let placeholder = "Não foram encontrados registros para a localização informada";
                                let listaUnidadeLito = [];
                                let campoDesabilitado = false;
                                let valorInicialLito = null;

                                if (this.state.unidadeLito) {
                                  this.state.unidadeLito.map((dado) =>
                                    listaUnidadeLito.push({
                                      id: dado.nome,
                                      nome: `${dado.nome} (${dado.sigla})`,
                                    })
                                  );

                                  if (listaUnidadeLito.length === 1) {
                                    valorInicialLito = listaUnidadeLito[0].id;
                                    formProps?.form?.mutators?.setValue('unidadeLito.unidadeLito', listaUnidadeLito[0].id);
                                  }
                                }

                                if (listaUnidadeLito.length <= 1 || this.props.permitirEdicao === false) {
                                  campoDesabilitado = true;
                                }

                                if (listaUnidadeLito.length > 1) {
                                  placeholder = "Selecione..";
                                }

                                return (
                                  <Field
                                    disabled={campoDesabilitado}
                                    name="unidadeLito.unidadeLito"
                                    component={DropdownListField}
                                    label="Unidade litoestratigráfica hospedeira da mineralização"
                                    dica="Informe, a partir da biblioteca, a Unidade Litoestratigráfica que hospeda a mineralização"
                                    placeholder={placeholder}
                                    initialValue={valorInicialLito}
                                    elementos={listaUnidadeLito}
                                  />
                                );
                              }}
                            </Field>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={12} className="mt-4">
                            <Container style={{ borderStyle: 'solid', borderWidth: '1px', borderBottomWidth: '2px', borderColor: '#c7c7c7' }}>
                              <Row>
                                <Col md={12} className="p-0">
                                  <Container className="p-3 pb-1 d-flex align-items-center justify-content-center">
                                    <Card.Subtitle className="text-center font-weight-bold">
                                      Idade da rocha encaixante ou hospedeira
                                    </Card.Subtitle>
                                  </Container>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6} className="p-0">
                                  <Container className="p-3 pb-1 d-flex align-items-center justify-content-center" style={{ borderColor: '#c7c7c7', borderStyle: 'solid', borderWidth: '1px', borderLeftWidth: '0px', borderRightWidth: '1px', borderBottomWidth: '0px' }}>
                                    <Card.Subtitle className="text-center">
                                      Idade máxima (Ma)
                                    </Card.Subtitle>
                                  </Container>
                                </Col>
                                <Col md={6} className="p-0">
                                  <Container className="p-3 pb-1 d-flex align-items-center justify-content-center" style={{ borderColor: '#c7c7c7', borderStyle: 'solid', borderWidth: '1px', borderLeftWidth: '0px', borderRightWidth: '0px', borderBottomWidth: '0px' }}>
                                    <Card.Subtitle className="text-center">
                                      Idade mínima (Ma)
                                    </Card.Subtitle>
                                  </Container>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6} className="p-0">
                                  <Table
                                    ref={this.tabelaIdadeMaximaRef}
                                    columns={this.idadeMaximaColumns}
                                    items={this.state.idadeMaxima}
                                    pageable={false}
                                  />
                                </Col>
                                <Col md={6} className="p-0">
                                  <Table
                                    ref={this.tabelaIdadeMinimaRef}
                                    columns={this.idadeMinimaColumns}
                                    items={this.state.idadeMinima}
                                    pageable={false}
                                  />
                                </Col>
                              </Row>
                            </Container>
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col md={2}>
                            <legend className="w-auto small">
                              Necessita validação?
                              <Hint textoAjuda="Sim = necessita alterar a Unidade Litoestratigráfica; ou Não = a Unidade Litoestratigráfica escolhida é adequada" />
                            </legend>
                            <Field name={"unidadeLito.necessitaValidacao"} subscription={{ value: true }}>
                              {({ input: necessitaValidacao }) => {

                                if (necessitaValidacao && necessitaValidacao.value) {
                                  const exibir = necessitaValidacao.value === 'SIM' ? true : false;
                                  if (this.state.exibirNecessitaValidacao != exibir)
                                    this.setState({ exibirNecessitaValidacao: exibir });
                                }

                                return <Field
                                  disabled={!this.props.permitirEdicao}
                                  component={RadioButtonSetField}
                                  name="unidadeLito.necessitaValidacao"
                                  elementos={this.state.necessitaValidacao}
                                />
                              }}
                            </Field>
                          </Col>
                          {this.state.exibirNecessitaValidacao ? (
                            <>
                              <Col md={5}>
                                <Field
                                  disabled={!this.props.permitirEdicao}
                                  name="unidadeLito.idSigla"
                                  component={DropdownListField}
                                  label="Proposta de nova unidade litoestratigráfica hospedeira"
                                  dica="Escolha, a partir da biblioteca, a opção mais adequada"
                                  initialValue={-1}
                                  elementos={this.state.siglasUnidadeLito}
                                  required={this.props.modoDefinitivo}
                                  validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica(
                                    "Proposta de nova unidade litoestratigráfica hospedeira"
                                  ))}
                                />
                              </Col>
                              <Col md={5}>
                                <Field
                                  disabled={!this.props.permitirEdicao}
                                  name="unidadeLito.informacoesAdicionais"
                                  component={TextAreaField}
                                  label="Informações adicionais da unidade litoestratigráfica"
                                  dica="Justificar de maneira clara e precisa a necessidade de modificação da Unidade Litoestratigráfica"
                                  initialValue=""
                                  maxLength={255}
                                  required={this.props.modoDefinitivo}
                                  validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica(
                                    "Informações adicionais da unidade litoestratigráfica"
                                  ))}
                                />
                              </Col>
                            </>
                          ) : (
                            <></>
                          )}
                        </Row>
                      </Card.Body>
                    </Card>

                    <Card className="mt-3">
                      <Card.Body>
                        <Card.Subtitle>
                          { `Arcabouço tectônico`}
                          <Hint textoAjuda={'Campos com preenchimento automático; validado espacialmente'} />
                        </Card.Subtitle>
                        <Row className="mt-3">
                          <Col>
                            <Field
                              disabled={!this.props.permitirEdicao}
                              component={HiddenField}
                              name="arcaboucoTectonico.id"
                              initialValue={
                                formProps.form.getState().values
                                  ?.arcaboucoTectonico?.id
                              }
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col md={6}>
                            <Field
                              name="arcaboucoTectonico.idProvinciaEstruturalGeologica"
                              subscription={{ value: true }}
                            >
                              {({
                                input: { value: provinciaEstruturalGeologica },
                              }) => {
                                let placeholder =
                                  "Não foram encontrados registros para a localização informada";
                                let campoDesabilitado = false;

                                let listaFiltrada = [];
                                this.state.provinciaEstruturalGeologica?.map(
                                  (dado) => {
                                    dado?.provinciaAtual
                                      ?.toString()
                                      .toLowerCase() === "s" &&
                                      listaFiltrada.push(dado);
                                  }
                                );
                                let valorInicial = null;
                                if (listaFiltrada.length === 1) {
                                  valorInicial = listaFiltrada[0]?.id;
                                  if (formProps.form.getState().values?.arcaboucoTectonico?.idProvinciaEstruturalGeologica != valorInicial) {
                                    formProps.form.mutators.setValue('arcaboucoTectonico.idProvinciaEstruturalGeologica', valorInicial);
                                  }
                                }

                                if (
                                  listaFiltrada.length === 0 ||
                                  listaFiltrada.length === 1 ||
                                  this.props.permitirEdicao === false
                                ) {
                                  campoDesabilitado = true;
                                }
                                if (listaFiltrada.length > 0) {
                                  placeholder = "Selecione..";
                                }

                                return (
                                  <Field
                                    name="arcaboucoTectonico.idProvinciaEstruturalGeologica"
                                    component={DropdownListField}
                                    label="Província estrutural / geológica"
                                    elementos={listaFiltrada}
                                    placeholder={placeholder}
                                    initialValue={valorInicial}
                                    disabled={campoDesabilitado}
                                  />
                                );
                              }}
                            </Field>
                          </Col>

                          <Col md={6}>
                            <Field
                              name="arcaboucoTectonico.idCraton"
                              subscription={{ value: true }}
                            >
                              {({ input: { value: idCraton } }) => {
                                let valorInicial = null;

                                let placeholder =
                                  "Não foram encontrados registros para a localização informada";
                                let campoDesabilitado = false;

                                if (this.state?.craton?.length === 1) {
                                  valorInicial = this.state?.craton[0]?.id;
                                  if (formProps.form.getState().values?.arcaboucoTectonico?.idCraton != valorInicial) {
                                    formProps.form.mutators.setValue('arcaboucoTectonico.idCraton', valorInicial);
                                  }
                                }

                                if (
                                  this.state.craton.length === 0 ||
                                  this.state.craton.length === 1 ||
                                  this.props.permitirEdicao === false
                                ) {
                                  campoDesabilitado = true;
                                }
                                if (this.state.craton.length > 0) {
                                  placeholder = "Selecione..";
                                }

                                return (
                                  <Field
                                    name="arcaboucoTectonico.idCraton"
                                    component={DropdownListField}
                                    label="Cráton"
                                    placeholder={placeholder}
                                    elementos={this.state.craton}
                                    initialValue={valorInicial}
                                    disabled={campoDesabilitado}
                                  />
                                );
                              }}
                            </Field>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={4}>
                            <Field
                              name="arcaboucoTectonico.idfaixaMovelBacia"
                              subscription={{ value: true }}
                            >
                              {({ input: { value: idfaixaMovelBacia } }) => {
                                let valorInicial = null;

                                let placeholder =
                                  "Não foram encontrados registros para a localização informada";
                                let campoDesabilitado = false;

                                if (this.state?.faixaMovelBacia?.length === 1) {
                                  valorInicial =
                                    this.state?.faixaMovelBacia[0]?.id;
                                  if (formProps.form.getState().values?.arcaboucoTectonico?.idfaixaMovelBacia != valorInicial) {
                                    formProps.form.mutators.setValue('arcaboucoTectonico.idfaixaMovelBacia', valorInicial);
                                  }
                                }

                                if (
                                  this.state.faixaMovelBacia.length === 0 ||
                                  this.state.faixaMovelBacia.length === 1 ||
                                  this.props.permitirEdicao === false
                                ) {
                                  campoDesabilitado = true;
                                }
                                if (this.state.faixaMovelBacia.length > 0) {
                                  placeholder = "Selecione..";
                                }

                                return (
                                  <Field
                                    name="arcaboucoTectonico.idfaixaMovelBacia"
                                    component={DropdownListField}
                                    label="Faixa móvel / Bacia"
                                    initialValue={valorInicial}
                                    disabled={campoDesabilitado}
                                    placeholder={placeholder}
                                    elementos={this.state?.faixaMovelBacia}
                                  />
                                );
                              }}
                            </Field>
                          </Col>
                          <Col md={4}>
                            <Field
                              name="arcaboucoTectonico.idDominio"
                              subscription={{ value: true }}
                            >
                              {({ input: { value: idDominio } }) => {
                                let valorInicial = null;

                                if (this.state?.dominio?.length === 1) {
                                  valorInicial = this.state?.dominio[0]?.id;
                                  if (formProps.form.getState().values?.arcaboucoTectonico?.idDominio != valorInicial) {
                                    formProps.form.mutators.setValue('arcaboucoTectonico.idDominio', valorInicial);
                                  }
                                }
                                let placeholder =
                                  "Não foram encontrados registros para a localização informada";
                                let campoDesabilitado = false;

                                if (
                                  this.state.dominio.length === 0 ||
                                  this.state.dominio.length === 1 ||
                                  this.props.permitirEdicao === false
                                ) {
                                  campoDesabilitado = true;
                                }
                                if (this.state.dominio.length > 0) {
                                  placeholder = "Selecione..";
                                }
                                return (
                                  <Field
                                    name="arcaboucoTectonico.idDominio"
                                    component={DropdownListField}
                                    label="Domínio"
                                    initialValue={valorInicial}
                                    disabled={campoDesabilitado}
                                    placeholder={placeholder}
                                    elementos={this.state?.dominio}
                                  />
                                );
                              }}
                            </Field>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>

                    <Card className="mt-3">
                      <Card.Body>
                        <Card.Subtitle>
                          Domínios Metalogenéticos
                        </Card.Subtitle>
                        <Row className="mt-3">
                          <Col>
                            <Field
                              component={HiddenField}
                              name="dominioMetalogenetico.id"
                              initialValue={
                                formProps.form.getState().values
                                  ?.dominioMetalogenetico?.id
                              }
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={3}>
                            <Field
                              name="dominioMetalogenetico.idProvinciaMineral"
                              subscription={{ value: true }}
                            >
                              {({ input: { value: idProvinciaMineral } }) => {
                                let valorInicial = null;

                                if (
                                  this.state?.provinciaMineral?.length === 1
                                ) {
                                  valorInicial =
                                    this.state?.provinciaMineral[0]?.id;
                                  if (formProps.form.getState().values?.dominioMetalogenetico?.idProvinciaMineral != valorInicial) {
                                    formProps.form.mutators.setValue('dominioMetalogenetico.idProvinciaMineral', valorInicial);
                                  }
                                }

                                let placeholder =
                                  "Não foram encontrados registros para a localização informada";
                                let campoDesabilitado = false;

                                if (
                                  this.state.provinciaMineral.length === 0 ||
                                  this.state.provinciaMineral.length === 1 ||
                                  this.props.permitirEdicao === false
                                ) {
                                  campoDesabilitado = true;
                                }
                                if (this.state.provinciaMineral.length > 0) {
                                  placeholder = "Selecione..";
                                }

                                return (
                                  <Field
                                    name="dominioMetalogenetico.idProvinciaMineral"
                                    component={DropdownListField}
                                    label="Província mineral"
                                    dica="Informar se o recurso mineral está associado a alguma província mineral,  a partir da biblioteca"
                                    initialValue={valorInicial}
                                    disabled={campoDesabilitado}
                                    placeholder={placeholder}
                                    elementos={this.state?.provinciaMineral}
                                  />
                                );
                              }}
                            </Field>
                          </Col>
                          <Col md={3}>
                            <Field
                              name="dominioMetalogenetico.idDistrito"
                              subscription={{ value: true }}
                            >
                              {({ input: { value: idDistrito } }) => {
                                let valorInicial = null;
                                if (this.state?.distrito?.length === 1) {
                                  valorInicial = this.state?.distrito[0]?.id;
                                  if (formProps.form.getState().values?.dominioMetalogenetico?.idDistrito != valorInicial) {
                                    formProps.form.mutators.setValue('dominioMetalogenetico.idDistrito', valorInicial);
                                  }
                                }

                                let placeholder =
                                  "Não foram encontrados registros para a localização informada";
                                let campoDesabilitado = false;

                                if (
                                  this.state.distrito.length === 0 ||
                                  this.state.distrito.length === 1 ||
                                  this.props.permitirEdicao === false
                                ) {
                                  campoDesabilitado = true;
                                }
                                if (this.state.distrito.length > 0) {
                                  placeholder = "Selecione..";
                                }

                                return (
                                  <Field
                                    name="dominioMetalogenetico.idDistrito"
                                    component={DropdownListField}
                                    label="Distrito"
                                    dica="Informar se o recurso mineral está associado a algum distrito mineral,  a partir da biblioteca"
                                    initialValue={valorInicial}
                                    disabled={campoDesabilitado}
                                    placeholder={placeholder}
                                    elementos={this.state?.distrito}
                                  />
                                );
                              }}
                            </Field>
                          </Col>
                          <Col md={3}>
                            <Field name={"dominioMetalogenetico.idDistrito"} subscription={{ value: true }}>
                              {({ input: { value: idDistrito } }) => {
                                let listaFiltrada = [];

                                if (idDistrito) {
                                  listaFiltrada = this.state.campo.filter(c => c.idDistrito === idDistrito);

                                  let idCampo = formProps.form.getState().values?.dominioMetalogenetico?.idCampo;

                                  if (!listaFiltrada.find((c) => c.id === idCampo))
                                    formProps.form.mutators.setValue('dominioMetalogenetico.idCampo', null);

                                  return <Field
                                    name={'dominioMetalogenetico.idCampo'}
                                    component={DropdownListField}
                                    label="Campo"
                                    dica="Informar se o recurso mineral está associado a algum Campo, a partir da biblioteca"
                                    elementos={listaFiltrada}
                                    initialValue={null}
                                    disabled={!this.props.permitirEdicao}
                                  />
                                }
                                else {
                                  if (formProps.form.getState().values?.dominioMetalogenetico?.idCampo)
                                    formProps.form.mutators.setValue('dominioMetalogenetico.idCampo', null);

                                  return <Field
                                    name='aaaa'
                                    component={DropdownListField}
                                    label="Campo"
                                    dica="Informar se o recurso mineral está associado a algum Campo, a partir da biblioteca"
                                    elementos={[]}
                                    initialValue={null}
                                    disabled={!this.props.permitirEdicao}
                                  />
                                }
                              }}
                            </Field>
                          </Col>
                          <Col md={3}>
                            <Field name={"dominioMetalogenetico.idCampo"} subscription={{ value: true }}>
                              {({ input: { value: idCampo } }) => {
                                let listaFiltrada = [];

                                if (idCampo) {
                                  listaFiltrada = this.state.polo.filter(p => p.idCampo === idCampo);

                                  let idPolo = formProps.form.getState().values?.dominioMetalogenetico?.idPolo;

                                  if (!listaFiltrada.find((p) => p.id === idPolo))
                                    formProps.form.mutators.setValue('dominioMetalogenetico.idPolo', null);

                                  return <Field
                                    name='dominioMetalogenetico.idPolo'
                                    component={DropdownListField}
                                    label="Polo"
                                    dica="Informar se o recurso mineral está associado a algum Polo, a partir da biblioteca"
                                    initialValue={null}
                                    elementos={listaFiltrada}
                                    disabled={!this.props.permitirEdicao}
                                  />
                                }
                                else {
                                  if (formProps.form.getState().values?.dominioMetalogenetico?.idPolo)
                                    formProps.form.mutators.setValue('dominioMetalogenetico.idPolo', null);

                                  return <Field
                                    name='aaaa'
                                    component={DropdownListField}
                                    label="Polo"
                                    dica="Informar se o recurso mineral está associado a algum Polo, a partir da biblioteca"
                                    initialValue={null}
                                    elementos={[]}
                                    disabled={!this.props.permitirEdicao}
                                  />
                                }
                              }}
                            </Field>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>

                    <Card className="mt-3">
                      <Card.Body>
                        <Card.Subtitle>
                          Dados descritivos do corpo mineralizado
                        </Card.Subtitle>
                        <Row className="mt-3">
                          <Col>
                            <Field
                              component={HiddenField}
                              name="metalogenia.dadosDescritivos.id"
                              initialValue={
                                formProps.form.getState().values
                                  ?.metalogenia?.dadosDescritivos?.id
                              }
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <Field
                              disabled={!this.props.permitirEdicao}
                              name="metalogenia.dadosDescritivos.idTexturas"
                              component={DropdownListField}
                              label="Textura"
                              dica="Informar a(s) textura(s) apresentadas pelo corpo mineralizado, a partir da biblioteca"
                              multiple
                              checkboxes={true}
                              elementos={this.state.textura}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <Field
                              disabled={!this.props.permitirEdicao}
                              name="metalogenia.dadosDescritivos.idEstruturasMineralizacao"
                              component={DropdownListField}
                              label="Estrutura de mineralização"
                              dica="Informar a(s) estrutura(s) do corpo mineralizado, a partir da biblioteca"
                              multiple
                              checkboxes={true}
                              elementos={this.state.estruturaMineralizacao}
                            />
                          </Col>
                          <Col md={6}>
                            <Field
                              disabled={!this.props.permitirEdicao}
                              name="metalogenia.dadosDescritivos.idAssociacoesMineralogicas"
                              component={DropdownListField}
                              label="Associação mineralógica"
                              dica={"Informe os minerais associados ao corpo mineralizado, a partir da biblioteca"}
                              multiple
                              type="number"
                              checkboxes={true}
                              validate={(dados) =>
                                this.validarAssociacaoMineralogica(dados)
                              }
                              elementos={this.state.associacaoMineralogica}
                            />
                          </Col>
                        </Row>
                        <Field
                          name={
                            "metalogenia.dadosDescritivos.alteracoesHidrotermais"
                          }
                          subscription={{ value: true }}
                        >
                          {({
                            input: { value: alteracoesHidrotermais = {} },
                          }) => (
                            <Card className="mt-3">
                              <Card.Body>
                                <Row>
                                  <Col md={12}>
                                    <SubForm
                                      keepDirtyOnReinitialize
                                      {...getPropsPermissao(
                                        this.props.permitirEdicao
                                      )}
                                      tituloForm="Alteração hidrotermal"
                                      tituloFormMenor={true}
                                      nome="metalogenia.dadosDescritivos.alteracoesHidrotermais"
                                      validar={(valores) => this.props.modoDefinitivo ?
                                        this.validacoesAlteracaoHidrotermal(
                                          valores,
                                          formProps
                                        ) : undefined
                                      }
                                      onSubmit={(alteracoesForm) =>
                                        this.onSubmitSubForm(
                                          alteracoesForm,
                                          formProps.form
                                        )
                                      }
                                      colunas={
                                        this.colunasAlteracaoHidrotermal
                                      }
                                      campos={
                                        this.state
                                          .datafieldsAlteracaoHidrotermal
                                      }
                                      elementos={alteracoesHidrotermais}
                                      valoresIniciais={{
                                        profundidadeSuperficial: "SIM",
                                        mensagemTotalPorcentagem: 0,
                                      }}
                                      formSubscription={{}}
                                      renderForm={({ formProps }) => {
                                        return (
                                          <>
                                            <Row>
                                              <Col md={12}>
                                                <Card.Subtitle>
                                                  Alteração hidrotermal
                                                </Card.Subtitle>
                                              </Col>
                                            </Row>

                                            <Row className="mb-4">
                                              <Field
                                                component={HiddenField}
                                                name={`mensagemTotalPorcentagem`}
                                              />
                                              <Col md={3}>
                                                <legend className="w-auto small">
                                                  Profundidade
                                                  superficial?
                                                  <Hint textoAjuda="Informar se a mineralização se encontra em superfície (afloramento, cava de garimpo, mina a ceu aberto etc...) ou se está em profundidade, sendo acessível somente por galerias, túneis, testemunhos de sondagem etc..." />
                                                </legend>
                                                <Field
                                                  disabled={
                                                    !this.props
                                                      .permitirEdicao
                                                  }
                                                  component={
                                                    RadioButtonSetField
                                                  }
                                                  name="profundidadeSuperficial"
                                                  elementos={
                                                    this.state
                                                      .profundidadeSuperficial
                                                  }
                                                  required={this.props.modoDefinitivo}
                                                  validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica(
                                                    "Profundidade superficial"
                                                  ))}
                                                />
                                              </Col>
                                              <FormSpy
                                                subscription={{
                                                  values: true,
                                                }}
                                              >
                                                {({ values }) => {
                                                  if (values?.preenchimentoProfundidade === "INTERVALO") {
                                                    formProps.form.mutators.setValue('profundidade', null)
                                                  }
                                                  if (values?.preenchimentoProfundidade === "ABSOLUTO") {
                                                    formProps.form.mutators.setValue('profundidadeInicial', null)
                                                    formProps.form.mutators.setValue('profundidadeFinal', null)
                                                  }
                                                  if (!values?.preenchimentoProfundidade) {
                                                    formProps.form.mutators.setValue('profundidadeInicial', null)
                                                    formProps.form.mutators.setValue('profundidadeFinal', null)
                                                    formProps.form.mutators.setValue('profundidade', null)
                                                  }
                                                  return (
                                                    <>
                                                      {formProps.form.getState()
                                                        .values
                                                        ?.profundidadeSuperficial ===
                                                        "NAO" ? (
                                                        <Col md={3}>
                                                          <Field
                                                            disabled={
                                                              !this.props
                                                                .permitirEdicao
                                                            }
                                                            name="preenchimentoProfundidade"
                                                            component={
                                                              DropdownListField
                                                            }
                                                            label="Preenchimento da profundidade"
                                                            required={this.props.modoDefinitivo}
                                                            validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica(
                                                              "Preenchimento da profundidade"
                                                            ))}
                                                            hint={'Informe a profundidade em que ocorre a alteração hidrotermal; se num intervalo ou num ponto único (absoluto)'}
                                                            dica='Informe a profundidade em que ocorre a alteração hidrotermal; se num intervalo ou num ponto único (absoluto)'
                                                            elementos={
                                                              this.state
                                                                .preenchimentoProfundidade
                                                            }
                                                          />
                                                        </Col>
                                                      ) : (
                                                        <></>
                                                      )}
                                                      {formProps.form.getState()
                                                        .values
                                                        ?.preenchimentoProfundidade ===
                                                        "INTERVALO" &&
                                                        formProps.form.getState()
                                                          .values
                                                          ?.profundidadeSuperficial ===
                                                        "NAO" ? (
                                                        <>
                                                          <Col md={3}>
                                                            <Field
                                                              disabled={
                                                                !this.props
                                                                  .permitirEdicao
                                                              }
                                                              name="profundidadeInicial"
                                                              component={
                                                                NumberInputField
                                                              }
                                                              label="Profundidade Inicial (m)"
                                                              dica="Informe o ponto inicial do intervalo em que foi observada a alteração hidrotermal"
                                                              required={this.props.modoDefinitivo}
                                                              validate={
                                                                validarSe(this.props.modoDefinitivo,
                                                                  comporValidadores(campoObrigatorioComMsgGenerica(
                                                                    "Profundidade Inicial"
                                                                  ), validaProfundidadeInicial))}
                                                              casasDecimais={
                                                                2
                                                              }
                                                              permitirNegativos={
                                                                false
                                                              }
                                                            />
                                                          </Col>
                                                          <Col md={3}>
                                                            <Field
                                                              disabled={
                                                                !this.props
                                                                  .permitirEdicao
                                                              }
                                                              name="profundidadeFinal"
                                                              component={
                                                                NumberInputField
                                                              }
                                                              label="Profundidade Final (m)"
                                                              dica="Informe o ponto final do intervalo em que foi observada a alteração hidrotermal"
                                                              hint='Informe o ponto final do intervalo em que foi observada a alteração hidrotermal'
                                                              required={this.props.modoDefinitivo}
                                                              validate={validarSe(this.props.modoDefinitivo, comporValidadores(campoObrigatorioComMsgGenerica(
                                                                "Profundidade Final"
                                                              ), validaProfundidadeFinal))}
                                                              casasDecimais={
                                                                2
                                                              }
                                                              permitirNegativos={
                                                                false
                                                              }
                                                            />
                                                          </Col>
                                                        </>
                                                      ) : formProps.form.getState()
                                                        .values
                                                        ?.preenchimentoProfundidade ===
                                                        "ABSOLUTO" &&
                                                        formProps.form.getState()
                                                          .values
                                                          ?.profundidadeSuperficial ===
                                                        "NAO" ? (
                                                        <Col md={6}>
                                                          <Field
                                                            disabled={
                                                              !this.props
                                                                .permitirEdicao
                                                            }
                                                            name="profundidade"
                                                            component={
                                                              NumberInputField
                                                            }
                                                            label="Profundidade (m)"
                                                            dica="Informe a profundidade do ponto em que foi observada a alteração hidrotermal"
                                                            hint='Informe a profundidade do ponto em que foi observada a alteração hidrotermal'
                                                            required={this.props.modoDefinitivo}
                                                            validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica(
                                                              "Profundidade"
                                                            ))}
                                                            casasDecimais={2}
                                                            permitirNegativos={
                                                              false
                                                            }
                                                          />
                                                        </Col>
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </>
                                                  )
                                                }}
                                              </FormSpy>
                                            </Row>

                                            <Row className="mb-3">
                                              <Col md={3}>
                                                <Field
                                                  component={CounterField}
                                                  name={`hierarquia`}
                                                  label="Hierarquia"
                                                  dica="Se possível informe a hierarquia da estrutura selecionada"
                                                  disabled={!this.props.permitirEdicao}
                                                  value={formProps.form.getState().values[`hierarquia`]}
                                                  onChange={(value) => {
                                                    formProps.form.mutators.setValue(`hierarquia`, value)
                                                  }}
                                                />
                                              </Col>
                                              <Col md={3}>
                                                <Field
                                                  disabled={
                                                    !this.props
                                                      .permitirEdicao
                                                  }
                                                  name="idGrauAlteracao"
                                                  component={
                                                    DropdownListField
                                                  }
                                                  label="Intensidade da alteração"
                                                  dica="Informar a intensidade da alteração hidrotermal a partir da biblioteca"
                                                  required={this.props.modoDefinitivo}
                                                  validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica(
                                                    "Intensidade da alteração"
                                                  ))}
                                                  elementos={
                                                    this.state.grauAlteracao
                                                  }
                                                />
                                              </Col>
                                              <Col md={6}>
                                                <Field
                                                  disabled={
                                                    !this.props
                                                      .permitirEdicao
                                                  }
                                                  name="idZonaAlteracao"
                                                  component={
                                                    DropdownListField
                                                  }
                                                  label="Zona de alteração"
                                                  dica="Informar se a alteração descrita faz parte de uma zona (alteração zonada) a partil da biblioteca"
                                                  required={this.props.modoDefinitivo}
                                                  validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica(
                                                    "Zona de alteração"
                                                  ))}
                                                  elementos={
                                                    this.state.zonaAlteracao
                                                  }
                                                />
                                              </Col>
                                            </Row>
                                            <Row className="mb-2">
                                              <Col md={6}>
                                                <Field
                                                  name={'idTipoAlteracao'}
                                                  subscription={{ value: true }}>
                                                  {({ input: { value: tipoAlteracao } }) => {

                                                    let listaFiltrada = this.state.tipoAlteracao.filter((t) => t.atual === 'S');

                                                    if (tipoAlteracao && this.state.tipoAlteracao.length && !listaFiltrada.find((t) => t.id === tipoAlteracao)) {
                                                      const item = this.state.tipoAlteracao.find((t) => t.id === tipoAlteracao);

                                                      if (item && item.atual === 'N') {
                                                        listaFiltrada.push(item);
                                                        listaFiltrada = listaFiltrada.sort((a, b) => a.nome.localeCompare(b.nome))
                                                      }
                                                    }

                                                    return <Field
                                                      disabled={!this.props.permitirEdicao}
                                                      name="idTipoAlteracao"
                                                      component={DropdownListField}
                                                      label="Tipo de alteração"
                                                      dica="Informar o(s) tipo(s) de alteração hidrotermal que afetou/afetaram a rocha hospedeira da mineralização (a partir de da biblioteca)"
                                                      elementos={listaFiltrada}
                                                      required={this.props.modoDefinitivo}
                                                      validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica(
                                                        "Tipo de alteração"
                                                      ))}
                                                    />
                                                  }}
                                                </Field>
                                              </Col>
                                              <Col md={6}>
                                                <Field
                                                  disabled={
                                                    !this.props
                                                      .permitirEdicao
                                                  }
                                                  name="idDistribuicaoAlteracao"
                                                  component={
                                                    DropdownListField
                                                  }
                                                  label="Forma de ocorrência"
                                                  dica="Informar de que modo a alteração hidrotermal se distribui no corpo mineralizado e/ou rocha hospedeira (a partir de biblioteca)"
                                                  elementos={
                                                    this.state
                                                      .distribuicaoAlteracao
                                                  }
                                                  required={this.props.modoDefinitivo}
                                                  validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica(
                                                    "Forma de ocorrência"
                                                  ))}
                                                />
                                              </Col>
                                              <Col md={12}>
                                                <Field
                                                  disabled={!this.props.permitirEdicao}
                                                  name="informacoesRelevantes"
                                                  component={TextAreaField}
                                                  label="Informações Relevantes"
                                                  dica="Descreva detalhadamente a alteração hidrotermal observada"
                                                  initialValue=""
                                                  maxLength={255}
                                                />
                                              </Col>
                                            </Row>

                                            <Row>
                                              <Field
                                                name={"minerais"}
                                                subscription={{
                                                  value: true,
                                                }}
                                              >
                                                {({
                                                  input: {
                                                    value:
                                                    minerais = {},
                                                  },
                                                }) => {

                                                  let mineraisOrdenados = minerais;

                                                  if (typeof minerais != "string") {
                                                    if (minerais && minerais.novos != null && minerais.novos != undefined) {
                                                      if (minerais.novos.find(i => i && i.ordemAbundancia != null && i.ordemAbundancia != undefined)) {
                                                        mineraisOrdenados.novos = minerais.novos.filter(a => a != null).sort((a, b) => b.ordemAbundancia - a.ordemAbundancia);
                                                      }
                                                    }

                                                    if (minerais && minerais.existentes != null && minerais.existentes != undefined) {
                                                      if (minerais.existentes.find(i => i && i.ordemAbundancia != null && i.ordemAbundancia != undefined))
                                                        mineraisOrdenados.existentes = minerais.existentes.filter(a => a != null).sort((a, b) => b.ordemAbundancia - a.ordemAbundancia);
                                                    }
                                                  }

                                                  var mineraisFiltrados = this.state.mineral;
                                                  var mineraisCadastrados =[];
                                                  if (mineraisOrdenados){
                                                      mineraisCadastrados = resolverExibidos(mineraisOrdenados);
                                                  }
                                                  for (let i of mineraisCadastrados) {
                                                    mineraisFiltrados = mineraisFiltrados.filter(item => item.id !== i.idTipoMineral);
                                                  }
                                                  return (
                                                    <Col md={12}>
                                                      <SubForm
                                                        keepDirtyOnReinitialize
                                                        {...getPropsPermissao(
                                                          this.props
                                                            .permitirEdicao
                                                        )}
                                                        exibirTabelaSemRegistro={true}
                                                        nome="minerais"
                                                        onSubmit={(
                                                          alteracoesForm
                                                        ) =>
                                                          this.onSubmitSubFormMinerais(
                                                            alteracoesForm,
                                                            formProps.form
                                                          )
                                                        }
                                                        colunas={
                                                          this
                                                            .colunasMinerais
                                                        }
                                                        campos={
                                                          this.state
                                                            .datafieldsMinerais
                                                        }
                                                        elementos={
                                                          mineraisOrdenados
                                                        }
                                                        validar={(
                                                          valores
                                                        ) =>
                                                          this.props.modoDefinitivo ?
                                                            this.validarOrdemAbundanciaMinerais(
                                                              valores,
                                                              formProps.form
                                                            ) : undefined
                                                        }
                                                        valoresIniciais={{
                                                          percentual:
                                                            null,
                                                          ordemAbundancia:
                                                            undefined,
                                                        }}
                                                        formSubscription={{}}
                                                        renderForm={({
                                                          formProps,
                                                        }) => {
                                                          return (
                                                            <>
                                                              <Row className="mb-3">
                                                                <Col
                                                                  md={6}
                                                                >
                                                                  <Field
                                                                    disabled={
                                                                      !this
                                                                        .props
                                                                        .permitirEdicao
                                                                    }
                                                                    name="idTipoMineral"
                                                                    component={
                                                                      DropdownListField
                                                                    }
                                                                    label="Mineral"

                                                                    dica="Informar os minerais hidrotermais a partir da biblioteca"
                                                                    elementos={mineraisFiltrados}
                                                                  />
                                                                </Col>

                                                                <Field name={'percentual'} subscription={{ value: true }}>
                                                                  {({ input: { value: percentual } }) => {

                                                                    return (
                                                                      <>
                                                                        <Col
                                                                          md={3}
                                                                        >
                                                                          <Field
                                                                            disabled={
                                                                              !this
                                                                                .props
                                                                                .permitirEdicao
                                                                            }
                                                                            name="ordemAbundancia"
                                                                            component={
                                                                              NumberInputField
                                                                            }
                                                                            permitirNegativos={
                                                                              false
                                                                            }
                                                                            label="Ordem de abundância"
                                                                            dica="Informar a ordem de abundância dos minerais listados"
                                                                            casasInteiros={4}
                                                                            valorMinimo={1}
                                                                            valorMaximo={999999999}
                                                                          />
                                                                        </Col>
                                                                      </>)
                                                                  }}
                                                                </Field>
                                                                <Field name={'ordemAbundancia'} subscription={{ value: true }}>
                                                                  {({ input: { value: ordemAbundancia } }) => {

                                                                    return (
                                                                      <>
                                                                        <Col
                                                                          md={3}
                                                                        >
                                                                          <FormSpy subscription={{ values: true }}>
                                                                            {({ values }) => {


                                                                              return (
                                                                                <Field
                                                                                  disabled={
                                                                                    !this
                                                                                      .props
                                                                                      .permitirEdicao
                                                                                  }
                                                                                  name="percentual"
                                                                                  component={
                                                                                    NumberInputField
                                                                                  }
                                                                                  permitirNegativos={
                                                                                    false
                                                                                  }
                                                                                  label="Percentual (%)"
                                                                                  dica="Informar os percentuais de todos os minerais listados de acordo com a ordem de abundância"
                                                                                  valorMaximo={100}
                                                                                  casasDecimais={4}
                                                                                  casasInteiros={3}
                                                                                  validate={() => {
                                                                                    let edicao = false;
                                                                                    let percentual = 0;
                                                                                    let minerais = resolverExibidos(mineraisOrdenados);

                                                                                    const mineralForm = formProps.form.getState().values;

                                                                                    minerais.forEach(mineral => {
                                                                                      if (mineralForm && mineral.id === mineralForm.id) {
                                                                                        percentual += mineralForm?.percentual;
                                                                                        edicao = true;
                                                                                      }
                                                                                      else
                                                                                        percentual += mineral?.percentual;
                                                                                    });

                                                                                    if (!edicao && mineralForm && mineralForm.percentual)
                                                                                      percentual += mineralForm?.percentual;

                                                                                    return (percentual > 100)
                                                                                      ? "A soma do percentual dos minerais não pode ser maior que 100%."
                                                                                      : undefined;
                                                                                  }}
                                                                                />
                                                                              )
                                                                            }}
                                                                          </FormSpy>
                                                                        </Col>
                                                                      </>
                                                                    )
                                                                  }}</Field>

                                                              </Row>
                                                            </>
                                                          );
                                                        }}
                                                      />
                                                    </Col>
                                                  )
                                                }}
                                              </Field>
                                            </Row>
                                            <FormSpy
                                              subscription={{
                                                values: true,
                                              }}
                                            >
                                              {({ values }) => {
                                                // if (
                                                //   formProps.form.getState()
                                                //     .values
                                                //     .mensagemTotalPorcentagem
                                                // ) {
                                                return (
                                                  <>
                                                    <legend
                                                      className=" mt-4 w-auto small"
                                                      style={
                                                        formProps.form.getState()
                                                          .values
                                                          .mensagemTotalPorcentagem ===
                                                          100
                                                          ? {
                                                            color:
                                                              "blue",
                                                          }
                                                          : {
                                                            color:
                                                              "red",
                                                          }
                                                      }
                                                    >
                                                      A soma total atual
                                                      dos percentuais dos
                                                      minerais é de{" "}
                                                      {
                                                        formProps.form.getState()
                                                          .values
                                                          .mensagemTotalPorcentagem ?? 0
                                                      }
                                                      %
                                                    </legend>
                                                  </>
                                                );
                                                // } else {
                                                //   return <></>;
                                                // }
                                              }}
                                            </FormSpy>
                                          </>
                                        );
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          )}
                        </Field>
                        <Field
                          name={"metalogenia.dadosDescritivos.intemperismo"}
                          subscription={{ value: true }}
                        >
                          {({ input: { value: intemperismo = {} } }) => (
                            <Card className="mt-3">
                              <Card.Body>
                                <Row>
                                  <Col md={12}>
                                    <SubForm
                                      keepDirtyOnReinitialize
                                      {...getPropsPermissao(
                                        this.props.permitirEdicao
                                      )}
                                      tituloForm="Intemperismo"
                                      tituloFormMenor={true}
                                      nome="metalogenia.dadosDescritivos.intemperismo"
                                      onSubmit={(alteracoesForm) =>
                                        this.onSubmitSubForm(
                                          alteracoesForm,
                                          formProps.form
                                        )
                                      }
                                      colunas={this.colunasIntemperismo}
                                      campos={
                                        this.state.dataFieldsIntemperismo
                                      }
                                      elementos={intemperismo}
                                      formSubscription={{}}
                                      renderForm={({ formProps }) => {
                                        return <>
                                          <Card.Subtitle>Intemperismo</Card.Subtitle>
                                          <Row className="mt-3">
                                            <Col>
                                              <Field
                                                component={HiddenField}
                                                name="id"
                                                initialValue={formProps.form.getState().values?.id}
                                              />
                                            </Col>
                                          </Row>
                                          <Row className="mb-3">
                                            <Col md={4}>
                                              <Field
                                                disabled={!this.props.permitirEdicao}
                                                name="idTipoProcesso"
                                                component={DropdownListField}
                                                label="Tipo de processo"
                                                dica="Informe a classe do intemperismo observado no corpo mineralizado, a partir da biblioteca"
                                                required={this.props.modoDefinitivo}
                                                validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica("Tipo de processo"))}
                                                elementos={this.state.tipoProcesso}
                                              />
                                            </Col>
                                            <Col md={4}>
                                              <Field
                                                disabled={!this.props.permitirEdicao}
                                                name="idTipoMaterialProduto"
                                                component={DropdownListField}
                                                label="Tipo de material ou produto"
                                                dica="Informar o produto gerado no intemperismo do corpo mineralizado"
                                                required={this.props.modoDefinitivo}
                                                validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica("Tipo de material ou produto"))}
                                                elementos={this.state.tipoMaterialProduto}
                                              />
                                            </Col>
                                            <Col md={4}>
                                              <Field
                                                disabled={!this.props.permitirEdicao}
                                                name="profundidade"
                                                component={NumberInputField}
                                                permitirNegativos={false}
                                                label="Profundidade (m)"
                                                dica="Informar a espessura do manto intempérico"
                                                initialValue={0}
                                                casasDecimais={4}
                                                casasInteiros={4}
                                              />
                                            </Col>
                                          </Row>
                                        </>
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          )}
                        </Field>
                        <Field
                          name={
                            "metalogenia.dadosDescritivos.rochasHospedeiras"
                          }
                          subscription={{ value: true }}
                        >
                          {({
                            input: { value: rochasHospedeiras = {} },
                          }) => { let hospedeiraEditada = [];return(
                            <Card className="mt-3">
                              <Card.Body>
                                <Row>
                                  <Col md={12}>
                                    <SubForm
                                      keepDirtyOnReinitialize
                                      {...getPropsPermissao(
                                        this.props.permitirEdicao
                                      )}
                                      id="rochasHospedeiras"
                                      tituloForm="Rocha hospedeira"
                                      tituloFormMenor={true}
                                      podeExcluirEntidade={(entidade) => this.podeExcluirRochaLito(entidade, formProps)}
                                      nome="metalogenia.dadosDescritivos.rochasHospedeiras"
                                      onSubmit={(alteracoesForm) =>
                                        this.onSubmitSubForm(
                                          alteracoesForm,
                                          formProps.form
                                        )
                                      }
                                      colunas={this.colunasRochaHospedeira}
                                      campos={
                                        this.state.datafieldsRochaHospedeira
                                      }
                                      validar={this.props.modoDefinitivo ? this.validarPorcentagemMineraisRochaNaoCadastradaHospedeira.bind(
                                        this
                                      ) : undefined}
                                      elementos={rochasHospedeiras}
                                      valoresIniciais={{
                                        cadastrada: "SIM",
                                      }}
                                      onBtnEditClick={(values)=>{hospedeiraEditada = values}} 
                                      alternarBotoesPai={true}
                                      formSubscription={{}}
                                      renderForm={({ formProps }) => {
                                        return (
                                          <>
                                            <Field
                                              component={HiddenField}
                                              name={`mensagemTotalPorcentagem`}
                                            />
                                            <Field
                                              component={HiddenField}
                                              name="metalogenia.dadosDescritivos.atualizarValidacao"
                                            />
                                            <Card.Subtitle>
                                              Rocha hospedeira
                                            </Card.Subtitle>
                                            <FormSpy
                                              subscription={{
                                                values: true,
                                              }}
                                            >
                                              {({ values }) => {

                                                let exibirAfloramentos = values?.cadastrada === "SIM";

                                               

                                                return <>
                                                  <Row className="mt-3">
                                                    <Col md={12}>
                                                      <Field
                                                        disabled={
                                                          !this.props
                                                            .permitirEdicao
                                                        }
                                                        component={
                                                          RadioButtonSetField
                                                        }
                                                        name="cadastrada"
                                                        label="Rocha hospedeira cadastrada"
                                                        dica="Informar se o nome ou tipo de rocha hospedeira já está cadastrado (nos  módulos aflora ou recmin ou amostra)"
                                                        elementos={
                                                          this.state
                                                            .rochaHospedeiraNaoCadastrada
                                                        }
                                                        horizontal
                                                      />
                                                    </Col>
                                                  </Row>
                                                  {exibirAfloramentos ? (
                                                    <Row className="d-flex justify-content-center align-items-center">
                                                      <Col md={6}>
                                                        <Field
                                                          name="idAfloramento"
                                                          subscription={{
                                                            value: true,
                                                          }}
                                                        >
                                                          {({
                                                            input: {
                                                              value:
                                                              idAfloramento,
                                                            },
                                                          }) => {
                                                            let listaFiltrada = [];
                                                            this.state.dadosAflora?.map((dado) => listaFiltrada.push({ id: dado.id, nome: dado.numeroCampo }));
                                                            return (
                                                              <Field
                                                                disabled={
                                                                  !this
                                                                    .props
                                                                    .permitirEdicao
                                                                }
                                                                name="idAfloramento"
                                                                component={
                                                                  DropdownListField
                                                                }
                                                                label="Afloramento"
                                                                dica="Informe, a partir da biblioteca, o afloramento onde foi cadastrada a rocha hospedeira"
                                                                elementos={
                                                                  listaFiltrada
                                                                }
                                                                required={this.props.modoDefinitivo}
                                                                validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica(
                                                                  "Afloramento"
                                                                ))}
                                                              />
                                                            );
                                                          }}
                                                        </Field>
                                                      </Col>
                                                      <Col md={6}>
                                                        <Field
                                                          name="idRochaAfloramento"
                                                          subscription={{
                                                            value: true,
                                                          }}
                                                        >
                                                          {({
                                                            input: {
                                                              value:
                                                              idRochaAfloramento,
                                                            },
                                                          }) => {
                                                            let rochas = [];
                                                            let rochasOrganizadas =
                                                              [];
                                                            this.state.dadosAflora?.map(
                                                              (dado) => {
                                                                if (
                                                                  dado.id ===
                                                                  formProps.form.getState()
                                                                    .values
                                                                    .idAfloramento
                                                                ) {
                                                                  rochas =
                                                                    dado.rochas;
                                                                }
                                                                if (
                                                                  rochas.length >
                                                                  0
                                                                ) {
                                                                  rochas?.map(
                                                                    (
                                                                      item
                                                                    ) => {
                                                                      rochasOrganizadas.push(
                                                                        {
                                                                          id: item.idRocha,
                                                                          nome: item.descricao,
                                                                        }
                                                                      );
                                                                    }
                                                                  );
                                                                }
                                                              }
                                                            );

                                                            rochasOrganizadas = Array.from(new Set(rochasOrganizadas.map(a => a.id)))
                                                              .map(id => {
                                                                return rochasOrganizadas.find(a => a.id === id)
                                                              });
                                                              
                                                              rochasCadastradasEncaixantes?.map(
                                                                (dado) => {
                                                                  if (dado.cadastrada === "SIM") {
                                                                    rochasOrganizadas.map(
                                                                      (rochas) => {
                                                                        if (rochas.id === dado.idRochaAfloramento) {
                                                                          rochasOrganizadas.splice(rochasOrganizadas.indexOf(rochas), 1);
                                                                        }
                                                                      })
                                                                  }
                                                                })
                                                                let cadastradas = resolverExibidos(rochasHospedeiras) 
                                                                let ids= [];
                                                                cadastradas.map((dado) =>{
                                                                  ids.push(dado.idRochaAfloramento)
                                                                })
                                                                if (formProps.form.getState().values?.tipo === "HOSPEDEIRA"){
                                                                  let edit = hospedeiraEditada?.rowdata?.rowData?.idRochaAfloramento
                                                                  ids = ids.filter((item) => item !==  parseInt(edit));
                                                                }
                                                                rochasOrganizadas = rochasOrganizadas.filter((item) => !ids.includes(item.id));
                                                            return (
                                                              <Field
                                                                disabled={
                                                                  !this
                                                                    .props
                                                                    .permitirEdicao
                                                                }
                                                                name="idRochaAfloramento"
                                                                component={
                                                                  DropdownListField
                                                                }
                                                                label="Rocha"
                                                                dica="Informe, a partir da biblioteca, a rocha (Material Geológico) associada à rocha hospedeira"
                                                                required={this.props.modoDefinitivo}
                                                                elementos={
                                                                  rochasOrganizadas.sort((a, b) => a.nome.localeCompare(b.nome))
                                                                }
                                                                validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica(
                                                                  "Rocha"
                                                                ))}
                                                              />
                                                            );
                                                          }}
                                                        </Field>
                                                      </Col>
                                                    </Row>
                                                  ) : (
                                                    <></>
                                                  )}
                                                  {!exibirAfloramentos ? (
                                                    <>
                                                      <Row>
                                                        <Col md={4}>
                                                          <Field
                                                            disabled={
                                                              !this.props
                                                                .permitirEdicao
                                                            }
                                                            name="rochaNaoCadastrada.idPrefixoRocha"
                                                            component={
                                                              DropdownListField
                                                            }
                                                            label="Prefixo"
                                                            dica="Se for o caso, defina o prefixo de nomenclatura de sua rocha de acordo com o tipo"
                                                            elementos={
                                                              this.state
                                                                .prefixoRocha
                                                            }
                                                            onChange={() =>
                                                              this.preencherNomenclaturaRochaHospedeira(
                                                                formProps.form.getState()
                                                                  .values,
                                                                formProps
                                                              )
                                                            }
                                                          />
                                                        </Col>
                                                        <Col md={4}>
                                                          <Field
                                                            name="rochaNaoCadastrada.idRocha"
                                                            subscription={{
                                                              value: true,
                                                            }}
                                                          >
                                                            {({
                                                              input: {
                                                                value:
                                                                idRocha,
                                                              },
                                                            }) => {
                                                              let listaFiltrada =
                                                                [];
                                                              this.state.rochas?.map(
                                                                (dado) => {
                                                                  dado?.rochaAtual
                                                                    ?.toString()
                                                                    .toLowerCase() ===
                                                                    "s" &&
                                                                    listaFiltrada.push(
                                                                      dado
                                                                    );
                                                                }
                                                              );

                                                              rochasCadastradasEncaixantes?.map(
                                                                (dado) => {
                                                                  if (dado.cadastrada === "NAO") {
                                                                    listaFiltrada.map(
                                                                      (rochas) => {
                                                                        if (rochas.id === dado.rochaNaoCadastrada.idRocha) {
                                                                          listaFiltrada.splice(listaFiltrada.indexOf(rochas), 1);
                                                                        }
                                                                      })
                                                                  }
                                                                })
                                                              return (
                                                                <Field
                                                                  disabled={
                                                                    !this
                                                                      .props
                                                                      .permitirEdicao
                                                                  }
                                                                  name="rochaNaoCadastrada.idRocha"
                                                                  component={
                                                                    DropdownListField
                                                                  }
                                                                  label="Rocha"
                                                                  dica="Informe, a partir da biblioteca, a rocha (Material Geológico) associada à rocha hospedeira"
                                                                  elementos={listaFiltrada}
                                                                  required={this.props.modoDefinitivo}
                                                                  validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica(
                                                                    "Rocha"
                                                                  ))}
                                                                  onChange={() => {
                                                                    this.preencherNomenclaturaRochaHospedeira(
                                                                      formProps.form.getState()
                                                                        .values,
                                                                      formProps
                                                                    );
                                                                  }}
                                                                />
                                                              );
                                                            }}
                                                          </Field>
                                                        </Col>
                                                        <Col md={4}>
                                                          <Field
                                                            disabled={
                                                              !this.props
                                                                .permitirEdicao
                                                            }
                                                            name="rochaNaoCadastrada.idComplementoRocha"
                                                            component={
                                                              DropdownListField
                                                            }
                                                            label="Complemento"
                                                            dica="Se for o caso, defina o complemento de nomenclatura de sua rocha"
                                                            elementos={
                                                              this.state
                                                                .complementoRocha
                                                            }
                                                            onChange={() =>
                                                              this.preencherNomenclaturaRochaHospedeira(
                                                                formProps.form.getState()
                                                                  .values,
                                                                formProps
                                                              )
                                                            }
                                                          />
                                                        </Col>
                                                      </Row>
                                                      <br></br>
                                                      <Field
                                                        name={
                                                          "rochaNaoCadastrada.mineraisRocha"
                                                        }
                                                        subscription={{
                                                          value: true,
                                                        }}
                                                      >
                                                        {({
                                                          input: {
                                                            value:
                                                            mineraisRocha = {}
                                                          },
                                                        }) =>{
                                                          var mineraisFiltrados = this.state.mineral;
                                                          var mineraisCadastrados =[];
                                                          if (formProps.form.getState().values?.rochaNaoCadastrada?.mineraisRocha){
                                                              mineraisCadastrados = resolverExibidos(formProps.form.getState().values?.rochaNaoCadastrada?.mineraisRocha);
                                                          }
                                                          for (let i of mineraisCadastrados) {
                                                            mineraisFiltrados = mineraisFiltrados.filter(item => item.id !== i.idMineral);
                                                          }
                                                          return(
                                                          <Row>
                                                            <Col
                                                              md={12}
                                                            >
                                                              <SubForm
                                                                keepDirtyOnReinitialize
                                                                {...getPropsPermissao(
                                                                  this.props
                                                                    .permitirEdicao
                                                                )}
                                                                exibirTabelaSemRegistro={true}
                                                                nome="rochaNaoCadastrada.mineraisRocha"
                                                                onSubmit={(
                                                                  alteracoesForm
                                                                ) => {
                                                                  this.onSubmitSubFormRocha(
                                                                    alteracoesForm,
                                                                    formProps.form,
                                                                    "rochaNaoCadastrada.mineraisRocha",
                                                                    formProps.form.getState()
                                                                      .values
                                                                  );
                                                                  this.preencherNomenclaturaRochaHospedeira(
                                                                    formProps.form.getState()
                                                                      .values,
                                                                    formProps
                                                                  );
                                                                }}
                                                                onClose={() =>
                                                                  this.preencherNomenclaturaRochaHospedeira(
                                                                    formProps.form.getState()
                                                                      .values,
                                                                    formProps
                                                                  )
                                                                }
                                                                colunas={
                                                                  this
                                                                    .colunasRochaNaoCadastrada
                                                                }
                                                                campos={
                                                                  this.state
                                                                    .datafieldsRochaNaoCadastrada
                                                                }
                                                                elementos={
                                                                  mineraisRocha
                                                                }

                                                                validar={(valores) => { }}
                                                                formSubscription={{}}
                                                                renderForm={({
                                                                  formProps,
                                                                }) => {
                                                                  return (
                                                                    <>
                                                                      <Row className="mb-4">
                                                                        <Col
                                                                          md={
                                                                            6
                                                                          }
                                                                        >
                                                                          <Field
                                                                            disabled={
                                                                              !this
                                                                                .props
                                                                                .permitirEdicao
                                                                            }
                                                                            name="compoeNomeRocha"
                                                                            component={
                                                                              RadioButtonWithFieldSetField
                                                                            }
                                                                            label="Compõe nome de rochas"
                                                                            dica="Compõe nome de rochas"
                                                                            elementos={
                                                                              this
                                                                                .state
                                                                                .compoeNomeRochas
                                                                            }
                                                                            initialValue={
                                                                              this
                                                                                .state
                                                                                .compoeNomeRochas[0]
                                                                                .id
                                                                            }
                                                                            horizontal
                                                                            validate={campoObrigatorioComMsgGenerica(
                                                                              "Modo de cadastro"
                                                                            )}
                                                                          />
                                                                        </Col>
                                                                      </Row>
                                                                      <Row>
                                                                        <Col
                                                                          md={
                                                                            6
                                                                          }
                                                                        >
                                                                          <Field
                                                                            disabled={
                                                                              !this
                                                                                .props
                                                                                .permitirEdicao
                                                                            }
                                                                            name="idMineral"
                                                                            component={
                                                                              DropdownListField
                                                                            }
                                                                            label="Mineral"
                                                                            dica="Informe o(s) mineral (ais) macroscópico(s) identificado(s) na rocha"
                                                                            required={this.props.modoDefinitivo}
                                                                            elementos={mineraisFiltrados}
                                                                            validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica(
                                                                              "Mineral"
                                                                            ))}
                                                                          />
                                                                        </Col>
                                                                        <Col
                                                                          md={
                                                                            6
                                                                          }
                                                                        >
                                                                          <Field
                                                                            disabled={
                                                                              !this
                                                                                .props
                                                                                .permitirEdicao
                                                                            }
                                                                            name="proporcao"
                                                                            component={
                                                                              NumberInputField
                                                                            }
                                                                            permitirNegativos={
                                                                              false
                                                                            }
                                                                            valorMaximo={100}
                                                                            validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica(
                                                                              "Proporção"
                                                                            ))}
                                                                            required={this.props.modoDefinitivo}
                                                                            label="Proporção (%)"
                                                                            dica="Proporção (%)"
                                                                            placeholder="0%"
                                                                            casasDecimais={
                                                                              2
                                                                            }
                                                                          />
                                                                        </Col>
                                                                      </Row>
                                                                    </>
                                                                  );
                                                                }}
                                                              />
                                                            </Col>
                                                          </Row>
                                                        )}}
                                                      </Field>
                                                      <Row className="mt-4"></Row>
                                                      <Row className="mt-4">
                                                        <Col md={12}>
                                                          <Field
                                                            name="nomeRochaNaoCadastrada"
                                                            component={
                                                              TextInputField
                                                            }
                                                            disabled
                                                            placeholder="Preenchimento automático com base em valores"
                                                          />
                                                        </Col>
                                                      </Row>
                                                      <FormSpy
                                                        subscription={{
                                                          values: true,
                                                        }}
                                                      >
                                                        {({ values }) => {
                                                          if (
                                                            formProps.form.getState()
                                                              .values
                                                              .mensagemTotalPorcentagem
                                                          ) {
                                                            return (
                                                              <>
                                                                <legend
                                                                  className=" mt-4 w-auto small"
                                                                  style={
                                                                    formProps.form.getState()
                                                                      .values
                                                                      .mensagemTotalPorcentagem ===
                                                                      100
                                                                      ? {
                                                                        color:
                                                                          "blue",
                                                                      }
                                                                      : {
                                                                        color:
                                                                          "red",
                                                                      }
                                                                  }
                                                                >
                                                                  A soma
                                                                  total
                                                                  atual dos
                                                                  percentuais
                                                                  dos
                                                                  minerais é
                                                                  de{" "}
                                                                  {
                                                                    formProps.form.getState()
                                                                      .values
                                                                      .mensagemTotalPorcentagem
                                                                  }
                                                                  %
                                                                </legend>
                                                              </>
                                                            );
                                                          } else {
                                                            return <></>;
                                                          }
                                                        }}
                                                      </FormSpy>
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </>
                                              }}
                                            </FormSpy>
                                          </>
                                        );
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          )}}
                        </Field>
                        <Field name={"metalogenia.dadosDescritivos"} subscription={{ value: true }}>
                          {({ input: { value: dadosDescritivos = {} }, }) => {

                            let rochasLito = [];
                            let totalRochas = [];
                            let rochasHospedeirasRaw = [];
                            let rochasEncaixantesRaw = [];

                            if (dadosDescritivos.rochasEncaixantes) {
                              let resultadoRochasEncaixantes = resolverExibidos(dadosDescritivos.rochasEncaixantes);
                              if (resultadoRochasEncaixantes && resultadoRochasEncaixantes.length)
                                rochasEncaixantesRaw = resultadoRochasEncaixantes;
                            }

                            if (dadosDescritivos.rochasHospedeiras) {
                              let resultadoRochasHospedeiras = resolverExibidos(dadosDescritivos.rochasHospedeiras);
                              if (resultadoRochasHospedeiras && resultadoRochasHospedeiras.length)
                                rochasHospedeirasRaw = resultadoRochasHospedeiras;
                            }

                            rochasEncaixantesRaw.forEach((rocha) => rocha.tipo = 'ENCAIXANTE');
                            rochasHospedeirasRaw.forEach((rocha) => rocha.tipo = 'HOSPEDEIRA')

                            totalRochas = [...rochasHospedeirasRaw, ...rochasEncaixantesRaw];

                            totalRochas.forEach((rocha) => {
                              if (rocha.cadastrada === 'NAO') {
                                rochasLito.push({
                                  id: `${rocha.nomeRochaNaoCadastrada} ${rocha.tipo}`,
                                  nome: rocha.nomeRochaNaoCadastrada,
                                  idRochaNaoCadastrada: rocha.id,
                                  cadastrada: 'NAO'
                                });
                              }
                              else {
                                if (!rochasLito.find((r) => r.idRochaAfloramento === rocha.idRochaAfloramento && r.idAfloramento === rocha.idAfloramento)) {
                                  const afloramento = this.state.dadosAflora.find((af) => af.id === rocha.idAfloramento);
                                  if (afloramento) {
                                    const rochaAfloramento = afloramento.rochas.find((ar) => ar.idRocha === rocha.idRochaAfloramento);
                                    if (rochaAfloramento) {
                                      rochasLito.push({
                                        idRochaAfloramento: rocha.idRochaAfloramento,
                                        idAfloramento: rocha.idAfloramento,
                                        nome: rochaAfloramento.descricao,
                                        id: rochaAfloramento.descricao,
                                        cadastrada: 'SIM'
                                      })
                                    }
                                  }
                                }
                              }
                            });

                            rochasLito = rochasLito.sort((a, b) => a.nome.localeCompare(b.nome));

                            const atualValorLito = formProps?.form?.getState()?.values?.metalogenia?.dadosInterpretativos?.controleMineralizacao?.controleLitologico;

                            if (atualValorLito) {
                              const atualItem = this.state.rochasLito.find((i) => i.id === atualValorLito);
                              if (atualItem && atualItem.cadastrada === 'NAO') {
                                try {
                                  const novoItem = rochasLito.find((a) => a.idRochaNaoCadastrada === atualItem.idRochaNaoCadastrada)
                                  formProps.form.mutators.setValue('metalogenia.dadosInterpretativos.controleMineralizacao.controleLitologico', novoItem.id);
                                } catch (err) { }
                              }
                            }

                            if (!isEqual(this.state.rochasLito, rochasLito))
                              this.setState({ rochasLito: rochasLito });

                            return <></>
                          }}
                        </Field>
                        <Field name={"metalogenia.dadosDescritivos.rochasEncaixantes"} subscription={{ value: true }}>
                          {({ input: { value: rochasEncaixantes = {} }, }) => {
                            let encaixanteEditada = [];
                            return (
                              <Card className="mt-3">
                                <Card.Body>
                                  <Row>
                                    <Col md={12}>
                                      <SubForm
                                        keepDirtyOnReinitialize
                                        {...getPropsPermissao(
                                          this.props.permitirEdicao
                                        )}
                                        tituloForm="Rocha encaixante"
                                        tituloFormMenor={true}
                                        podeExcluirEntidade={(entidade) => this.podeExcluirRochaLito(entidade, formProps)}
                                        nome="metalogenia.dadosDescritivos.rochasEncaixantes"
                                        onSubmit={(alteracoesForm) =>
                                          this.onSubmitSubForm(
                                            alteracoesForm,
                                            formProps.form
                                          )
                                        }
                                        colunas={this.colunasRochaEncaixante}
                                        campos={
                                          this.state.datafieldsRochaEncaixante
                                        }
                                        validar={this.props.modoDefinitivo ? this.validarPorcentagemMineraisRochaNaoCadastradaEncaixante.bind(
                                          this
                                        ) : undefined}
                                        elementos={rochasEncaixantes}
                                        valoresIniciais={{
                                          cadastrada: "SIM",
                                        }}
                                        onBtnEditClick={(values) => encaixanteEditada = values}
                                        formSubscription={{}}
                                        renderForm={({ formProps }) => {
                                          return (
                                            <>
                                              <Field
                                                disabled={
                                                  !this.props.permitirEdicao
                                                }
                                                component={HiddenField}
                                                name={`mensagemTotalPorcentagem`}
                                              />
                                              <Card.Subtitle>
                                                Rocha encaixante
                                              </Card.Subtitle>
                                              <FormSpy
                                                subscription={{
                                                  values: true,
                                                }}
                                              >
                                                {({ values }) => {
                                                  let exibirAfloramentos = values?.cadastrada === 'SIM';

                                                  let listaFiltrada = [];
                                                  this.state.dadosAflora?.map((dado) => listaFiltrada.push({ id: dado.id, nome: dado.numeroCampo }));

                                                  return <>
                                                    <Row className="mt-3">
                                                      <Col md={12}>
                                                        <Field
                                                          disabled={
                                                            !this.props
                                                              .permitirEdicao
                                                          }
                                                          component={
                                                            RadioButtonSetField
                                                          }
                                                          name="cadastrada"
                                                          label="Rocha encaixante cadastrada"
                                                          dica="Informar se o nome ou tipo de rocha encaixante já está cadastrado (nos módulos aflora ou amostra ou recmin)"
                                                          elementos={
                                                            this.state
                                                              .rochaEncaixanteNaoCadastrada
                                                          }
                                                          horizontal
                                                        />
                                                      </Col>
                                                    </Row>
                                                    {exibirAfloramentos ? (
                                                      <Row className="d-flex justify-content-center align-items-center">
                                                        <Col md={6}>
                                                          <Field
                                                            name="idAfloramento"
                                                            subscription={{
                                                              value: true,
                                                            }}
                                                          >
                                                            {({
                                                              input: {
                                                                value:
                                                                idAfloramento,
                                                              },
                                                            }) => {
                                                              return (
                                                                <Field
                                                                  disabled={
                                                                    !this
                                                                      .props
                                                                      .permitirEdicao
                                                                  }
                                                                  name="idAfloramento"
                                                                  component={
                                                                    DropdownListField
                                                                  }
                                                                  label="Afloramento"
                                                                  dica="Informe, a partir da biblioteca, o afloramento onde foi cadastrada a rocha encaixante"
                                                                  elementos={
                                                                    listaFiltrada
                                                                  }
                                                                />
                                                              );
                                                            }}
                                                          </Field>
                                                        </Col>
                                                        <Col md={6}>
                                                          <Field
                                                            name="idRochaAfloramento"
                                                            subscription={{
                                                              value: true,
                                                            }}
                                                          >
                                                            {({
                                                              input: {
                                                                value:
                                                                idRochaAfloramento,
                                                              },
                                                            }) => {
                                                              let rochas = [];
                                                              let rochasOrganizadas =
                                                                [];
                                                              this.state.dadosAflora?.map(
                                                                (dado) => {
                                                                  if (
                                                                    dado.id ===
                                                                    formProps.form.getState()
                                                                      .values
                                                                      .idAfloramento
                                                                  ) {
                                                                    rochas =
                                                                      dado.rochas;
                                                                  }
                                                                  if (
                                                                    rochas.length >
                                                                    0
                                                                  ) {
                                                                    rochas?.map(
                                                                      (
                                                                        item
                                                                      ) => {
                                                                        rochasOrganizadas.push(
                                                                          {
                                                                            id: item.idRocha,
                                                                            nome: item.descricao,
                                                                          }
                                                                        );
                                                                      }
                                                                    );
                                                                  }
                                                                }
                                                              );

                                                              rochasOrganizadas = Array.from(new Set(rochasOrganizadas.map(a => a.id)))
                                                                .map(id => {
                                                                  return rochasOrganizadas.find(a => a.id === id)
                                                                });
                                                              
                                                              rochasCadastradasHospedeiras?.map(
                                                                (dado) => {
                                                                  if (dado.cadastrada === "SIM") {
                                                                    rochasOrganizadas.map(
                                                                      (rochas) => {
                                                                        if (rochas.id === dado.idRochaAfloramento) {
                                                                          rochasOrganizadas.splice(rochasOrganizadas.indexOf(rochas), 1);
                                                                        }
                                                                      })
                                                                  }
                                                                })
                                                                let cadastradas = resolverExibidos(rochasEncaixantes) 
                                                                let ids= [];
                                                                cadastradas.map((dado) =>{
                                                                  ids.push(dado.idRochaAfloramento)
                                                                })
                                                                if (formProps.form.getState().values?.tipo === "ENCAIXANTE"){
                                                                  let edit = encaixanteEditada?.rowdata?.rowData?.idRochaAfloramento
                                                                  ids = ids.filter((item) => item !==  parseInt(edit));
                                                                }
                                                                rochasOrganizadas = rochasOrganizadas.filter((item) => !ids.includes(item.id));
                                                              return (
                                                                <Field
                                                                  disabled={
                                                                    !this
                                                                      .props
                                                                      .permitirEdicao
                                                                  }
                                                                  name="idRochaAfloramento"
                                                                  component={
                                                                    DropdownListField
                                                                  }
                                                                  label="Rocha"
                                                                  dica="Informe, a partir da biblioteca, a rocha (Material Geológico) associada à rocha encaixante"
                                                                  required={this.props.modoDefinitivo}
                                                                  elementos={
                                                                    rochasOrganizadas.sort((a, b) => a.nome.localeCompare(b.nome))
                                                                  }
                                                                  validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica(
                                                                    "Rocha"
                                                                  ))}
                                                                />
                                                              );
                                                            }}
                                                          </Field>
                                                        </Col>
                                                      </Row>
                                                    ) : (
                                                      <></>
                                                    )}
                                                    {!exibirAfloramentos ? (
                                                      <>
                                                        <Row>
                                                          <Col md={4}>
                                                            <Field
                                                              disabled={
                                                                !this.props
                                                                  .permitirEdicao
                                                              }
                                                              name="rochaNaoCadastrada.idPrefixoRocha"
                                                              component={
                                                                DropdownListField
                                                              }
                                                              label="Prefixo"
                                                              dica="Se for o caso, defina o prefixo de nomenclatura de sua rocha de acordo com o tipo"
                                                              elementos={
                                                                this.state
                                                                  .prefixoRocha
                                                              }
                                                              onChange={() =>
                                                                this.preencherNomenclaturaRochaEncaixante(
                                                                  formProps.form.getState()
                                                                    .values,
                                                                  formProps
                                                                )
                                                              }
                                                            />
                                                          </Col>
                                                          <Col md={4}>
                                                            <Field
                                                              name="rochaNaoCadastrada.idRocha"
                                                              subscription={{
                                                                value: true,
                                                              }}
                                                            >
                                                              {({
                                                                input: {
                                                                  value:
                                                                  idRocha,
                                                                },
                                                              }) => {
                                                                let listaFiltrada =
                                                                  [];
                                                                this.state.rochas?.map(
                                                                  (dado) => {
                                                                    dado?.rochaAtual
                                                                      ?.toString()
                                                                      .toLowerCase() ===
                                                                      "s" &&
                                                                      listaFiltrada.push(
                                                                        dado
                                                                      );
                                                                  }
                                                                );
                                                                rochasCadastradasHospedeiras?.map(
                                                                  (dado) => {
                                                                    if (dado.cadastrada === "NAO"){
                                                                      listaFiltrada.map(
                                                                        (rochas) => {
                                                                              if(rochas.id === dado.rochaNaoCadastrada.idRocha){
                                                                                listaFiltrada.splice( listaFiltrada.indexOf(rochas), 1);
                                                                              }
                                                                        })
                                                                    }
                                                                  })
                                                                return (
                                                                  <Field
                                                                    disabled={
                                                                      !this
                                                                        .props
                                                                        .permitirEdicao
                                                                    }
                                                                    name="rochaNaoCadastrada.idRocha"
                                                                    component={
                                                                      DropdownListField
                                                                    }
                                                                    label="Rocha"
                                                                    dica="Rocha"
                                                                    elementos={
                                                                      listaFiltrada.sort((a, b) => a.nome.localeCompare(b.nome))
                                                                    }
                                                                    required={this.props.modoDefinitivo}
                                                                    validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica(
                                                                      "Rocha"
                                                                    ))}
                                                                    onChange={() => {
                                                                      this.preencherNomenclaturaRochaEncaixante(
                                                                        formProps.form.getState()
                                                                          .values,
                                                                        formProps
                                                                      );
                                                                    }}
                                                                  />
                                                                );
                                                              }}
                                                            </Field>
                                                          </Col>
                                                          <Col md={4}>
                                                            <Field
                                                              disabled={
                                                                !this.props
                                                                  .permitirEdicao
                                                              }
                                                              name="rochaNaoCadastrada.idComplementoRocha"
                                                              component={
                                                                DropdownListField
                                                              }
                                                              label="Complemento"
                                                              dica="Se for o caso, defina o complemento de nomenclatura de sua rocha"
                                                              elementos={
                                                                this.state
                                                                  .complementoRocha
                                                              }
                                                              onChange={() =>
                                                                this.preencherNomenclaturaRochaEncaixante(
                                                                  formProps.form.getState()
                                                                    .values,
                                                                  formProps
                                                                )
                                                              }
                                                            />
                                                          </Col>
                                                        </Row>
                                                        <br></br>
                                                        <Field
                                                          name={
                                                            "rochaNaoCadastrada.mineraisRocha"
                                                          }
                                                          subscription={{
                                                            value: true,
                                                          }}
                                                        >
                                                          {({
                                                            input: {
                                                              value:
                                                              mineraisRocha = {},
                                                            },
                                                          }) => {
                                                            var mineraisFiltrados = this.state.mineral;
                                                            var mineraisCadastrados =[];
                                                            if (formProps.form.getState().values?.rochaNaoCadastrada?.mineraisRocha){
                                                                mineraisCadastrados = resolverExibidos(formProps.form.getState().values?.rochaNaoCadastrada?.mineraisRocha);
                                                            }
                                                            for (let i of mineraisCadastrados) {
                                                              mineraisFiltrados = mineraisFiltrados.filter(item => item.id !== i.idMineral);
                                                            }
                                                            return(
                                                            <Row>
                                                              <Col
                                                                md={12}
                                                              >
                                                                <SubForm
                                                                  keepDirtyOnReinitialize
                                                                  {...getPropsPermissao(
                                                                    this.props
                                                                      .permitirEdicao
                                                                  )}
                                                                  nome="rochaNaoCadastrada.mineraisRocha"
                                                                  exibirTabelaSemRegistro={true}
                                                                  onSubmit={(
                                                                    alteracoesForm
                                                                  ) => {
                                                                    this.onSubmitSubFormRocha(
                                                                      alteracoesForm,
                                                                      formProps.form,
                                                                      "mineraisRocha",
                                                                      formProps.form.getState()
                                                                        .values
                                                                    );
                                                                    return this.preencherNomenclaturaRochaEncaixante(
                                                                      formProps.form.getState()
                                                                        .values,
                                                                      formProps
                                                                    );
                                                                  }}
                                                                  onClose={this.preencherNomenclaturaRochaEncaixante(
                                                                    formProps.form.getState()
                                                                      .values,
                                                                    formProps
                                                                  )}
                                                                  colunas={
                                                                    this
                                                                      .colunasRochaNaoCadastrada
                                                                  }
                                                                  campos={
                                                                    this.state
                                                                      .datafieldsRochaNaoCadastrada
                                                                  }
                                                                  elementos={
                                                                    mineraisRocha
                                                                  }
                                                                  formSubscription={{}}
                                                                  renderForm={({
                                                                    formProps,
                                                                  }) => {
                                                                    return (
                                                                      <>
                                                                        <Row className="mb-4">
                                                                          <Col
                                                                            md={
                                                                              6
                                                                            }
                                                                          >
                                                                            <Field
                                                                              disabled={
                                                                                !this
                                                                                  .props
                                                                                  .permitirEdicao
                                                                              }
                                                                              name="compoeNomeRocha"
                                                                              component={
                                                                                RadioButtonWithFieldSetField
                                                                              }
                                                                              label="Compõe nome de rochas"
                                                                              dica="Compõe nome de rochas"
                                                                              elementos={
                                                                                this
                                                                                  .state
                                                                                  .compoeNomeRochas
                                                                              }
                                                                              initialValue={
                                                                                this
                                                                                  .state
                                                                                  .compoeNomeRochas[0]
                                                                                  .id
                                                                              }
                                                                              horizontal
                                                                              validate={campoObrigatorioComMsgGenerica(
                                                                                "Modo de cadastro"
                                                                              )}
                                                                            />
                                                                          </Col>
                                                                        </Row>
                                                                        <Row>
                                                                          <Col
                                                                            md={
                                                                              6
                                                                            }
                                                                          >
                                                                            <Field
                                                                              disabled={
                                                                                !this
                                                                                  .props
                                                                                  .permitirEdicao
                                                                              }
                                                                              name="idMineral"
                                                                              component={
                                                                                DropdownListField
                                                                              }
                                                                              label="Mineral"
                                                                              dica="Informe o(s) mineral (ais) macroscópico(s) identificado(s) na rocha"
                                                                              required={this.props.modoDefinitivo}
                                                                              elementos={mineraisFiltrados}
                                                                              validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica(
                                                                                "Mineral"
                                                                              ))}
                                                                            />
                                                                          </Col>
                                                                          <Col
                                                                            md={
                                                                              6
                                                                            }
                                                                          >
                                                                            <Field
                                                                              disabled={
                                                                                !this
                                                                                  .props
                                                                                  .permitirEdicao
                                                                              }
                                                                              name="proporcao"
                                                                              component={
                                                                                NumberInputField
                                                                              }
                                                                              permitirNegativos={
                                                                                false
                                                                              }
                                                                              valorMaximo={100}
                                                                              validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica(
                                                                                "Proporção"
                                                                              ))}
                                                                              required={this.props.modoDefinitivo}
                                                                              label="Proporção (%)"
                                                                              dica="Proporção (%)"
                                                                              placeholder="0%"

                                                                              casasDecimais={
                                                                                2
                                                                              }
                                                                            />
                                                                          </Col>
                                                                        </Row>
                                                                      </>
                                                                    );
                                                                  }}
                                                                />
                                                              </Col>
                                                            </Row>
                                                          )}}
                                                        </Field>
                                                        <Row className="mt-4">
                                                          <Col md={12}>
                                                            <Field
                                                              name="nomeRochaNaoCadastrada"
                                                              component={
                                                                TextInputField
                                                              }
                                                              disabled
                                                            />
                                                          </Col>
                                                        </Row>
                                                        <FormSpy
                                                          subscription={{
                                                            values: true,
                                                          }}
                                                        >
                                                          {({ values }) => {
                                                            if (
                                                              formProps.form.getState()
                                                                .values
                                                                .mensagemTotalPorcentagem
                                                            ) {
                                                              return (
                                                                <>
                                                                  <legend
                                                                    className=" mt-4 w-auto small"
                                                                    style={
                                                                      formProps.form.getState()
                                                                        .values
                                                                        .mensagemTotalPorcentagem ===
                                                                        100
                                                                        ? {
                                                                          color:
                                                                            "blue",
                                                                        }
                                                                        : {
                                                                          color:
                                                                            "red",
                                                                        }
                                                                    }
                                                                  >
                                                                    A soma
                                                                    total
                                                                    atual dos
                                                                    percentuais
                                                                    dos
                                                                    minerais é
                                                                    de{" "}
                                                                    {
                                                                      formProps.form.getState()
                                                                        .values
                                                                        .mensagemTotalPorcentagem
                                                                    }
                                                                    %
                                                                  </legend>
                                                                </>
                                                              );
                                                            } else {
                                                              return <></>;
                                                            }
                                                          }}
                                                        </FormSpy>
                                                      </>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </>
                                                }}
                                              </FormSpy>
                                            </>
                                          );
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                </Card.Body>
                              </Card>
                            )
                          }}
                        </Field>
                      </Card.Body>
                    </Card>

                    <Card className="mt-3">
                      <Card.Body>
                        <Card.Subtitle>
                          Dados interpretativos do corpo mineralizado
                        </Card.Subtitle>
                        <Field
                          component={HiddenField}
                          name="metalogenia.dadosInterpretativos.id"
                          initialValue={
                            formProps.form.getState().values
                              ?.metalogenia?.dadosInterpretativos?.id
                          }
                        />
                        <Row className="mt-3">
                          <Col md={12}>
                            <FormSpy subscription={{ values: true }}>
                              {({ values }) => {
                                let possuiForma = values?.metalogenia?.dadosInterpretativos?.idFormasHospedeiras;

                                if (possuiForma !== null) {
                                  if (!possuiForma || possuiForma.length < 1) {
                                    formProps.form.mutators.setValue('metalogenia.dadosInterpretativos.idFormasHospedeiras', null);
                                    try {
                                      setTimeout(() => 'Selecione..', 500);
                                    } catch (err) {
                                      console.log('Erro ao limpar combo', err);
                                    }
                                  }
                                }
                                return (
                                  <Field
                                    disabled={!this.props.permitirEdicao}
                                    name="metalogenia.dadosInterpretativos.idFormasHospedeiras"
                                    component={DropdownListField}
                                    label="Forma (Relação hospedeira)"
                                    dica="Informar a forma do corpo mineralizado em relação à rocha hospedeira, a partir da biblioteca"
                                    multiple
                                    type="number"
                                    checkboxes={true}
                                    elementos={this.state.formaRelacaoHospedeira}
                                  />)
                              }}
                            </FormSpy>
                          </Col>
                        </Row>

                        <Card className="mt-3">
                          <Card.Body>
                            <Field
                              name={
                                "metalogenia.dadosInterpretativos.controleMineralizacao.controlesEstruturais"
                              }
                              subscription={{ value: true }}
                            >
                              {({
                                input: { value: estrutural = {} },
                              }) => {

                                return (
                                  <>
                                    <Row>
                                      <Col md={12}>
                                        <Card.Subtitle>
                                          Controle de mineralização
                                          {/* <span style={{ color: "red" }}>{geraNomeMineralizacao(values?.metalogenia?.dadosInterpretativos?.idFormasHospedeiras, this.props.modoDefinitivo)}</span> */}
                                        </Card.Subtitle>
                                      </Col>
                                    </Row>
                                    <Card className="mt-3">
                                      <Card.Body>
                                        <Field
                                          name={
                                            "metalogenia.dadosInterpretativos.controleMineralizacao.controlesEstruturais"
                                          }
                                          subscription={{ value: true }}
                                        >
                                          {({
                                            input: { value: estrutural = {} },
                                          }) => (
                                            <Row>
                                              <Col md={12}>
                                                <SubForm
                                                  keepDirtyOnReinitialize
                                                  {...getPropsPermissao(
                                                    this.props.permitirEdicao
                                                  )}
                                                  tituloForm="Estrutural"
                                                  tituloFormMenor={true}
                                                  nome="metalogenia.dadosInterpretativos.controleMineralizacao.controlesEstruturais"
                                                  onSubmit={(
                                                    alteracoesForm
                                                  ) =>
                                                    this.onSubmitSubForm(
                                                      alteracoesForm,
                                                      formProps.form
                                                    )
                                                  }
                                                  colunas={
                                                    this.colunasEstrutural
                                                  }
                                                  campos={
                                                    this.state
                                                      .datafieldsEstrutural
                                                  }
                                                  elementos={estrutural}
                                                  formSubscription={{}}
                                                  renderForm={({
                                                    formProps,
                                                  }) => {
                                                    return (
                                                      <>
                                                        <Card.Subtitle>
                                                          Estrutural
                                                        </Card.Subtitle>
                                                        <Row className="mt-3">
                                                          <Col md={4}>
                                                            <Field
                                                              name="idTipoControleEstrutural"
                                                              subscription={{
                                                                value: true,
                                                              }}
                                                            >
                                                              {({
                                                                input: {
                                                                  value:
                                                                  idTipoControleEstrutural,
                                                                },
                                                              }) => {
                                                                let listaFiltrada =
                                                                  [];
                                                                this.state?.tipoControleEstrutural?.map(
                                                                  (item) => {
                                                                    if (
                                                                      item.inControleEstrutural
                                                                        ?.toString()
                                                                        .toLowerCase() ===
                                                                      "s"
                                                                    ) {
                                                                      listaFiltrada.push(
                                                                        item
                                                                      );
                                                                    }
                                                                  }
                                                                );
                                                                return (
                                                                  <Field
                                                                    disabled={
                                                                      !this
                                                                        .props
                                                                        .permitirEdicao
                                                                    }
                                                                    name="idTipoControleEstrutural"
                                                                    component={
                                                                      DropdownListField
                                                                    }
                                                                    label="Tipo de controle estrutural"
                                                                    dica="Informar (caso haja) o tipo de controle estrutural da mineralização, a partir da biblioteca"
                                                                    elementos={
                                                                      listaFiltrada
                                                                    }
                                                                    required={this.props.modoDefinitivo}
                                                                  />
                                                                );
                                                              }}
                                                            </Field>
                                                          </Col>
                                                        </Row>
                                                        <Row>
                                                          <Col md={12}>
                                                            <Atitudes
                                                              permitirEdicao={
                                                                this.props
                                                                  .permitirEdicao
                                                              }
                                                              nome={
                                                                "atitudes"
                                                              }
                                                              formAtividade={
                                                                this.props
                                                                  .referenciaForm
                                                              }
                                                              valoresIniciais={{}}
                                                              tiposNotacao={
                                                                this.state
                                                                  .tiposNotacao
                                                              }
                                                            />
                                                          </Col>
                                                        </Row>
                                                      </>
                                                    );
                                                  }}
                                                />
                                              </Col>
                                            </Row>
                                          )}
                                        </Field>
                                      </Card.Body>
                                    </Card>
                                    <Row className="mt-4">
                                      <Col md={6}>
                                        <Field
                                          name="metalogenia.dadosInterpretativos.controleMineralizacao.controleEstratigrafico.idTipoControle"
                                          subscription={{ value: true }}
                                        >
                                          {({
                                            input: { value: idTipoControle },
                                          }) => {
                                            let listaFiltrada = [];
                                            this.state?.estratigrafico?.map(
                                              (item) => {
                                                if (
                                                  item.inControleEstratigrafico
                                                    ?.toString()
                                                    .toLowerCase() === "s"
                                                ) {
                                                  listaFiltrada.push(item);
                                                }
                                              }
                                            );
                                            return (
                                              <Field
                                                permitirEdicao={
                                                  this.props.permitirEdicao
                                                }
                                                name="metalogenia.dadosInterpretativos.controleMineralizacao.controleEstratigrafico.idTipoControle"
                                                component={DropdownListField}
                                                label="Estratigráfico"
                                                dica="Informar (caso haja) o tipo de controle estratigráfico, a partir da biblioteca"
                                                elementos={listaFiltrada}
                                              />
                                            );
                                          }}
                                        </Field>
                                      </Col>

                                      {formProps.form.getState().values
                                        ?.metalogenia?.dadosInterpretativos
                                        ?.controleMineralizacao
                                        ?.controleEstratigrafico
                                        ?.idTipoControle === 431 ? (
                                        <>
                                          <Col md={6}>
                                            <Field
                                              permitirEdicao={
                                                this.props.permitirEdicao
                                              }
                                              component={TextInputField}
                                              name="metalogenia.dadosInterpretativos.controleMineralizacao.controleEstratigrafico.descricao"
                                              label="Descrição"
                                              dica="Descrição"
                                            />
                                          </Col>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </Row>
                                    <Row className="mt-4">
                                      <Col md={6}>
                                        <FormSpy subscription={{ values: true }}>
                                          {({ values }) => {


                                            let habilitado = false;

                                            if (values.metalogenia && values.metalogenia.dadosDescritivos && values.metalogenia.dadosDescritivos.rochasEncaixantes) {
                                              const rochasEncaixantes = resolverExibidos(values.metalogenia.dadosDescritivos.rochasEncaixantes).filter(i => i != null);
                                              if (rochasEncaixantes.length)
                                                habilitado = true;
                                            }

                                            if (values.metalogenia && values.metalogenia.dadosDescritivos && values.metalogenia.dadosDescritivos.rochasHospedeiras) {
                                              const rochasHospedeiras = resolverExibidos(values.metalogenia.dadosDescritivos.rochasHospedeiras).filter(i => i != null);
                                              if (rochasHospedeiras.length)
                                                habilitado = true;
                                            }

                                            if (formProps.form.getState()?.values?.metalogenia?.dadosInterpretativos?.controleMineralizacao?.controleLitologico && !habilitado) {
                                              formProps.form.mutators.setValue('metalogenia.dadosInterpretativos.controleMineralizacao.controleLitologico', null);
                                            }

                                            return <Field
                                              disabled={!habilitado}
                                              permitirEdicao={!habilitado && this.props.permitirEdicao}
                                              name="metalogenia.dadosInterpretativos.controleMineralizacao.controleLitologico"
                                              component={DropdownListField}
                                              label="Litológico"
                                              dica="Informar (caso haja) o tipo de controle litológico, a partir da biblioteca"
                                              placeholder={'Selecione...'}
                                              elementos={this.state.rochasLito}
                                              onBlur={() => this.atualizarValidacaoRochas()}
                                              onClear={() => this.atualizarValidacaoRochas()}
                                            />
                                          }}
                                        </FormSpy>
                                      </Col>
                                    </Row>
                                  </>
                                )
                              }}
                            </Field>
                          </Card.Body>
                        </Card>
                        <FormSpy subscription={{ values: true }}>
                          {({ values }) => {
                            return (
                              <Card className="mt-3">
                                <Card.Body>
                                  <Card.Subtitle>
                                    Classificação do depósito
                                  </Card.Subtitle>

                                  <Field
                                    component={HiddenField}
                                    name="metalogenia.dadosInterpretativos.classificacaoDeposito.id"
                                    initialValue={
                                      formProps.form.getState().values
                                        ?.metalogenia
                                        ?.dadosInterpretativos
                                        ?.classificacaoDeposito?.id
                                    }
                                  />

                                  <Row className="mt-3">
                                    <Field
                                      name="metalogenia.dadosInterpretativos.classificacaoDeposito.idClasseClassificacaoDeposito"
                                      subscription={{ value: true }}
                                    >
                                      {({ input: { value: idClasseClassificacaoDeposito } }) => {
                                        this.selecionarSistemaDeposito(formProps, idClasseClassificacaoDeposito);
                                        let idClasseClassificacao = formProps.form.getState().values?.metalogenia?.dadosInterpretativos?.classificacaoDeposito?.idClasseClassificacaoDeposito;
                                        this.selecionarProcessoFundamentalDeposito(formProps, idClasseClassificacao, formProps.form.getState().values?.metalogenia?.dadosInterpretativos?.classificacaoDeposito?.idSistemaClassificacaoDeposito);
                                        let idSistemaClassificacaoDeposito = formProps.form.getState().values?.metalogenia?.dadosInterpretativos?.classificacaoDeposito?.idSistemaClassificacaoDeposito;
                                        this.selecionarTipoModeloDeposito(formProps, idClasseClassificacao, idSistemaClassificacaoDeposito, formProps.form.getState().values?.metalogenia?.dadosInterpretativos?.classificacaoDeposito?.idProcessoFundamental);

                                        return <>
                                          <Col md={3}>
                                            <Field
                                              permitirEdicao={
                                                this.props.permitirEdicao
                                              }
                                              name="metalogenia.dadosInterpretativos.classificacaoDeposito.idClasseClassificacaoDeposito"
                                              component={DropdownListField}
                                              label="Classe"
                                              dica="Informar a classe do depósito mineral ou mineralização (não confundir com tipo ou tipologia) a partir da biblioteca"
                                              elementos={this.state.classe}
                                            />
                                          </Col>
                                        </>
                                      }}
                                    </Field>
                                    <Field
                                      name="metalogenia.dadosInterpretativos.classificacaoDeposito.idClasseClassificacaoDeposito"
                                      subscription={{ value: true }}
                                    >
                                      {({ input: { value: idClasseClassificacaoDeposito } }) => {
                                        if (!idClasseClassificacaoDeposito && formProps.form.getState().values?.metalogenia?.dadosInterpretativos?.classificacaoDeposito?.idSistemaClassificacaoDeposito !== null) {
                                          formProps.form.mutators.setValue('metalogenia.dadosInterpretativos.classificacaoDeposito.idSistemaClassificacaoDeposito', null);


                                          formProps.form.mutators.setValue('metalogenia.dadosInterpretativos.classificacaoDeposito.idProcessoFundamental', null);


                                          formProps.form.mutators.setValue('metalogenia.dadosInterpretativos.classificacaoDeposito.idModeloClassificacaoDeposito', null);


                                          return <>
                                            <Col md={3}>
                                              <Field
                                                name="tempSistemasClassificacaoDeposito"
                                                component={DropdownListField}
                                                label="Sistemas "
                                                dica="Informar ao sistema mineral gerador da mineralização a partir da biblioteca "
                                              />
                                            </Col>
                                            <Col md={3}>
                                              <Field
                                                name="tempProcessosClassificacaoDeposito"
                                                component={DropdownListField}
                                                label="Processo fundamental "
                                                dica="Informar o processo fundamental responsável pela mineralização a partir da biblioteca "
                                              />
                                            </Col>
                                            <Col md={3}>
                                              <Field
                                                name="tempModeloClassificacaoDeposito"
                                                component={DropdownListField}
                                                label="Tipo ou modelo "
                                                dica="Informar (caso haja) o tipo ou tipologia de depósito ou mineralização (não confundir com classe), a partir da biblioteca "
                                              />
                                            </Col>
                                          </>
                                        } else {
                                          return <>
                                            <Col md={3}>
                                              <Field
                                                permitirEdicao={
                                                  this.props.permitirEdicao
                                                }
                                                name="metalogenia.dadosInterpretativos.classificacaoDeposito.idSistemaClassificacaoDeposito"
                                                component={DropdownListField}
                                                label="Sistemas"
                                                dica="Informar o sistema mineral gerador da mineralização a partir da biblioteca"
                                                isClearable={false}
                                                elementos={formProps.form.getState().values.tempSistemasClassificacaoDeposito}
                                              />
                                            </Col>
                                            <Col md={3}>
                                              <Field
                                                permitirEdicao={
                                                  this.props.permitirEdicao
                                                }
                                                name="metalogenia.dadosInterpretativos.classificacaoDeposito.idProcessoFundamental"
                                                component={DropdownListField}
                                                label="Processo fundamental"
                                                dica="Informar o processo fundamental responsável pela mineralização a partir da biblioteca"
                                                isClearable={false}
                                                elementos={
                                                  formProps.form.getState().values.tempProcessosClassificacaoDeposito
                                                }
                                              />
                                            </Col>
                                            <Col md={3}>
                                              <Field
                                                permitirEdicao={
                                                  this.props.permitirEdicao
                                                }
                                                name="metalogenia.dadosInterpretativos.classificacaoDeposito.idModeloClassificacaoDeposito"
                                                component={DropdownListField}
                                                label="Tipo ou modelo"
                                                dica="Informar (caso haja) o tipo ou tipologia de depósito ou mineralização (não confundir com classe), a partir da biblioteca"
                                                isClearable={false}
                                                elementos={formProps.form.getState().values.tempModeloClassificacaoDeposito}
                                              />
                                            </Col>
                                          </>
                                        }


                                      }}
                                    </Field>
                                  </Row>
                                </Card.Body>
                              </Card>
                            );
                          }}
                        </FormSpy>
                      </Card.Body>
                    </Card>

                    <Card className="mt-3">
                      <Card.Body>
                        <Row>
                          <Col md={12}>
                            <Card.Title>Ambiente tectônico</Card.Title>
                          </Col>
                        </Row>
                        <Row>
                          <Field
                            name="idSubAmbienteTectonico"
                            subscription={{ value: true }}
                          >
                            {({ input: { value: idSubAmbienteTectonico } }) => {
                              let idSubAmbiente = formProps.form.getState().values.idSubAmbienteTectonico;
                              let listaFiltradaParaExibicao = this.state.regimeTectonico?.filter((item) => {
                                return item.idSubAmbienteTectonico == idSubAmbiente
                              });

                              formProps.form?.mutators?.setValue(
                                "tempRegimesTectonicos",
                                listaFiltradaParaExibicao
                              );

                              let listaFiltradaAmbienteTectonico = this.state.ambienteTectonico?.filter((item) => {
                                return item.idSubAmbienteTectonico == idSubAmbiente
                              });

                              formProps.form?.mutators?.setValue(
                                "tempAmbientesTectonicos",
                                listaFiltradaAmbienteTectonico
                              );

                              if (formProps.form.getState().values.tempRegimesTectonicos.length == 1) {
                                formProps.form?.mutators?.setValue(
                                  "idRegimeTectonico",
                                  formProps.form.getState().values.tempRegimesTectonicos[0].id
                                );
                              }

                              if (formProps.form.getState().values.tempAmbientesTectonicos.length == 1) {
                                formProps.form?.mutators?.setValue(
                                  "idAmbienteTectonico",
                                  formProps.form.getState().values.tempAmbientesTectonicos[0].id
                                );
                              }

                              return <>
                                <Col md={4}>
                                  <Field
                                    permitirEdicao={this.props.permitirEdicao}
                                    name="idSubAmbienteTectonico"
                                    component={DropdownListField}
                                    label="Subambiente tectônico"
                                    dica="Informar o subambiente tectônico em que se formou a mineralização ou depósito mineral (a partir da biblioteca)"
                                    elementos={this.state.subambienteTectonico}
                                  />
                                </Col>
                              </>
                            }}
                          </Field>


                          <Field
                            name="idSubAmbienteTectonico"
                            subscription={{ value: true }}
                          >
                            {({ input: { value: idSubAmbienteTectonico } }) => {

                              if (formProps.form.getState().values.tempRegimesTectonicos.length == 1) {
                                formProps.form?.mutators?.setValue(
                                  "idRegimeTectonico",
                                  formProps.form.getState().values.tempRegimesTectonicos[0].id
                                );
                              }

                              if (formProps.form.getState().values.tempAmbientesTectonicos.length == 1) {
                                formProps.form?.mutators?.setValue(
                                  "idAmbienteTectonico",
                                  formProps.form.getState().values.tempAmbientesTectonicos[0].id
                                );
                              }
                              return <>
                                <Col md={4}>
                                  <Field
                                    disabled
                                    name="idAmbienteTectonico"
                                    component={DropdownListField}
                                    label="Ambiente tectônico"
                                    dica="Informar o ambiente tectônico em que se formou a mineralização ou depósito mineral (a partir da biblioteca)"
                                    isClearable={false}
                                    elementos={formProps.form.getState().values.tempAmbientesTectonicos}
                                  />
                                </Col>
                                <Col md={4}>
                                  <Field
                                    disabled
                                    name="idRegimeTectonico"
                                    component={DropdownListField}
                                    label="Regime tectônico"
                                    dica="Informar o regime tectônico do ambiente e subambiente em que se formou a mineralização ou depósito mineral (a partir da biblioteca)"
                                    isClearable={false}
                                    elementos={formProps.form.getState().values.tempRegimesTectonicos}
                                  />
                                </Col>
                              </>
                            }}
                          </Field>


                        </Row>
                      </Card.Body>
                    </Card>
                    <Card className="mt-3">
                      <Card.Body>
                        <Row className="mb-3">
                          <Col md={12}>
                            <Field
                              component={TextAreaField}
                              name={`informacoesRelevantes`}
                              label="Informações relevantes"
                              dica="Informar detalhadamente as informações tectônicas mais importantes para esta mineralização"
                              maxLength={250}
                              disabled={!this.props.permitirEdicao}
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                    <Row className="mt-3">
                      <Col md={12}>
                        <FonteReferencia
                          permitirEdicao={this.props.permitirEdicao}
                          formPai={formProps}
                          mutators={{ setValue }}
                          origem={"ESTUDO_METALOGENETICO"}
                          modoDefinitivo={this.props.modoDefinitivo}
                        />
                      </Col>
                    </Row>
                  </>
                );
              }}
            />
          )}
        </Field>
      </BlockUi>
    );
  }
}

export default EstudosMetalogeneticos;
