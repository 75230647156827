import React, { useCallback, useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import { Table } from "../../components/Jqx/Table";
import PageScaffold from "../../components/scaffold/PageScaffold";
import "../../assets/css/publicacao.css";

import BlockUi from "react-block-ui";
import { Col, Form, Row } from "react-bootstrap";
import Card from "../../components/card";
import InfoPanelGPS from "../../components/infoPanelGPS";
import LegendaMapa from "../estacoes/components/mapa/LegendaMapa";
import { DropdownListWithValue } from "../../components/Jqx/DropdownList";
import Hint from "../../components/hint/Hint";
import { FocusableButton } from "../../components/Jqx/Button";
import Service, { VALIDACAO } from "../../service/Service";
import swal from "sweetalert";
import GridAppendButton from "../../components/Jqx/Button/GridAppendButton";
import { colunasAtividadesPublicacao, COLUNA_CHECKBOX_PUBLICADO } from "./ColunasAtividadesPublicacao";
import { datafieldsAtividadesPublicacao } from "./DataFieldsAtividadesPublicacao";
import AppMapComponent from "../../components/mapa/map_component/AppMapComponent";
import { configMapaPublicacao } from "../../config/mapa";
import { geoJSONParaArcGIS } from "../../util/mapa";

const Publicacao = (props) => {
  const tableRef = useRef(null);
  const [mapaRef, setMapaRef] = useState(null);
  const [localidadeStorage, setLocalidadeStorage] = useState(null);
  const [, setIsPublicadorProjeto] = useState(false);
  const [idSubAreaSelecionada, setIdSubAreaSelecionada] = useState(null);
  const [listaSubAreas, setListaSubAreas] = useState([]);
  const [listaAtividades, setListaAtividades] = useState([]);
  const [selectAllPublicacao, setSelectAllPublicacao] = useState(false);
  const [, setRowsSelecionadasPublicacao] = useState(false);

  const projetosService = Service("/projetos");

  const iconCheckboxPublicacao = `fas text-secondary ${selectAllPublicacao ? "fa-check-square" : "fa-square"} `; 

  const atualizaListaSubAreas = async () => {
    if (!localidadeStorage) return;
    const idProjeto = localidadeStorage.idProjeto;
    const respostaSubAreas = await projetosService.customURL("GET", `/${idProjeto}/subAreas`);

    // Ordena a lista de subáreas por ordem alfabética
    let subAreaOrdenada = respostaSubAreas.data.dados.sort((a, b) => {
      if (a.nome < b.nome) {
          return -1;
      }
      if (a.nome > b.nome) {
          return 1;
      }
      return 0;
    });
    const subAreas = subAreaOrdenada;
    
    if (!subAreas.find((subArea) => subArea.nome === "Todas")) {
      subAreas.unshift({ id: -1, nome: "Todas" });
    }
    setListaSubAreas(subAreas);
  };

  const atividadesParaPublicacao = async () => {
    const idArea = JSON.parse(sessionStorage.getItem("localidade")).idArea;
    if(idSubAreaSelecionada === null || localidadeStorage === null || !idSubAreaSelecionada || !localidadeStorage)
        return;
      if (idSubAreaSelecionada === -1 ) {
        const respostaAtividades = await Service(
          `/atividades/validacao/consultarTodasAtividadesParaPublicacao`,
          VALIDACAO
        ).get(idArea);
        const atividades = respostaAtividades.data;
        setListaAtividades(atividades);
        
      } else {
        const respostaAtividades = await Service(
          `/atividades/validacao/consultarAtividadesParaPublicacaoPorSubArea`,
          VALIDACAO
        ).get(idSubAreaSelecionada);
        const atividades = respostaAtividades.data;
        setListaAtividades(atividades);
      }    
  }
  
  const calcularPorcentagemPublicadas = (totalAtividades, totalPublicadas) => {
    if (totalAtividades === 0) {
      const nenhumaAtividade = 0;
      return nenhumaAtividade.toFixed(2);
    }
    const porcentagem = (totalPublicadas / totalAtividades) * 100;
    return porcentagem.toFixed(2);
  };


  const carregaValoresStorage = () => {
    const storage = JSON.parse(sessionStorage.getItem("localidade"));
    setLocalidadeStorage(storage);
    setIdSubAreaSelecionada(storage.idSubArea);
    setIsPublicadorProjeto(storage.publicadorProjeto);
  };

  const publicarAtividadesMarcadas = useCallback(async () => {
    if (tableRef.current === null) return;
  
    const displayingRows = tableRef.current.getdisplayrows();
    const rowsMarcadasPublicacao = displayingRows.filter(item => item[COLUNA_CHECKBOX_PUBLICADO]);
    const totalPublicadas = rowsMarcadasPublicacao.reduce((total, item) => total + item.totalPublicadas, 0);
    const totalValidadas = rowsMarcadasPublicacao.reduce((total, item) => total + item.totalValidadas, 0);
  
    const qtdItensSelecionados = rowsMarcadasPublicacao.length;
    if (qtdItensSelecionados <= 0) {
      return;
    }
    
    if (totalPublicadas >= totalValidadas) {
      await swal({
        title: "Não há atividades do tipo selecionado para serem publicadas",
        icon: "warning",
      });
      return;
    }
  
    const result = await swal({
      title: `Deseja publicar ${qtdItensSelecionados} tipo(s) de atividade(s)?`,
      icon: "info",
      buttons: {
        cancel: "Não, cancelar",
        confirm: { text: "Sim, desejo prosseguir!", className: "btn-success" },
      },
    });
  
    if (!result) {
      return;
    }
  
    const tiposSolicitados = rowsMarcadasPublicacao.map(item => item.tipo);
    const localidade = JSON.parse(sessionStorage.getItem("localidade"));
    const idProjeto = localidade?.idProjeto;
    const idArea = localidade?.idArea;
    const idSubArea = (idSubAreaSelecionada === -1 ? 0 : idSubAreaSelecionada);
  
    const payload = {
      tiposSolicitados: tiposSolicitados,
      idProjeto: idProjeto,
      idSubAreas: idSubArea,
      idAreas: idArea,
    };
  
    try {  
      const respostaAtividades = await Service(
        "/atividades/validacao/atualizarStatusParaPublicado",
        VALIDACAO
      ).post(payload);
  
      const atividades = respostaAtividades.data;
      setListaAtividades(atividades);
      
      atividadesParaPublicacao();
      await swal({
        title: "Sucesso",
        text: `${qtdItensSelecionados} tipos de atividades publicadas com sucesso!`,
        icon: "success",
      });
  
    } catch (error) {
      console.error("Erro ao publicar atividades:", error);
      const errorMessage = error?.response?.data?.message || "Ocorreu um erro ao publicar as atividades.";
      await swal({
        title: "Erro",
        text: errorMessage,
        icon: "error",
      });
    }
  }, [localidadeStorage, idSubAreaSelecionada]);
  
  //#region métodos de mapas
  const onFiltrar = () => {
    if(!mapaRef)
      return;
    mapaRef.limparPontos();
  }

  const [, setMapaCarregado] = useState(false);
  const [, setLayerAtividadesPublicacao] = useState(null);
  const onMapaCarregado = () => {
    if(!localidadeStorage || !listaSubAreas)
      return;

    setMapaCarregado(true);
    setLayerAtividadesPublicacao(mapaRef.props.config.state.otherLayers[0]);
    
    // Geralmente o projeto/area/subarea carregam e são selecionados automaticamente antes do mapa,
    // então quando o mapa carregar, utilizamos os valores atuais para filtragem
    if(!mapaRef)
      return;

    let idProjeto = localidadeStorage.idProjeto;   
    const subareaSelecionada = listaSubAreas.find(subarea => subarea.id === idSubAreaSelecionada);
    const geometryFilter = geoJSONParaArcGIS(subareaSelecionada.geoJSON);

    mapaRef.limparPontos(true);
    mapaRef.filtrar(
      idProjeto,
      geometryFilter
    );

    filtrarAtividadesProjeto();
  }

  const filtrarAtividadesProjeto = () => {
    let idProjeto = localidadeStorage.idProjeto;   
    let layerAtividades =  mapaRef.props.config.state.otherLayers[0];
    let campoIdProjeto = layerAtividades.projectIdField;
    let url = layerAtividades.url;

    if(tableRef.current === null){
      let query = "1 = 2";
      mapaRef.filtrarOutro(url, query);
      return;
    }

    const displayingRows = tableRef.current.getdisplayrows();
    if(!displayingRows.some((row) => row.checkboxPublicado)){
      let query = "1 = 2";
      mapaRef.filtrarOutro(url, query);
      return;
    }
    let tiposSelecionados = displayingRows.filter(row=> row.checkboxPublicado === true).map(row => row.tipo);
    
    let query = campoIdProjeto + " = " + idProjeto;
    query += " AND ds_tipo_atividade IN ("
    let isFirst = true;
    tiposSelecionados?.forEach(tipo => {
      if(isFirst === false){
        query +=  ","
      }else{
        isFirst = false;
      }
      query += "'" + tipo + "'";
    });
    query +=  " ) "

    mapaRef.filtrarOutro(url, query);
  }
  //#endregion

  useEffect(() => {
    atualizaListaSubAreas();
    carregaValoresStorage();
    atividadesParaPublicacao();
  }, []);

  useEffect(() => {
    atualizaListaSubAreas();
    atividadesParaPublicacao();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idSubAreaSelecionada]);

  return (
    <BlockUi>
      <PageScaffold titulo="Publicação de atividades por Projeto" disableInfo>
        <Container>
          <InfoPanelGPS hideSubArea />
          <Card>
            <Card.Body>
              <Row>
                <Col md={4} className="mb-5">
                  <Form.Label className="small">
                    Subárea
                    <Hint textoAjuda="Informe a subárea do projeto" />
                  </Form.Label>
                  <DropdownListWithValue
                    elementos={listaSubAreas}
                    onSelect={setIdSubAreaSelecionada}
                    value={idSubAreaSelecionada}
                  />
                </Col>
                <hr />
              </Row>
              <Row>
                <Col md="12">
                  <LegendaMapa
                    exibirEstacao={false}
                    exibirGeometriaProjeto={true}
                    outrosElementos={[
                      { descricao: "Validado", cor: "rgba(56,118,29,100)" },
                      { descricao: "Não Validado", cor: "rgba(155,155,155,100)" },
                      { descricao: "Estação", cor: "rgba(0,0,255,100)" },
                    ]}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="8">
                  <Container className="container-grid-atividades-publicar">
                    <Row>
                      <Col md="4">
                        <GridAppendButton
                          className="float-right mb-0 check-revisao-publicacao"
                          onClick={() => {
                            let rows = tableRef.current.getdisplayrows();
                            const indexExibidos = rows.map((value) => { return value.id; });
                            const rowsToEdit = tableRef?.current?.props?.source?.originaldata.filter((value) => indexExibidos.includes(value.id));
                            rowsToEdit.forEach((value) => { value[COLUNA_CHECKBOX_PUBLICADO] = !selectAllPublicacao });
                            setSelectAllPublicacao(!selectAllPublicacao);
                            tableRef.current.updatebounddata('sort');
                            setRowsSelecionadasPublicacao(rowsToEdit);
                            filtrarAtividadesProjeto();
                          }}>
                          <i className={iconCheckboxPublicacao} style={{ marginRight: "15px" }} ></i>
                          <span style={{ marginLeft: "5px" }}>
                            Selecionar todos para publicação
                          </span>
                        </GridAppendButton>
                      </Col>
                      <Col md="4"></Col>
                      <Col md="12">
                        <Table
                          jqxRef={tableRef}
                          datafields={datafieldsAtividadesPublicacao}
                          columns={colunasAtividadesPublicacao(calcularPorcentagemPublicadas)}
                          items={listaAtividades}
                          onCellvaluechanged={() => filtrarAtividadesProjeto()}
                          editable
                        />
                      </Col>
                    </Row>
                    <Row>
                    <Col md="6" className="mt-3">
                        <FocusableButton
                          className="float-left m-2"
                          onClick={() => {
                            props.history.goBack();
                          }}
                        >
                          Voltar
                        </FocusableButton>
                      </Col>
                      <Col md="6" className="mt-3">
                        <FocusableButton
                          className="float-right m-2"
                          onClick={() => {
                            publicarAtividadesMarcadas()
                          }}
                        >
                          Publicar
                        </FocusableButton>
                      </Col>
                    </Row>
                  </Container>
                </Col>
                <Col md="4">
                  <AppMapComponent
                      ref={(instance) => {
                        setMapaRef(instance);
                      }}
                      config={configMapaPublicacao}
                      onCarregarMapa={onMapaCarregado}
                      onFiltrar={onFiltrar}
                      debugMensagens={!!process.env.REACT_APP_MAP_DEBUG}
                    />
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <hr />
        </Container>
      </PageScaffold>
    </BlockUi>
  );
};

export default Publicacao;
