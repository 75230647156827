const COLUNA_VISUALIZAR = "idVisualizar";
const COLUNA_CHECKBOX_REVISADO = "checkboxRevisado";
const COLUNA_CHECKBOX_VALIDADO = "checkboxValidado";

export const datafieldsAtividades = [
    { name: COLUNA_VISUALIZAR, type: "number", map: "id" },
    { name: "id", type: "number" },
    { name: "idVisita", type: "number" },
    { name: "responsavelTecnico", type: "string" },
    { name: "tipo", type: "string" },
    { name: "modoCadastro", type: "string" },
    { name: "nomeTipo", type: "string" },
    { name: "numeroCampo", type: "string" },
    { name: "idClasseAmostra", type: "string" },
    { name: "modoEdicao", type: "string" },
    { name: "statusValidacao", type: "string" },
    { name: "idStatusValidacao", type: "number" },
    { name: "ultimaAtualizacao", type: "string" },
    { name: "comentarioValidacao", type: "string" },
    { name: "subArea", type: "string" },
    { name: COLUNA_CHECKBOX_REVISADO, type: "bool" },
    { name: COLUNA_CHECKBOX_VALIDADO, type: "bool" },
    { name: "revisado", type: "bool" },
    { name: "validado", type: "bool" },
    { name: "publicado", type: "bool" },
    { name: "podeValidar", type: "bool" },
    { name: "podeRevisar", type: "bool" },
    { name: "dataCriacao", type: "string" },
    { name: "flagRascunho", type: "string" },
    { name: "idCriador", type: "number" },
    { name: "resumo", type: "string" },
];