import { isArray } from "lodash";

const codigoEPSGPadrao = 4326 // WGS84

// GeoJson format: https://geojson.org/
// ArcGIS Polygon data type: https://developers.arcgis.com/documentation/common-data-types/geometry-objects.htm#POLYGON

// Converte o polígono em formato GeoJSON retornado da query de geometria para um polígono em formato ArcGIS
const geoJSONParaArcGIS = geoJSONPolygon => {
    const geoJSON = JSON.parse(geoJSONPolygon);

    if (
        !geoJSON
        || !geoJSON.coordinates
        || !isArray(geoJSON.coordinates)
        || !geoJSON?.coordinates?.some((polygon) => isArray(polygon))
    ) {
        return null;
    }

    return JSON.stringify({
        rings: geoJSON.coordinates.flatMap((polygon) => polygon),
        spatialReference: { wkid: codigoEPSGPadrao },
    });
};



export { geoJSONParaArcGIS }