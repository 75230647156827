import { FORM_ERROR } from "final-form";
import { resolverExibidos } from "../../../../components/SubForm/SubForm";

export const calcularSomaPercentuais = (percentuais) => {
  let soma;
  if (percentuais) {
    soma = percentuais.reduce((soma, item) => {
      const numeroPercentual = item || 0;
      return Number(Number(soma) + Number(numeroPercentual));
    }, 0);
  }
  return soma;
};

export const buscaPercentuais = (dados) => {

  if (!dados || !dados.length) {
    return;
  }
  const retorno = [];
  dados.forEach((item) => {
    if (item?.numeroPercentual) {
      retorno.push(item?.numeroPercentual);
    } 
  });
  return retorno;
}

export const buscaPercentuaisParaEstimativaModal = (dados) => {

    if (!dados || !dados.length) {
      return;
    }
    const retorno = [];
    dados.forEach((item) => {

        for (let campo in item) {
          if (campo !== 'id') {
            retorno.push(item[campo]);
          }
        }
      
    });
    return retorno;
}

export const buscaPercentuaisParaPorosidade = (dados) => {

  if (!dados || !dados.length) {
    return;
  }
  const retorno = [];
  dados.forEach((item) => {
    if (item?.porcentagemPoros) {
      retorno.push(item?.porcentagemPoros);
    } 
  });
  return retorno;
}

export const validaGranulometriaMinima = ((value, allValues, fieldState) => {
  if (allValues?.granulometriaMinima > allValues?.granulometriaMaxima) {
    return "Valor mínimo não pode ser maior que o valor máximo.";
  }
})

export const validaGranulometriaMaxima = ((value, allValues, fieldState) => {
  if (allValues?.granulometriaMaxima < allValues?.granulometriaMinima) {
    return "Valor máximo não pode ser menor que o valor mínimo.";
  }
})


export const validacoesGeraisArcabouco = (valores) => { 
  if (!(valores?.fragmentosMinerais || valores?.fragmentosLiticos || valores?.componentesAloquimicos)) {
    return { [FORM_ERROR]: "Ao menos um dos subformularios deve ser preenchido"}
  }
  const validacaoFragmentosMinerais = validarPorcentagemTotal(valores, 'fragmentosMinerais', 'porcentagemArcabouco');
  if (validacaoFragmentosMinerais) {
    return { [FORM_ERROR]: "A soma das porcentagens dos Fragmentos Minerais deve ser 100%."}
  }

  const validacaoFragmentosLiticos = validarPorcentagemTotal(valores, 'fragmentosLiticos', 'porcentagemFracao');
  if (validacaoFragmentosLiticos) {
    return { [FORM_ERROR]: "A soma das porcentagens dos Fragmentos Líticos deve ser 100%."};
  }

  const validacaoComponentesAloquimicos = validarPorcentagemTotal(valores, 'componentesAloquimicos', 'porcentagemFracao');
  if (validacaoComponentesAloquimicos) {
    return { [FORM_ERROR]: "A soma das porcentagens dos Componentes Aloquímicos deve ser 100%."};
  }

  return null;
};


export const validacoesGeraisMatriz = (valores) => { 
  if ( valores?.fasesMinerais) {
    let fasesMineraisTela = resolverExibidos(valores?.fasesMinerais);
    if (fasesMineraisTela.length > 0) {
        const validacaoFragmentosMinerais = validarPorcentagemTotal(valores, 'fasesMinerais', 'porcentagemFracao');
        if (validacaoFragmentosMinerais) {
            return { [FORM_ERROR]: "A soma das porcentagens das Fases Minerais deve ser 100%." }
        }
    }
  }

};
export const buscaPercentuaisArcabouco = (dados, valor) => {
  if (!dados || !dados.length) {
    return;
  }
  const retorno = [];
  dados.forEach((item) => {
    if (item[valor]) {
      retorno.push(item[valor]);
    }
  });
  return retorno;
}
export const validarPorcentagemTotal = (valores, propriedade, campo) => {
  const itens = valores?.[propriedade];
  if (!itens) {
    return false;
  }

  const itensTela = resolverExibidos(itens);
  if (!itensTela || itensTela.length <= 0) {
    return false;
  }

  const totalPorcentagem = itensTela.reduce((total, objeto) => total + objeto?.[campo], 0);
  if (totalPorcentagem !== 100) {
    return true;
  }

  return false;
};

export const validarCamposVazios = (valores, propriedade, campo) => {
  const itens = valores?.[propriedade];
  if (!itens) {
    return false;
  }

  return false;
};

export function forcarLimparComboBox(className) {
  setTimeout(() => {
      try {
          document.getElementsByClassName(className)[0].children[1].children[0].children[0].textContent = 'Selecione..';
      } catch (err) {
          console.log('Erro ao limpar combo', err);
      }
  }, 50);
}

export function forcarValorCampo(className, valor) {
  setTimeout(() => {
    try {
      document.getElementsByClassName(className)[0].children[1].children[0].children[0].textContent = valor ? valor : '';
    } catch (err) {
      console.log('Erro ao forçar valor na combo', err);
    }
  }, 50);
}