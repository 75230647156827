import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Field, useForm } from "react-final-form";
import { aoEnviarSubForm } from "../../../../../util/mutadores";
import Card from "../../../../../components/card";
import { SubForm } from "../../../../../components/SubForm";
import { DropdownListField } from "../../../../../components/field/DropdownListField";
import { getPropsPermissao, resolverExibidos } from "../../../../../components/SubForm/SubForm";
import { campoObrigatorioComMsgGenerica, comporValidadores } from "../../../../../util/validadores";
import { NumberInputField } from "../../../../../components/field/NumberInputField";
import Hint from "../../../../../components/hint/Hint";
import { validaGranulometriaMaxima, validaGranulometriaMinima } from "../../../utils/gerais";
import { FORM_ERROR } from "final-form";
import { SEDIMENTAR } from "../../../utils/contantes/constantesPetrografia";

const FragmentosLiticos = ({
    permitirEdicao,
    bbRocha,
    bbPrefixoRocha,
    bbSufixoRocha,
    bbUnidadeComprimento,
    disabled,
    form
}) => {

    const onSubmit = aoEnviarSubForm(form);

    const colunasFragmentosLiticos = [
        {
            text: 'Fragmentos liticos',
            datafield: 'idPrefixo',
            width: '40%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {

                const prefixo = bbPrefixoRocha.find(elemento => elemento.id === rowdata?.idPrefixo);
                const rocha = bbRocha.find(elemento => elemento.id === rowdata?.idRocha);
                const complemento = bbSufixoRocha.find(elemento => elemento.id === rowdata?.idComplemento);

                let valor = '';
                valor += prefixo?.nome ? prefixo?.nome + " " : '';
                valor += rocha?.nome? rocha?.nome + " ": '';
                valor += complemento?.nome? complemento?.nome : '';

                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valor}</div>`;
            }
        },
        {
            text: '% na fração',
            datafield: 'porcentagemFracao',
            width: '19%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {

                let valor = "";
                if (value === 0 || value === "0") {
                    valor = "Acessório";
                } else {
                    valor = value;
                }
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valor}</div>`;
            }
        },
        {
            text: 'Granulometria (Mínima)',
            datafield: 'granulometriaMinima',
            width: '19%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                const numeroTamanhoMinimo = bbUnidadeComprimento.find(elemento => elemento.id === rowdata?.idUnidadeMedidaGranulometria);
                const valorMinimo = value + (numeroTamanhoMinimo?.sigla ?? "");
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valorMinimo}</div>`;
            }
        },
        {
            text: 'Granulometria (Máxima)',
            datafield: 'granulometriaMaxima',
            width: '19%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                const numeroTamanhoMaximo = bbUnidadeComprimento.find(elemento => elemento.id === rowdata?.idUnidadeMedidaGranulometria);
                const valormaximo = value + (numeroTamanhoMaximo?.sigla ?? "");
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valormaximo}</div>`;
            }
        }
    ]
    const camposFragmentosLiticos = [
        { name: "idPrefixo", type: "number", map: "idPrefixo" },
        { name: "idRocha", type: "number", map: "idRocha" },
        { name: "idComplemento", type: "number", map: "idComplemento" },
        { name: "porcentagemFracao", type: "number", map: "porcentagemFracao" },
        { name: "idUnidadeMedidaGranulometria", type: "number", map: "idUnidadeMedidaGranulometria" },
        { name: "granulometriaMinima", type: "number", map: "granulometriaMinima" },
        { name: "granulometriaMaxima", type: "number", map: "granulometriaMaxima" }
    ];

    const validarFragmentosLiticos = (valores) => {

        const fragmentosLiticos = form.getState().values?.fragmentosLiticos;
        const itens = fragmentosLiticos ? resolverExibidos(fragmentosLiticos) : [];
        const idParaSubstituir = valores.id;
        const indiceParaSubstituir = itens.findIndex(item => item.id === idParaSubstituir);

        if (indiceParaSubstituir !== -1) {
            itens.splice(indiceParaSubstituir, 1, valores);
        } else {
            itens.push(valores);
        }

        let totalPorcentagem = 0;

        if (itens.length > 0) {
            totalPorcentagem = itens.reduce((total, objeto) => {
                return total + Number(objeto.porcentagemFracao);
            }, 0);
        }

        if (totalPorcentagem > 100) {
            return { [FORM_ERROR]: 'A soma da % na fração não deve ultrapassar 100%.' };
        }

        return null
    }
    const valorFormulario = useForm()
    return (
        <>
            <div style={{ marginTop: '2.5rem' }}>
                <Card className="mt-3">
                    <Card.Body>
                        <Card.Title>Fragmentos líticos</Card.Title>
                        <Field name={`fragmentosLiticos`} subscription={{ value: true }}>
                            {({ input: { value: fragmentosLiticos = [] } }) => {

                                return (
                                    <SubForm
                                        nome={`fragmentosLiticos`}
                                        onSubmit={onSubmit}
                                        exibirTabelaSemRegistro={true}
                                        modoSubFormComGrid
                                        colunas={colunasFragmentosLiticos}
                                        elementos={fragmentosLiticos}
                                        campos={camposFragmentosLiticos}
                                        validar={(valores) => validarFragmentosLiticos(valores)}
                                        {...getPropsPermissao(!disabled)}
                                        renderForm={({ formProps: { form } }) => {
                                            const hasUnidadeMedida = !!form.getState().values?.idUnidadeMedidaGranulometria;
                                            const hasMinima = !!form.getState().values?.granulometriaMinima;
                                            const hasMaxima = !!form.getState().values?.granulometriaMaxima;

                                            const camposObrigatorios = hasUnidadeMedida || hasMinima || hasMaxima

                                            var valorAtual = form.getState().values?.idPrefixo
                                            var fragmentosLiticos = valorFormulario?.getState().values?.fragmentosLiticos
                                            var fragmentosLiticosTela = []
                                            if (fragmentosLiticos) {
                                                fragmentosLiticosTela = resolverExibidos(fragmentosLiticos)
                                            }
                                            var bbPrefixoRochaFiltrados = bbPrefixoRocha.filter(value => {
                                                return !fragmentosLiticosTela.some(testeItem => testeItem.idPrefixo === value.id && valorAtual !== value.id);
                                            });
                                            
                                            //Somente rochas sedimentares podem ser cadastradas
                                            let bbRochaFiltradas = bbRocha.filter(objeto => objeto.idTipoRocha === SEDIMENTAR);

                                            return (
                                                <>
                                                    <Row className="my-3">
                                                        <Col md={4}>
                                                            <Field
                                                                component={DropdownListField}
                                                                name={"idPrefixo"}
                                                                label="Prefixo"
                                                                dica="Se for o caso, defina o prefixo de nomenclatura de sua rocha de acordo com o tipo."
                                                                elementos={bbPrefixoRochaFiltrados}
                                                                displayMember={'nome'}
                                                                disabled={disabled}
                                                            />
                                                        </Col>
                                                        <Col md={4}>
                                                            <Field
                                                                component={DropdownListField}
                                                                name={"idRocha"}
                                                                label="Rocha"
                                                                dica="Selecione o nome simples da rocha."
                                                                elementos={bbRochaFiltradas}
                                                                displayMember={'nome'}
                                                                disabled={disabled}
                                                                required
                                                                validate={campoObrigatorioComMsgGenerica("Rocha")}
                                                            />
                                                        </Col>
                                                        <Col md={4}>
                                                            <Field
                                                                component={DropdownListField}
                                                                name={"idComplemento"}
                                                                label="Complemento"
                                                                dica="Se for o caso, defina o complemento de nomenclatura de sua rocha de acordo com o tipo."
                                                                elementos={bbSufixoRocha}
                                                                displayMember={'nome'}
                                                                disabled={disabled}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row className="my-3">
                                                        <Col md={3}>
                                                            <Field
                                                                name="porcentagemFracao"
                                                                component={NumberInputField}
                                                                label="% na fração"
                                                                dica="Estime em porcentagem a proporção deste tipo de fragmento no arcabouço."
                                                                permitirNegativos={false}
                                                                casasInteiros={3}
                                                                casasDecimais={0}
                                                                valorMaximo={100}
                                                                required
                                                                validate={campoObrigatorioComMsgGenerica("% na fração")}
                                                                disabled={disabled}
                                                            />
                                                        </Col>
                                                        {camposObrigatorios && (
                                                            <Col md={6}>
                                                                <fieldset className="border p-2">
                                                                    <legend className="w-auto small" style={{ marginBottom: 8, fontSize: '16px' }}>
                                                                        Granulometria
                                                                        <Hint textoAjuda={'granulometria'} />
                                                                    </legend>
                                                                    <Row>
                                                                        <Col md={4}>
                                                                            <Field
                                                                                component={DropdownListField}
                                                                                name={"idUnidadeMedidaGranulometria"}
                                                                                label="Unidade de medida"
                                                                                dica="Selecione a unidade de medida utilizada."
                                                                                elementos={bbUnidadeComprimento}
                                                                                displayMember={'nome'}
                                                                                disabled={disabled}
                                                                                required
                                                                                validate={campoObrigatorioComMsgGenerica("Unidade de medida")}
                                                                            />
                                                                        </Col>
                                                                        <Col md={4}>
                                                                            <Field
                                                                                name="granulometriaMinima"
                                                                                component={NumberInputField}
                                                                                label="Mínima"
                                                                                dica=" Dimensão dos menores fragmentos observados."
                                                                                permitirNegativos={false}
                                                                                casasInteiros={3}
                                                                                casasDecimais={3}
                                                                                disabled={disabled}
                                                                                required
                                                                                validate={comporValidadores(
                                                                                    campoObrigatorioComMsgGenerica("Mínima"),
                                                                                    validaGranulometriaMinima
                                                                                )}
                                                                            />
                                                                        </Col>
                                                                        <Col md={4}>
                                                                            <Field
                                                                                name="granulometriaMaxima"
                                                                                component={NumberInputField}
                                                                                label="Máxima"
                                                                                dica="Dimensão dos maiores fragmentos observados."
                                                                                permitirNegativos={false}
                                                                                casasInteiros={3}
                                                                                casasDecimais={3}
                                                                                disabled={disabled}
                                                                                required
                                                                                validate={comporValidadores(
                                                                                    campoObrigatorioComMsgGenerica("Máxima"),
                                                                                    validaGranulometriaMaxima
                                                                                )}
                                                                            />
                                                                        </Col>
                                                                    </Row>

                                                                </fieldset>
                                                            </Col>
                                                        )}
                                                        {!camposObrigatorios && (
                                                            <Col md={6}>
                                                                <fieldset className="border p-2">
                                                                    <legend className="w-auto small" style={{ marginBottom: 8, fontSize: '16px' }}>
                                                                        Granulometria
                                                                        <Hint textoAjuda={'granulometria'} />
                                                                    </legend>
                                                                    <Row>
                                                                        <Col md={4}>
                                                                            <Field
                                                                                component={DropdownListField}
                                                                                name={"idUnidadeMedidaGranulometria"}
                                                                                label="Unidade de medida"
                                                                                dica=" Selecione a unidade de medida utilizada."
                                                                                elementos={bbUnidadeComprimento}
                                                                                displayMember={'nome'}
                                                                                disabled={disabled}
                                                                            />
                                                                        </Col>
                                                                        <Col md={4}>
                                                                            <Field
                                                                                name="granulometriaMinima"
                                                                                component={NumberInputField}
                                                                                label="Mínima"
                                                                                dica=" Dimensão dos menores fragmentos observados."
                                                                                permitirNegativos={false}
                                                                                casasInteiros={3}
                                                                                casasDecimais={3}
                                                                                disabled={disabled}
                                                                            />
                                                                        </Col>
                                                                        <Col md={4}>
                                                                            <Field
                                                                                name="granulometriaMaxima"
                                                                                component={NumberInputField}
                                                                                label="Máxima"
                                                                                dica="Dimensão dos maiores fragmentos observados."
                                                                                permitirNegativos={false}
                                                                                casasInteiros={3}
                                                                                casasDecimais={3}
                                                                                disabled={disabled}
                                                                            />
                                                                        </Col>
                                                                    </Row>

                                                                </fieldset>
                                                            </Col>
                                                        )}

                                                    </Row>
                                                </>
                                            )
                                        }
                                        }
                                    />
                                )
                            }}
                        </Field>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
};

export default FragmentosLiticos;