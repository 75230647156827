import React, { useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import Iframe from 'react-iframe';
import './AppAltitudeComponent.css';
import uniqueId from "lodash/uniqueId";

const AppAltitudeComponent = ({ debugMensagens = false, grande = true, aoCarregar = () => {}, aoRenderizarMapa = () => {} }, ref) => {
  const uid = useRef(uniqueId('atitude-')).current

  const aoReceberMensagem = useCallback((evt) => {
    const { target, state } = evt.data ?? {};

    // Ignorar outros eventos 'message' (ex: react)
    if (!target) return;
    // Ignorar eventos de outro iframe (https://stackoverflow.com/a/47676007/11138267)
    if (document.getElementById(uid).contentWindow === evt.source)
    
    if (debugMensagens) {
      console.info(`%cRecebendo mensagem de #${uid}: %c${target}`,
        'color: orange', `color: ${target === 'operationFinished' ? 'grey' : '#76af57'}; font-weight: bold`)
    }

    // Chamar função dependendo da mensagem do componente
    if (target === 'initialized' && aoCarregar) {
      aoCarregar();
    }

    if(target === 'operationFinished' && state === 'showPolygon' && aoRenderizarMapa){
      aoRenderizarMapa();
    }
  }, [debugMensagens, uid, aoCarregar])

  useEffect(() => {
    window.addEventListener('message', aoReceberMensagem)
    return () => window.removeEventListener('message', aoReceberMensagem)
  }, [aoReceberMensagem])

  const enviarMensagem = useCallback((target, payload) => {
    const mensagem = {
      target,
      state: payload,
    };

    if (debugMensagens) {
      console.info(`%cEnviando ao #${uid}: %c${target}`, 'color: orange', 'color: #e772d4; font-weight: bold', mensagem)
    }

    document.getElementById(uid)?.contentWindow.postMessage(mensagem, "*");
  }, [debugMensagens, uid])

  const renderizarAltitude = useCallback(({ latitude, longitude, atitude }) => {
    // O iframe tem um bug: enviar valores decimais faz com que a atitude n seja renderizada o.o
    // De Alguma forma quando altera a mascara do input ele nao renderiza, e necessario "limpar" os valores anteriores
    enviarMensagem('renderAltitude', {
      latitude: 0,
      longitude: 0,
      altitude: 0
    })
    enviarMensagem('renderAltitude', {
      latitude: latitude,
      longitude: longitude,
      altitude: atitude
    })
  }, [enviarMensagem])

  const renderizarGeometriaProjeto = useCallback(({ geometriaProjeto }) => {    
    enviarMensagem('showPolygon', {
      polygonJSON: geometriaProjeto
    })    
  }, [enviarMensagem])

  useImperativeHandle(ref, () => ({
    renderizarAltitude,
    renderizarGeometriaProjeto
  }))

  return (
    <>
    <Iframe
      id={uid}
      url="/iframe_component_altitude_notation/index.html"
      display="initial"
      position="relative"
      scrolling="no"
      className={`mapa-atitude ${grande ? 'grande' : ''}`}
    />
    </>
  );
}

export default React.forwardRef(AppAltitudeComponent);
