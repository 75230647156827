import React, { useEffect, useRef, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { LegacySubForm, SubForm } from "../../../components/SubForm";
import { Field, useForm, FormSpy } from "react-final-form";
import { DropdownListField } from "../../../components/field/DropdownListField";
import { campoObrigatorioComMsgGenerica } from "../../../util/validadores";
import { TextInputField } from "../../../components/field/TextInputField";
import FileGridField from "../../../components/field/FileGridField/FileGridField";
import { aoEnviarSubForm } from "../../../util/mutadores";
import { getPropsPermissao, resolverExibidos } from "../../../components/SubForm/SubForm";
import { HiddenField } from "../../../components/field/HiddenField";
import { limparArquivos, retornarArquivoInterno, removerArquivoInterno } from "../../../reducers/fileReducers";
import { Card } from "react-bootstrap";
import { FORM_ERROR } from "final-form";
import { useDispatch } from "react-redux";
import { store } from "../../../store";
import { isEmpty, isObjectLike } from "lodash";

let tipo = undefined;
let limparListaExcluidos = false;
let showAlertaMudancaTipo = false;
let abertura = true;
let arquivosIniciais = [];

const campos = [
  { name: "idTipoIlustracao", type: "string", map: "idTipoIlustracao" },
  { name: "legenda", type: "string", map: "legenda" },
  { name: "idMaterialGeologico", type: "string", map: "idMaterialGeologico" },
  { name: "nomeGerado", type: "string", map: "nomeGerado" },
  { name: "nomeOriginal", type: "string", map: "nomeOriginal" },
]

let excluidos = [];

let idStore = null;

const IlstracoesConteudoMultimidiaPetro = ({
  tituloForm,
  aoAbrirSubForm,
  aoFecharSubForm,
  nome,
  // onSubmit,
  // elementos,
  valoresIniciais,
  permitirEdicao,
  visitas,
  visitaSelecionada,
  tiposIlustracao,
  materiaisGeologicos = [],
  extencoesIlustracao,
  tipoAtividadeIlustracao,
  nomeIlustracaoAmostra,
  nomeIlustracaoEstacao,
  legendaObrigatoria,
  permitirVisualizacao,
  showMaterialGeologicoField = false,
}) => {
  let refForm = null;
  // These refs are for remembering the previous values of each array that's a
  // dependency of the columns array. We only recalculate the columns array when
  // there's an actual change in the arrays.
  const tiposIlustracaoRef = useRef(tiposIlustracao);
  const materiaisGeologicosRef = useRef(materiaisGeologicos);
  const [colunasIlustracoes, setColunasIlustracoes] = useState(
    gerarColunasIlustracoes2(
      tiposIlustracao,
      materiaisGeologicos,
      { showMaterialGeologicoField }
    )
  );
  const [clickEditar, setClickEditar] = useState(false);
  const [clickVisualizar, setClickVisualizar] = useState(false);
  const [idMultimidia, setIdMultimidia] = useState("");
  const [nomeGeradoIlustracoes, setNomeGeradoIlustracoes] = useState("");

  function pegarIdDoRegistroEditado(e) {
    const id = e.toString()
    setClickEditar(true)
    setIdMultimidia(id)
  }

  const dispatch = useDispatch();

  useEffect(() => {
    // Only update the columns when at least one of the dependencies has really changed.
    // Otherwise, avoid changing the columns because that causes a table rerender.
    // (The *correct* thing to do would be including the names in the objects themselves (add extra fields)
    // and always recalculate those names when dependencies change, completely removing the need for
    // a cellsrenderer.)
    // Compare the dependencies by value.
    if (JSON.stringify(tiposIlustracaoRef.current) !== JSON.stringify(tiposIlustracao)) {
      setColunasIlustracoes(
        gerarColunasIlustracoes2(
          tiposIlustracao,
          materiaisGeologicos,
          { showMaterialGeologicoField }
        )
      );
      tiposIlustracaoRef.current = tiposIlustracao;
    }

    if (JSON.stringify(materiaisGeologicosRef.current) !== JSON.stringify(materiaisGeologicos)) {
      setColunasIlustracoes(
        gerarColunasIlustracoes2(
          tiposIlustracao,
          materiaisGeologicos,
          { showMaterialGeologicoField }
        )
      );
      materiaisGeologicosRef.current = materiaisGeologicos;
    }
  }, [
    tiposIlustracao,
    materiaisGeologicos,
    showMaterialGeologicoField,
  ]);

  const form = useForm();

  const getNomeExibicaoArquivoBy = (arquivo, numeroSequencial) => (filesListKey, hashOrId) => {

    const ilustracoes = form.getState()?.values?.ilustracoes || [];
    const arquivos = Object.values(
      store.getState()?.files[filesListKey] || {}
    ).filter((arq) => !arq?.deletado);
    const temApenasUmArquivo = arquivos.length === 1;

    const idsIlustracoesExcluidas = (ilustracoes.idsExcluidos || []).map((idExcluido) => (
      `${idExcluido}`
    ));

    if (clickVisualizar || clickEditar) {

      const ilustracaoExistenteAtual = (ilustracoes?.existentes || []).find((ilustracaoExistente) => (
        (ilustracaoExistente?.idsArquivosExistentes || [])?.some((idArquivoExistente) => (
          `${idArquivoExistente}` === hashOrId
        ))
      ));
      if (ilustracaoExistenteAtual) {
        const extencaoArquivoExistente = arquivo?.nome?.slice(
          arquivo?.nome?.search(/[A-z0-9]+$/g)
        );

        if (temApenasUmArquivo) {
          return `${ilustracaoExistenteAtual?.nomeGerado}.${extencaoArquivoExistente}`;
        }

        const nomeExibicaoArquivoExistente = `${ilustracaoExistenteAtual?.nomeGerado}_${numeroSequencial}.${extencaoArquivoExistente}`;
        return nomeExibicaoArquivoExistente;
      }

      const ilustracaoNovaAtual = (ilustracoes?.novos || []).find((ilustracaoNova) => (
        (ilustracaoNova?.hashesNovosArquivos || [])?.some((hashArquivoNovo) => (
          `${hashArquivoNovo}` === hashOrId
        ))
      ));

      if (ilustracaoNovaAtual) {
        const extencaoArquivoNovo = arquivo?.nome?.slice(
          arquivo?.nome?.search(/[A-z0-9]+$/g)
        );

        if (temApenasUmArquivo) {
          return `${ilustracaoNovaAtual?.nomeGerado}.${extencaoArquivoNovo}`;
        }

        const nomeExibicaoArquivoNovo = `${ilustracaoNovaAtual?.nomeGerado}_${numeroSequencial}.${extencaoArquivoNovo}`;

        return nomeExibicaoArquivoNovo;
      }

      const ilustracaoAtualByIdArquivoExistente = (ilustracoes?.existentes || []).find((ilustracaoExistente) => {
        const [primeiroArquivoEncontrado] = arquivos.filter((arquivoAtual) => (
          !!arquivoAtual?.id
        ));
        return (ilustracaoExistente?.idsArquivosExistentes || []).includes(
          primeiroArquivoEncontrado?.id
        )
      });

      if (ilustracaoAtualByIdArquivoExistente) {
        const extencaoArquivoNovo = arquivo?.nome?.slice(
          arquivo?.nome?.search(/[A-z0-9]+$/g)
        );

        if (temApenasUmArquivo) {
          return `${ilustracaoAtualByIdArquivoExistente?.nomeGerado}.${extencaoArquivoNovo}`;
        }

        const nomeExibicaoArquivoNovo = `${ilustracaoAtualByIdArquivoExistente?.nomeGerado}_${numeroSequencial}.${extencaoArquivoNovo}`;

        setNomeGeradoIlustracoes(ilustracaoAtualByIdArquivoExistente?.nomeGerado)
        return nomeExibicaoArquivoNovo;
      }

      const ilustracaoAtualByHashArquivoExistente = (ilustracoes?.novos || []).find((ilustracaoNova) => {
        const [primeiroArquivoEncontrado] = arquivos.filter((arquivoAtual) => (
          !!arquivoAtual?.retorno
        ));
        return (ilustracaoNova?.hashesNovosArquivos || []).includes(
          primeiroArquivoEncontrado?.retorno
        )
      });

      if (ilustracaoAtualByHashArquivoExistente) {
        const extencaoArquivoNovo = arquivo?.nome?.slice(
          arquivo?.nome?.search(/[A-z0-9]+$/g)
        );

        if (temApenasUmArquivo) {
          return `${ilustracaoAtualByHashArquivoExistente?.nomeGerado}.${extencaoArquivoNovo}`;
        }

        const nomeExibicaoArquivoNovo = `${ilustracaoAtualByHashArquivoExistente?.nomeGerado}_${numeroSequencial}.${extencaoArquivoNovo}`;
        return nomeExibicaoArquivoNovo;
      }


      const arquivosNovosInseridos = arquivos.filter(item => item.id === undefined);

      if (arquivosNovosInseridos.length) {

        const extencaoArquivoNovo = arquivo?.nome?.slice(
          arquivo?.nome?.search(/[A-z0-9]+$/g)
        );

        if (temApenasUmArquivo) {
          return `${nomeGeradoIlustracoes}.${extencaoArquivoNovo}`;
        }
        const nomeExibicaoArquivoNovo = `${nomeGeradoIlustracoes}_${numeroSequencial}.${extencaoArquivoNovo}`;
        return nomeExibicaoArquivoNovo

      }

      return null;
    }

    const ilustracoesExistentesValidas = (ilustracoes?.existentes || []).filter((ilustracaoExistente) => (
      !idsIlustracoesExcluidas.includes(ilustracaoExistente.id)
    ));
    const numeroIlustracoesExistentes = ilustracoesExistentesValidas.length;
    const numeroIlustracoesNovas = (ilustracoes?.novos || []).filter((ilustracaoNova) => (
      isObjectLike(ilustracaoNova) && !isEmpty(ilustracaoNova)
    )).length;

    const currentFileNumber = numeroIlustracoesExistentes + numeroIlustracoesNovas + 1;;

    let nomeGerado = '';

    if (tipoAtividadeIlustracao === "amostra" && nomeIlustracaoAmostra !== "" && nomeIlustracaoAmostra) {
      nomeGerado = `${nomeIlustracaoAmostra}-${currentFileNumber}`;
    } else if (tipoAtividadeIlustracao === "estacao" && nomeIlustracaoEstacao !== "" && nomeIlustracaoEstacao) {
      nomeGerado = `${nomeIlustracaoEstacao}-${currentFileNumber}`;
    } else {
      const [currentVisita] = (visitas || [])?.filter((visita) => (
        visita?.id?.toString() === visitaSelecionada?.toString()
      ));
      nomeGerado = `${currentVisita.nome}-${currentFileNumber}`;
    }

    const extencaoArquivo = arquivo.nome?.slice(
      arquivo.nome?.search(/[A-z0-9]+$/g)
    );

    if (temApenasUmArquivo) {
      return `${nomeGerado}.${extencaoArquivo}`;
    }

    const nomeExibicao = `${nomeGerado}_${numeroSequencial}.${extencaoArquivo}`;

    return nomeExibicao;
  };

  function preencherNomes(arquivos) {

    let nome = "";
    let totalRegistros = 0;
    let nomesArquivos = [];

    arquivos.map(arquivo => nomesArquivos.push(arquivo[1].nome))

    let nomeArquivosFormatado = nomesArquivos.toString()
      .split(',')
      .join(", ");

    if (nomeArquivosFormatado.length > 250) {
      nomeArquivosFormatado = nomeArquivosFormatado.slice(0, 247).concat('...');
    }

    if (tipoAtividadeIlustracao === "amostra") {
      nome = nomeIlustracaoAmostra;
    } else if (tipoAtividadeIlustracao === "amostra") {
      nome = nomeIlustracaoEstacao;
    } else {
      visitas?.forEach((visita) => {
        if (visita?.id?.toString() === visitaSelecionada?.toString()) {
          nome = `${visita.nome}-${totalRegistros + 1}`;
        }
      })
    }

    if (form.getState().values.ilustracoes) {
      totalRegistros = resolverExibidos(form.getState().values.ilustracoes).length
    }

    refForm.mutators.setValue("nomeGerado", `${nome}`)
    refForm.mutators.setValue('nomeOriginal', nomeArquivosFormatado);
  };

  const onSubmit = function onSubmit({ alteracoesForm }) {
    tipo = null;
    abertura = true;
    arquivosIniciais = [];

    let ilustracoes = form.getState().values.ilustracoes ?? [];

    let objetoFiltrado = []

    if ((ilustracoes?.existentes?.length ?? 0) > 0 || (ilustracoes?.length ?? 0) > 0) {
      objetoFiltrado = ilustracoes?.existentes.find(item => item.id === idMultimidia);
    }

    if (objetoFiltrado) {
      alteracoesForm["ilustracoes.editadosPorId.id_" + idMultimidia + ".nomeGerado"] = objetoFiltrado.nomeGerado;
    }

    form.batch(() => {
      for (const [key, val] of Object.entries(alteracoesForm)) {
        form.mutators.setValue(`${key}`, val);
      }
    });

    let tempIlustracoes = resolverExibidos(ilustracoes);

    for (let i = 0; i < tempIlustracoes.length; i++) {
      if (tipoAtividadeIlustracao === "amostra") {
        if (tempIlustracoes[i].nomeGerado === nomeIlustracaoAmostra && nomeIlustracaoAmostra !== "" && nomeIlustracaoAmostra) {
          tempIlustracoes[i].nomeGerado = `${nomeIlustracaoAmostra}-${i + 1}`
        }
      }

      else if (tipoAtividadeIlustracao === "estacao") {
        if (nomeIlustracaoEstacao !== "" && nomeIlustracaoEstacao) {
          tempIlustracoes[i].nomeGerado = `${nomeIlustracaoEstacao}-${i + 1}`
        }
      }
      else {
        visitas?.map(visita => {
          if (visita?.id?.toString() === visitaSelecionada?.toString()) {
            tempIlustracoes[i].nomeGerado = `${visita.nome}-${i + 1}`;
          }
        })
      }
    }
    valoresIniciais = {
      novos: [tempIlustracoes]
    }
    setClickEditar(false)
    setIdMultimidia("")
  }

  function validarConteudoMultimidia(valores) {
    if (valores.nomeOriginal === "" || !valores.nomeOriginal) {
      return {
        [FORM_ERROR]: 'Ao menos um arquivo deve ser inserido'
      }
    }
  }

  return (
    <Field name={nome} subscription={{ value: true }}>
      {({ input: { value: ilustracoes = {} } }) => {
        return (
          <SubForm
            tituloForm={tituloForm ?? null}
            nome={nome}
            onSubmit={onSubmit}
            elementos={ilustracoes} // elementos
            campos={campos}
            colunas={colunasIlustracoes}
            formSubscription={{}}
            onOpen={aoAbrirSubForm}
            onClose={aoFecharSubForm}
            valoresIniciais={valoresIniciais}
            {...getPropsPermissao(permitirEdicao)}
            alternarBotoesPai={true}
            validarVoltar={true}
            naoValidarCamposVoltar={[
              "nomeGerado", "nomeOriginal", "idMaterialGeologico"
            ]}
            permitirVisualizacao={permitirVisualizacao}
            validar={(valores) => validarConteudoMultimidia(valores)}
            onBtnEditClick={(event) => { pegarIdDoRegistroEditado(event.value) }}
            aoConfirmarVoltar={(event) => {
              if (arquivosIniciais && arquivosIniciais.length) {
                arquivosIniciais.forEach((e) => {
                  dispatch(retornarArquivoInterno({ key: e, store: `arquivosIlustracoes_${idStore}` }));
                  limparListaExcluidos = true;
                });
                arquivosIniciais = [];
              }
              setClickEditar(false);
              setClickVisualizar(false);
              limparListaExcluidos = false;
              tipo = null;
              abertura = true;
            }}
            onBtnViewClick={(event) => { setClickVisualizar(true) }}
            renderForm={({ prefixoNome, formProps }) => {
              refForm = formProps.form;
              return (
                <>
                  <FormSpy subscription={{ values: true }}>
                    {({ values }) => {

                      if (abertura) {
                        if (values.id < 0 && values.hashesNovosArquivos)
                          arquivosIniciais = values.hashesNovosArquivos;

                        else if (values.id > 0)
                          arquivosIniciais = values.idsArquivosExistentes;

                        abertura = false;

                      }

                      if (values && values.id)
                        idStore = values.id;

                      let podeAlterarTipo = true;
                      let exibirFileInput = true;

                      if (values && values.id && values.id >= 1)
                        podeAlterarTipo = false;

                      let filtroArquivos = undefined;

                      if (!values || !values.idTipoIlustracao) {
                        filtroArquivos = undefined;
                        exibirFileInput = false;
                      }
                      else {
                        filtroArquivos = extencoesIlustracao ? extencoesIlustracao.filter((extencao) => extencao.idTipoIlustracao === values.idTipoIlustracao)
                          .map(e => '.' + e.nome.toLowerCase()) : [];
                      }

                      if (podeAlterarTipo && values.nomeOriginal && permitirEdicao != false && !clickVisualizar)
                        showAlertaMudancaTipo = true;
                      else
                        showAlertaMudancaTipo = false;

                      if (!tipo)
                        tipo = values.idTipoIlustracao;

                      else if (tipo != values.idTipoIlustracao) {

                        if ((tipo === 6 || tipo === 3 || values.idTipoIlustracao === 6 || values.idTipoIlustracao === 3) && values.nomeOriginal) {

                          const files = store.getState()?.files;
                          const ilustracoesObjeto = files[`arquivosIlustracoes_${values.id}`];

                          if (ilustracoesObjeto) {
                            for (let prop in ilustracoesObjeto) {
                              excluidos.push(ilustracoesObjeto[prop].retorno);
                              dispatch(
                                removerArquivoInterno({ key: ilustracoesObjeto[prop].retorno, store: `arquivosIlustracoes_${values.id}` }));
                            }
                          }
                        }

                        tipo = values.idTipoIlustracao;
                      }

                      return <>
                        <Field
                          name={`${prefixoNome}nomeGerado`}
                          component={HiddenField}
                        />
                        <Field
                          name={`${prefixoNome}nomeOriginal`}
                          component={HiddenField}
                        />

                        <Row>
                          <Col md={12}>
                            <Card.Title>{tituloForm}</Card.Title>
                          </Col>
                        </Row>

                        <Row className="my-3">
                          <Col md={4}>
                            <Field name={`${prefixoNome}idTipoIlustracao`} subscription={{ value: true }}>
                              {({ input: { value: idTipoIlustracao } }) => {

                                return <Field
                                  component={DropdownListField}
                                  name={`${prefixoNome}idTipoIlustracao`}
                                  label="Tipo do conteúdo multimidia"
                                  dica={`Selecione o tipo do conteúdo multimídia representativo da ${tipoAtividadeIlustracao === 'estacao' ? 'estação' : 'atividade'} que deseja salvar`}
                                  elementos={tiposIlustracao}
                                  required
                                  disabled={!podeAlterarTipo ? true : clickVisualizar ?? !permitirEdicao}
                                  validate={campoObrigatorioComMsgGenerica("Tipo do conteúdo multimidia")}
                                />
                              }}
                            </Field>
                          </Col>
                          <Col md={showMaterialGeologicoField ? 5 : 8} className='label-input-text'>
                            <Field
                              component={TextInputField}
                              name={`${prefixoNome}legenda`}
                              label="Legenda"
                              dica="Descreva de forma sucinta uma legenda referente ao(s) arquivo(s) multimídia"
                              disabled={clickVisualizar ?? !permitirEdicao}
                              maxLength={250}
                              required={legendaObrigatoria ? true : false}
                              validate={legendaObrigatoria ? campoObrigatorioComMsgGenerica("Legenda") : undefined}
                            />
                          </Col>
                          {showMaterialGeologicoField && (
                            <Col md={3}>
                              <Field
                                component={DropdownListField}
                                name="idMaterialGeologico"
                                label="Material geológico"
                                dica="Selecione o material geológico cadastrado no afloramento para associação ao conteúdo multimídia"
                                elementos={materiaisGeologicos}
                                disabled={clickVisualizar ?? !permitirEdicao}
                              />
                            </Col>
                          )}
                        </Row>
                        <Row>
                          <Col md={12}>
                            <Field name={`${prefixoNome}id`}>
                              {({ input: { value } }) => {

                                if (exibirFileInput) {
                                  return (
                                    <>
                                      <FileGridField
                                        preencherNomes={preencherNomes}
                                        campoNovosArquivos={`${prefixoNome}hashesNovosArquivos`}
                                        campoArquivosExistentes={`${prefixoNome}idsArquivosExistentes`}
                                        chaveListaArquivos={`${prefixoNome}arquivosIlustracoes_${value}`}
                                        clickVisualizar={clickVisualizar}
                                        bloquearTipos={true}
                                        tiposPermitidos={filtroArquivos}
                                        disabled={filtroArquivos ? !permitirEdicao : true}
                                        nomeExibicaoArquivoGetter={getNomeExibicaoArquivoBy}
                                        limparListaExcluidos={limparListaExcluidos}
                                        excluidos={(list) => {
                                          if (list && list.length) {
                                            excluidos = list;
                                          }
                                        }}
                                      />
                                      {showAlertaMudancaTipo ? <p className="text-danger"> Ao alterar o tipo de conteúdo multimídia os arquivos serão perdidos </p> : <></>}
                                    </>
                                  )
                                }
                                else
                                  return <></>
                              }}
                            </Field>
                          </Col>
                        </Row>
                        <div className="tipo_ilustracao"></div>
                      </>
                    }}
                  </FormSpy>
                </>
              )
            }}
          />
        )
      }}
    </Field>
  );
}

function gerarColunasIlustracoes2(tiposIlustracao, materiaisGeologicos = [], options = {}) {
  return [
    {
      text: 'Tipo do conteúdo', datafield: 'idTipoIlustracao', width: "20%",
      cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
        const nome = tiposIlustracao.find(t => parseInt(t.id) === parseInt(value))?.nome;
        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
      }
    },
    { text: "Legenda", datafield: "legenda", width: "20%" },
    {
      text: "Material geológico",
      datafield: "idMaterialGeologico",
      width: "20%",
      hidden: !options?.showMaterialGeologicoField,
      cellsrenderer: (_row, _columnfield, value, _defaulthtml, _columnproperties, _rowdata) => {
        const nome = materiaisGeologicos?.find(t => parseInt(t.id) === parseInt(value))?.nome;
        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome || ''}</div>`;
      }
    },
    { text: "Nome gerado", datafield: "nomeGerado", width: "20%" },
    { text: "Nomes originais dos arquivos", datafield: "nomeOriginal" },
  ];
}

export default React.memo(IlstracoesConteudoMultimidiaPetro);
