import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Field, useForm } from "react-final-form";
import { aoEnviarSubForm } from "../../../../../util/mutadores";
import Card from "../../../../../components/card";
import { SubForm } from "../../../../../components/SubForm";
import { DropdownListField } from "../../../../../components/field/DropdownListField";
import { getPropsPermissao, resolverExibidos } from "../../../../../components/SubForm/SubForm";
import { campoObrigatorioComMsgGenerica, comporValidadores } from "../../../../../util/validadores";
import { NumberInputField } from "../../../../../components/field/NumberInputField";
import Hint from "../../../../../components/hint/Hint";
import { validaGranulometriaMaxima, validaGranulometriaMinima } from "../../../utils/gerais";
import { FORM_ERROR } from "final-form";

const FasesMinerais = ({
    permitirEdicao,
    bbMinerais,
    bbUnidadeComprimento,
    bbMorfologia,
    somaTotalPercentuais,
    disabled,
}) => {

    const valorFormulario = useForm()
    const onSubmit = aoEnviarSubForm(valorFormulario);
    
    const colunasFasesMinerais = [
        {
            text: 'Mineral',
            datafield: 'idMineral',
            width: '21%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                const objetoFiltrado = bbMinerais.find(elemento => elemento.id === value);
                const valor = objetoFiltrado ? objetoFiltrado.nome : '';
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valor}</div>`;
            },
        },
        {
            text: '% na fração',
            datafield: 'porcentagemFracao',
            width: '19%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {

                let valor = "";
                if (value === 0 || value === "0") {
                    valor = "Acessório";
                } else {
                    valor = value;
                }
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valor}</div>`;
            }
        },
        {
            text: 'Granulometria (Mínima)',
            datafield: 'granulometriaMinima',
            width: '19%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                const numeroTamanhoMinimo = bbUnidadeComprimento.find(elemento => elemento.id === rowdata?.idUnidadeMedidaGranulometria);
                const valorMinimo = value + (numeroTamanhoMinimo?.sigla ?? "");
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valorMinimo}</div>`;
            }
        },
        {
            text: 'Granulometria (Máxima)',
            datafield: 'granulometriaMaxima',
            width: '19%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                const numeroTamanhoMaximo = bbUnidadeComprimento.find(elemento => elemento.id === rowdata?.idUnidadeMedidaGranulometria);
                const valormaximo = value + (numeroTamanhoMaximo?.sigla ?? "");
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valormaximo}</div>`;
            }
        },
        {
            text: 'Forma',
            datafield: 'idForma',
            width: '19%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                const objetoFiltrado = bbMorfologia.find(elemento => elemento.id === value);
                const valor = objetoFiltrado ? objetoFiltrado.nome : '';
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valor}</div>`;
            },
        }
    ]
    const camposFasesMinerais = [
        { name: "idMineral", type: "number", map: "idMineral" },
        { name: "porcentagemFracao", type: "number", map: "porcentagemFracao" },
        { name: "idUnidadeMedidaGranulometria", type: "number", map: "idUnidadeMedidaGranulometria" },
        { name: "granulometriaMinima", type: "number", map: "granulometriaMinima" },
        { name: "granulometriaMaxima", type: "number", map: "granulometriaMaxima" },
        { name: "idForma", type: "number", map: "idForma" },

    ];

    const validarFasesMinerais = (valores) => {

        const fasesMinerais = valorFormulario.getState().values?.fasesMinerais;
        const itens = fasesMinerais ? resolverExibidos(fasesMinerais) : [];
        const idParaSubstituir = valores.id;
        const indiceParaSubstituir = itens.findIndex(item => item.id === idParaSubstituir);

        if (indiceParaSubstituir !== -1) {
            itens.splice(indiceParaSubstituir, 1, valores);
        } else {
            itens.push(valores);
        }

        let totalPorcentagem = 0;

        if (itens.length > 0) {
            totalPorcentagem = itens.reduce((total, objeto) => {
                return total + Number(objeto.porcentagemFracao);
            }, 0);
        }

        if (totalPorcentagem > 100) {
            return { [FORM_ERROR]: 'A soma da % na fração não deve ultrapassar 100%.' };
        }

        return null
    }



    return (
        <>
            <div style={{ marginTop: '2.5rem' }}>
                <Card className="mt-3">
                    <Card.Body>
                        <Card.Title>Fases Minerais</Card.Title>
                        {(somaTotalPercentuais != null && somaTotalPercentuais < 100) && (
                            <span className="erro-campo" style={{ color: 'blue', fontSize: '10px' }}>
                                A soma total dos percentuais das Fases Minerais é de: {somaTotalPercentuais}%, mas deve ser 100%.
                            </span>
                        )}
                        <Field name={`fasesMinerais`} subscription={{ value: true }}>
                            {({ input: { value: fasesMinerais = [] } }) => {

                                return (
                                    <SubForm
                                        nome={`fasesMinerais`}
                                        onSubmit={onSubmit}
                                        exibirTabelaSemRegistro={true}
                                        modoSubFormComGrid
                                        colunas={colunasFasesMinerais}
                                        elementos={fasesMinerais}
                                        campos={camposFasesMinerais}
                                        validar={(valores) => validarFasesMinerais(valores)}
                                        {...getPropsPermissao(!disabled)}
                                        renderForm={({ formProps: { form } }) => {
                                            const hasUnidadeMedida = !!form.getState().values?.idUnidadeMedidaGranulometria;
                                            const hasMinima = !!form.getState().values?.granulometriaMinima;
                                            const hasMaxima = !!form.getState().values?.granulometriaMaxima;

                                            const camposObrigatorios = hasUnidadeMedida || hasMinima || hasMaxima

                                            var valorAtual = form.getState().values?.idMineral
                                            var fasesMinerais = valorFormulario?.getState().values?.fasesMinerais
                                            var fasesMineraisTela = []
                                            if (fasesMinerais) {
                                                fasesMineraisTela = resolverExibidos(fasesMinerais)
                                            
                                            }
                                            var bbMineraisFiltrados = bbMinerais.filter(value => {
                                                return !fasesMineraisTela.some(item => item.idMineral === value.id && valorAtual !== value.id);
                                            });
                                            const mineral = form.getState().values?.idMineral;
                                            const hasMineral = !!mineral;

                                            return (
                                                <>
                                                    <Row className="my-3">
                                                        <Col md={4}>
                                                            <Field
                                                                component={DropdownListField}
                                                                name={"idMineral"}
                                                                label="Mineral"
                                                                dica="Se possível selecione um ou mais  minerais que compõem a matriz."
                                                                elementos={bbMineraisFiltrados}
                                                                displayMember={'nome'}
                                                                disabled={disabled}
                                                                required
                                                                validate={campoObrigatorioComMsgGenerica("Mineral")}    
                                                            />
                                                        </Col>
                                                        <Col md={4}>
                                                            {hasMineral && (
                                                                <Field
                                                                    name="porcentagemFracao"
                                                                    component={NumberInputField}
                                                                    label="% na fração"
                                                                    dica="Estime em porcentagem a proporção deste mineral na matriz."
                                                                    permitirNegativos={false}
                                                                    casasInteiros={3}
                                                                    casasDecimais={0}
                                                                    valorMaximo={100}
                                                                    disabled={disabled}
                                                                    required={hasMineral}
                                                                    validate={campoObrigatorioComMsgGenerica("% na fração")}
                                                                />
                                                            )}
                                                            {!hasMineral && (
                                                                <Field
                                                                    name="porcentagemFracao"
                                                                    component={NumberInputField}
                                                                    label="% na fração"
                                                                    dica="Estime em porcentagem a proporção deste mineral na matriz. "
                                                                    permitirNegativos={false}
                                                                    casasInteiros={3}
                                                                    casasDecimais={0}
                                                                    valorMaximo={100}
                                                                    disabled={disabled}
                                                                />
                                                            )}

                                                        </Col>

                                                    </Row>
                                                    <Row className="my-3">

                                                        {camposObrigatorios && (
                                                            <Col md={6}>
                                                                <fieldset className="border p-2">
                                                                    <legend className="w-auto small" style={{ marginBottom: 8, fontSize: '16px' }}>
                                                                        Granulometria
                                                                        <Hint textoAjuda={'granulometria'} />
                                                                    </legend>
                                                                    <Row>
                                                                        <Col md={4}>
                                                                            <Field
                                                                                component={DropdownListField}
                                                                                name={"idUnidadeMedidaGranulometria"}
                                                                                label="Unidade de medida"
                                                                                dica="Selecione a unidade de medida observada."
                                                                                elementos={bbUnidadeComprimento}
                                                                                displayMember={'nome'}
                                                                                disabled={disabled}
                                                                                required
                                                                                validate={campoObrigatorioComMsgGenerica("Unidade de medida")}
                                                                            />
                                                                        </Col>
                                                                        <Col md={4}>
                                                                            <Field
                                                                                name="granulometriaMinima"
                                                                                component={NumberInputField}
                                                                                label="Mínima"
                                                                                dica=" Dimensão dos menores fragmentos observados."
                                                                                permitirNegativos={false}
                                                                                casasInteiros={3}
                                                                                casasDecimais={3}
                                                                                disabled={disabled}
                                                                                validate={comporValidadores(
                                                                                    campoObrigatorioComMsgGenerica("Mínima"),
                                                                                    validaGranulometriaMinima
                                                                                )}
                                                                            />
                                                                        </Col>
                                                                        <Col md={4}>
                                                                            <Field
                                                                                name="granulometriaMaxima"
                                                                                component={NumberInputField}
                                                                                label="Máxima"
                                                                                dica="Dimensão dos maiores fragmentos observados."
                                                                                permitirNegativos={false}
                                                                                casasInteiros={3}
                                                                                casasDecimais={3}
                                                                                disabled={disabled}
                                                                                required
                                                                                validate={comporValidadores(
                                                                                    campoObrigatorioComMsgGenerica("Máxima"),
                                                                                    validaGranulometriaMaxima
                                                                                )}
                                                                            />
                                                                        </Col>
                                                                    </Row>

                                                                </fieldset>
                                                            </Col>
                                                        )}
                                                        {!camposObrigatorios && (
                                                            <Col md={6}>
                                                                <fieldset className="border p-2">
                                                                    <legend className="w-auto small" style={{ marginBottom: 8, fontSize: '16px' }}>
                                                                        Granulometria
                                                                        <Hint textoAjuda={'granulometria'} />
                                                                    </legend>
                                                                    <Row>
                                                                        <Col md={4}>
                                                                            <Field
                                                                                component={DropdownListField}
                                                                                name={"idUnidadeMedidaGranulometria"}
                                                                                label="Unidade de medida"
                                                                                dica=" Selecione a unidade de medida utilizada."
                                                                                elementos={bbUnidadeComprimento}
                                                                                displayMember={'nome'}
                                                                                disabled={disabled}
                                                                            />
                                                                        </Col>
                                                                        <Col md={4}>
                                                                            <Field
                                                                                name="granulometriaMinima"
                                                                                component={NumberInputField}
                                                                                label="Mínima"
                                                                                dica=" Dimensão dos menores fragmentos observados."
                                                                                permitirNegativos={false}
                                                                                casasInteiros={3}
                                                                                casasDecimais={3}
                                                                                disabled={disabled}
                                                                            />
                                                                        </Col>
                                                                        <Col md={4}>
                                                                            <Field
                                                                                name="granulometriaMaxima"
                                                                                component={NumberInputField}
                                                                                label="Máxima"
                                                                                dica="Dimensão dos maiores fragmentos observados."
                                                                                permitirNegativos={false}
                                                                                casasInteiros={3}
                                                                                casasDecimais={3}
                                                                                disabled={disabled}
                                                                            />
                                                                        </Col>
                                                                    </Row>

                                                                </fieldset>
                                                            </Col>
                                                        )}
                                                        <Col md={4}>
                                                            <Field
                                                                component={DropdownListField}
                                                                name={"idForma"}
                                                                label="Forma"
                                                                dica="Se possível selecione a forma do ou dos minerais que compõem a matriz."
                                                                elementos={bbMorfologia}
                                                                displayMember={'nome'}
                                                                disabled={disabled}

                                                            />
                                                        </Col>
                                                    </Row>
                                                </>
                                            )
                                        }
                                        }
                                    />
                                )
                            }}
                        </Field>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
};

export default FasesMinerais;