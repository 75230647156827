import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";

import "./FileCard.css";
import { connect } from "react-redux";
import formatarBytes from "../../../util/formatarBytes";
import { mapearTipo } from "../../../util/Arquivo";

const iconesCustomizados = {
  file: "fa-file",
  audio: 'fa-file-audio',
  video: 'fa-file-video',
  zip: "fa-file-archive",
};

// ***************************************************************************

function Spinner({ carregando }) {
  return (
    carregando && (
      <div className="file-grid__spinner">
        <div />
        <div />
        <div />
        <div />
      </div>
    )
  );
}

function PreviewImagem({ prefixUrl = "", caminho, carregando, onClickImagem }) {
  const className = `file-grid__preview ${
    carregando ? "file-grid__preview--uploading" : ""
  }`;
  return (
    <div className={className} onClick={onClickImagem}>
      {!caminho ? null : (
        <img
          alt=""
          className="file-grid__preview"
          style={{ margin: "0 auto", display: "block", maxWidth: "100%" }}
          src={`${prefixUrl}${caminho}`}
        />
      )}
      <Spinner carregando={carregando} />
    </div>
  );
}

function PreviewIcone({ tipo, carregando, onClick }) {
  const classeContainer = `file-grid__preview--${tipo}`;
  const classeIcone = iconesCustomizados[tipo] || `fa-file-${tipo}`;
  const className =
    `file-grid__preview file-grid__preview--has-icon ${classeContainer}` +
    `${carregando ? " file-grid__preview--uploading" : ""}`;

  return (
    <div className={className} onClick={onClick}>
      <i className={`file-grid__preview-icon fas ${classeIcone}`} />
      <Spinner carregando={carregando} />
    </div>
  );
}

function ErroPreview({ onClick, erro }) {
  return (
    !!erro && (
      <div
        className="file-grid__preview file-grid__preview--has-icon file-grid__preview--has-error"
        onClick={onClick}
      >
        <i className={`file-grid__preview-icon fas fa-exclamation`} />
      </div>
    )
  );
}

function FileCard({
  nomeGrid,
  prefixUrl = process.env.REACT_APP_API_URL,
  arquivo,
  chave,
  mostrarBotaoExclusao,
  mostrarBotaoDownload,
  onClickImagem = () => {},
  onExcluir = () => {},
  nomeExibicaoArquivoGetter = () => () => null,
}) {
  const {
    nome,
    nomeExibicao,
    caminho,
    tamanho,
    tipo,
    carregando = false,
    baixavel = true,
    erro,
  } = arquivo;

  const nomeExibicaoArquivoNovo = useMemo(() => (
    nomeExibicaoArquivoGetter(
      nomeGrid,
      chave,
    )
  ), [
    chave,
    nomeGrid,
    nomeExibicaoArquivoGetter,
  ]);

  const download = useCallback(
    (event) => {
      let nomeGerado = nomeExibicaoArquivoNovo || nomeExibicao || nome;
      event.stopPropagation();
      const link = document.createElement("a");
      link.href = `${prefixUrl}${caminho}/${nomeGerado}`;
      link.click();
    },
    [caminho, prefixUrl]
  );

  const eventoOnClickImagem = useCallback(
    (event) => {
      event.stopPropagation();
      if (onClickImagem) onClickImagem(arquivo);
    },
    [onClickImagem, arquivo]
  );

  const eventoOnClickGenerico = useCallback((event) => {
    event.stopPropagation();
  }, []);

  const handleKeyboardExcluir = useCallback(
    (event) => {
      if (event.charCode === 13 || event.charCode === 32) {
        onExcluir(chave);
      }
    },
    [chave, onExcluir]
  );

  const handleExcluir = useCallback(() => {
    onExcluir(chave);
  }, [chave, onExcluir]);

  const handleKeyboardDownload = useCallback(
    (event) => {
      if (event.charCode === 13 || event.charCode === 32) {
        download(event);
      }
    },
    [download]
  );

  return (
    <>
      {
        mapearTipo(tipo) === null ? <></> :
        <div className="file-grid__file">
          {erro ? (
            <ErroPreview onClick={eventoOnClickGenerico} erro={erro} />
              ) : "imagem" === mapearTipo(tipo) ? (
            <PreviewImagem
              prefixUrl={prefixUrl}
              caminho={caminho}
              carregando={carregando}
              onClickImagem={eventoOnClickImagem}
            />
            ) : (
            <PreviewIcone
              tipo={mapearTipo(tipo)}
              carregando={carregando}
              onClick={download}
            />
          )}
          <div
            className="file-grid__infoAndActions"
            onClick={eventoOnClickGenerico}
          >
          <div className="file-grid__info">
            {!erro && (
              <>
                <p className="file-grid__text file-grid__file-name">{nomeExibicaoArquivoNovo || nomeExibicao || nome}</p>
                <p className="file-grid__text file-grid__file-size">
                  {formatarBytes(tamanho, 0)}
                </p>
              </>
            )}
            {erro && (
              <p className="file-grid__text file-grid__file-error">{erro}</p>
            )}
          </div>
          <div className="file-grid__actions">
            {/* adicionar tooltips aqui??? */}
            {mostrarBotaoExclusao && (
              <span
                className="file-grid__icon file-grid__icon--exclude"
                tabIndex={0}
                onKeyPress={handleKeyboardExcluir}
                onClick={handleExcluir}
              />
            )}
            {mostrarBotaoDownload && baixavel && (
                <span
                  className="file-grid__icon file-grid__icon--download"
                  onKeyPress={handleKeyboardDownload}
                  onClick={download}
                >
                  <i className="fas fa-file-download" />
                </span>
              )}
            </div>
          </div>
        </div>
    }
  </>
  );
}

FileCard.propTypes = {
  nomeGrid: PropTypes.string.isRequired,
  chave: PropTypes.string.isRequired,

  nome: PropTypes.string,
  hashArquivo: PropTypes.string,

  mostrarBotaoExclusao: PropTypes.bool,
  mostrarBotaoDownload: PropTypes.bool,
  onExcluir: PropTypes.func,
  onClickImagem: PropTypes.func,

  nomeExibicaoArquivoGetter: PropTypes.func,

  // Redux
  arquivo: PropTypes.object,
};

FileCard.defaultProps = {
  mostrarBotaoExclusao: true,
  mostrarBotaoDownload: true,
};

export default connect(({ files }, { nomeGrid, chave, arquivo }) => {
  return {
    arquivo: arquivo || files[nomeGrid][chave],
  };
})(FileCard);
