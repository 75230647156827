import React, { Component } from "react";
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Field } from 'react-final-form';
import { MODO_CADASTRO_BASE_PROJETOS, MODO_CADASTRO_DETALHADO, MODO_CADASTRO_SIMPLIFICADO } from "../../../util/constantes";
import { resolverMultiRequestRecmin } from "../../../util/ResolverRequestRecmin";
import { SubForm } from "../../../components/SubForm";
import { getPropsPermissao } from "../../../components/SubForm/SubForm";
import { campoObrigatorioComMsgGenerica, validarSe, validaçãoBaseProjetosFonteReferencia } from "../../../util/validadores";
import { HiddenField } from "../../../components/field/HiddenField";
import { FileButton } from "../../../components/Jqx/Button";
import { RadioButtonSetField } from "../../../components/field/RadioButtonSetField";
import { TextInputField } from "../../../components/field/TextInputField";
import { DropdownListField } from "../../../components/field/DropdownListField";
import { NumberInputField } from "../../../components/field/NumberInputField";


class FonteReferencia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projeto: undefined,
      fonteReferenciaTipo: [],
      modoCadastroFonteReferencia: [
        MODO_CADASTRO_SIMPLIFICADO,
        MODO_CADASTRO_DETALHADO,
        MODO_CADASTRO_BASE_PROJETOS,
      ],
      projetos: [],
      link: '',
    }

    this.usarProjetoDefault = true;

    /**
     * Esta é uma cópia dos projetos no estado na forma de uma referência. Isto serve para
     * que esta variável esteja sempre acessível de dentro do callback da coluna
     * que extrai o nome do projeto a partir da lista de projetos. Os projetos
     * existem em primeiro lugar no estado, para que mudanças lá possam refletir
     * neste componente, e esta variável é somente uma cópia que pode ser capturada
     * e mesmo assim atualizada.
     */
    this.projetosCopy = {
      value: [],
    };

    this.state.projeto = this.setProjetoInitialValue();
    this.colunasFonteReferenciaAtividadeExploracao = [
      {
        text: "Referência", datafield: "referencia", width: '94%',
        cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          if (rowdata.modoCadastro === "SIMPLIFICADO") {
            if (rowdata?.link?.toString().includes("http://") || rowdata?.link?.toString().includes("https://")) {
              return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;"><a href="${rowdata.link}" target="_blank">${rowdata.descricao}</a></div>`;
            } else {
              return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;"><a href="http://${rowdata.link}" target="_blank">${rowdata.descricao}</a></div>`;
            };
          }

          if (rowdata.modoCadastro === "DETALHADO") {
            let tempLink = ""
            if (rowdata.link && rowdata.link !== "") {
              tempLink = rowdata.link;
              if (!tempLink?.toString().includes("http://") && !tempLink?.toString().includes("https://")) {
                tempLink = `http://${tempLink}`;
              }
            }

            const autores = rowdata.autores.split(',');
            if (autores.length === 1) {
              if (tempLink !== "") {
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">
                <a href="${tempLink}" target="_blank">
                ${autores[0].split(" ").pop()}. (${rowdata.ano}). ${rowdata.titulo}.
                </a>
                </div>`;
              } else {
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">
                ${autores[0].split(" ").pop()}. (${rowdata.ano}). ${rowdata.titulo}.
                </div>`;
              }

            };
            if (autores.length === 2) {

              if (tempLink !== "") {
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">
                <a href="${tempLink}" target="_blank">
                ${autores[0].split(" ").pop()} e ${autores[1].split(" ").pop()}. (${rowdata.ano}). ${rowdata.titulo}.
                </a>
                </div>`;
              } else {
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${autores[0].split(" ").pop()} e ${autores[1].split(" ").pop()}. (${rowdata.ano}). ${rowdata.titulo}.</div>`;
              }
            };
            if (autores.length >= 3) {

              if (tempLink !== "") {
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">
                <a href="${tempLink}" target="_blank">
                ${autores[0].split(" ").pop()} et al. (${rowdata.ano}). ${rowdata.titulo}.
                </a>
                </div>`;
              } else {
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${autores[0].split(" ").pop()} et al. (${rowdata.ano}). ${rowdata.titulo}.`;
              }
            }
            return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${rowdata.titulo}</div>`;
          };
          if (rowdata.modoCadastro === "BASE_PROJETOS" &&
            (rowdata.idProjeto === "" || rowdata.idProjeto === undefined || rowdata.idProjeto === null)
            && rowdata.link
          ) {
            let tempLink = ""
            if (rowdata.link && rowdata.link !== "") {
              tempLink = rowdata.link;
              if (!tempLink?.toString().includes("http://") && !tempLink?.toString().includes("https://")) {
                tempLink = `http://${tempLink}`;
              }
            }
            if (tempLink !== "") {
              return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">
              <a href="${tempLink}" target="_blank">
              ${tempLink}
              </a>
              </div>`;

            }

          } else
            if (rowdata.modoCadastro === "BASE_PROJETOS" && rowdata.idProjeto) {
              let projetos = this.projetosCopy.value;
              let nome = "";
              projetos?.map(projeto => {
                if (projeto.id?.toString() === rowdata.idProjeto?.toString()) {
                  nome = projeto.nome;
                }
              });
              return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
            };
        },
      },
    ];

    this.datafieldsFonteReferenciaAtividadeExploracao = [
      { name: "modoCadastro", type: "string", map: "modoCadastro" },
      { name: "autores", type: "string", map: "autores" },
      { name: "ano", type: "string", map: "ano" },
      { name: "volume", type: "string", map: "volume" },
      { name: "titulo", type: "string", map: "titulo" },
      { name: "paginaInicial", type: "string", map: "paginaInicial" },
      { name: "link", type: "string", map: "link" },
      { name: "descricao", type: "string", map: "descricao" },
      { name: "idProjeto", type: "string", map: "idProjeto" },
      { name: "origem", type: "string", map: "origem" },
    ];
  }
x
  handleFiles = async (files, form) => {

    let arquivoJson = {
      autores: [],
      ano: "",
      volume: "",
      titulo: "",
      periodico: "",
      paginaInicial: "",
      paginaFinal: "",
      link: ""
    };

    let possuiLinkDOI = false;
    let possuiLinkUR = false;
    let possuiAutorAU = false;

    let reader = new FileReader();
    await reader.readAsText(files[0]);
    reader.onloadend = () => {
      const arrayOfLines = reader.result.match(/[^\r\n]+/g);
      for (let i = 0; i < arrayOfLines.length; i++) {
        console.log(arrayOfLines[i].includes("AU  - "));
        if (arrayOfLines[i].includes("AU  - ")) {
          possuiAutorAU = true;
          arquivoJson.autores = [];
          arquivoJson.autores.push(arrayOfLines[i].replace(/AU {2}- /, "").split(",").reverse().join(" "));
        }

        if ((arrayOfLines[i].includes("A1  - ") ||
          arrayOfLines[i].includes("A2  - ") ||
          arrayOfLines[i].includes("A3  - ") ||
          arrayOfLines[i].includes("A4  - ")) && !possuiAutorAU) {
          arquivoJson.autores.push(arrayOfLines[i].replace(/A[1-4] {2}- /, "").split(",").reverse().join(" "));
        }
        if (arrayOfLines[i].includes("PY  - ")) {
          arquivoJson.ano = arrayOfLines[i].split("PY  - ").pop();
        } else
          if (arrayOfLines[i].includes("Y1  - ")) {
            arquivoJson.ano = arrayOfLines[i].split("Y1  - ").pop();
          };
        if (arrayOfLines[i].includes("VL  - ")) {
          arquivoJson.volume = arrayOfLines[i].split("VL  - ").pop();
        };
        if (arrayOfLines[i].includes("T1  - ")) {
          arquivoJson.titulo = arrayOfLines[i].split("T1  - ").pop();
        };
        if (arrayOfLines[i].includes("JO  - ")) {
          arquivoJson.periodico = arrayOfLines[i].split("JO  - ").pop();
        };
        if (arrayOfLines[i].includes("SP  - ")) {
          arquivoJson.paginaInicial = arrayOfLines[i].split("SP  - ").pop();
        };
        if (arrayOfLines[i].includes("EP  - ")) {
          arquivoJson.paginaFinal = arrayOfLines[i].split("EP  - ").pop();
        };
        if (arrayOfLines[i].includes("DO  - ")) {
          possuiLinkDOI = true;
          arquivoJson.link = arrayOfLines[i].split("DO  - ").pop();
        }
        if (arrayOfLines[i].includes("UR  - ") && !possuiLinkDOI) {
          possuiLinkUR = true;
          arquivoJson.link = arrayOfLines[i].split("UR  - ").pop();
        }
        if (arrayOfLines[i].includes("L1  - ") && !possuiLinkDOI && !possuiLinkUR) {
          arquivoJson.link = arrayOfLines[i].split("L1  - ").pop();
        }
      };

      form?.mutators?.setValue(`autores`,
        arquivoJson.autores?.toString().split(",").join(", "));
      form?.mutators?.setValue(`ano`,
        arquivoJson.ano);
      form?.mutators?.setValue(`volume`,
        arquivoJson.volume);
      form?.mutators?.setValue(`titulo`,
        arquivoJson.titulo);

      if(typeof arquivoJson.periodico === 'string'){
        form?.mutators?.setValue(`periodico`,
        arquivoJson.periodico); 
      }

      form?.mutators?.setValue(`paginaInicial`,
        arquivoJson.paginaInicial);
      form?.mutators?.setValue(`paginaFinal`,
        arquivoJson.paginaFinal);
      form?.mutators?.setValue(`link`,
        arquivoJson.link);
      form?.mutators?.setValue(`modoCadastro`,
        'DETALHADO');

    };

    reader.onerror = () => {
      console.log(reader.error);
    };
  }

  onSubmitSubForm({ alteracoesForm }, formProps) {
    formProps?.batch(() => {
      for (const [key, val] of Object.entries(alteracoesForm)) {
        formProps?.mutators?.setValue(`${key}`, val);
      }
    });
  }

  async componentDidMount() {
    const response = await resolverMultiRequestRecmin({
      fonteReferenciaTipo: {uri: "/tipoReferencia"},
      projetos: {uri: "/projetos"},
    });
    this.setState(response);
    this.projetosCopy.value = response.projetos;
  }

  linkObrigatorio(values) {
    if (values.modoCadastro === "SIMPLIFICADO") return true;
    if (values.modoCadastro === "DETALHADO") return false;
    if (values.modoCadastro === "BASE_PROJETOS" && (values.projetoSGP === undefined || values.projetoSGP === "")) return true;
  }

  projetoSGPObrigatorio(values) {
    if (values.modoCadastro === "BASE_PROJETOS" && (values.link === undefined || values.link === "")) return true;
  }

  setProjetoInitialValue() {
    if (!this.usarProjetoDefault) return;

    try {
      const localidade = JSON.parse(sessionStorage.getItem('localidade'));
      return localidade.idProjeto
    }
    catch (err) {
      return undefined;
    }
  }

  async preencherLink(link, formProps) {
    if (link && link.toLowerCase().includes('rigeo')) {
      formProps.form?.mutators?.setValue(`modoCadastro`,
        'BASE_PROJETOS');
      this.setState({}, () => {
        formProps.form?.mutators?.setValue(`modoCadastro`,
          'BASE_PROJETOS');
      });
    };
    if (link && link.toLowerCase().includes('doi.org')) {

      let arquivoJson = {
        autores: [],
        ano: "",
        volume: "",
        titulo: "",
        periodico: "",
        paginaInicial: "",
        paginaFinal: "",
        link: "",
        descricao: ""
      };

      let doi = link.split('doi.org/');
      doi = (doi[doi.length - 1]);
      let dadosDoi = {};
      await fetch('https://api.crossref.org/works/' + doi).then(res => res.json()).then(result => dadosDoi = result.message);

      if (dadosDoi.issued['date-parts']) {
        arquivoJson.ano = dadosDoi.issued['date-parts'][0][0];
      }


      if (formProps.form.getState().values.modoCadastro === "SIMPLIFICADO") {
        if (dadosDoi?.author?.length === 1) {
          arquivoJson.descricao = `${dadosDoi.author[0].family} (${arquivoJson.ano}).`
        } else if (dadosDoi?.author?.length === 2) {
          arquivoJson.descricao = `${dadosDoi.author[0].family} e ${dadosDoi.author[1].family} (${arquivoJson.ano}).`
        } else if (dadosDoi?.author?.length >= 3) {
          arquivoJson.descricao = `${dadosDoi.author[0].family} et al (${arquivoJson.ano}).`
        }
        formProps.form?.mutators?.setValue(`descricao`,
          arquivoJson.descricao);
        formProps.form?.mutators?.setValue(`link`,
          link);
      }

      if (formProps.form.getState().values.modoCadastro === "DETALHADO" || formProps.form.getState().values.modoCadastro === "BASE_PROJETOS") {
        dadosDoi?.author?.map(autor => arquivoJson.autores.push(autor.given + " " + autor.family));
        let doiPages = dadosDoi.page.split('-');

        arquivoJson.volume = dadosDoi.volume ?? "";
        arquivoJson.titulo = dadosDoi.title[0] ?? "";
        arquivoJson.paginaInicial = doiPages[0] ?? "";
        arquivoJson.paginaFinal = doiPages[1] ?? "";
        arquivoJson.link = link ?? "";
        arquivoJson.periodico = dadosDoi['short-container-title'][0] ?? ""

        formProps.form?.mutators?.setValue(`autores`,
          arquivoJson.autores?.toString().split(",").join(", "));
        formProps.form?.mutators?.setValue(`ano`,
          arquivoJson.ano);
        formProps.form?.mutators?.setValue(`volume`,
          arquivoJson.volume);
        formProps.form?.mutators?.setValue(`titulo`,
          arquivoJson.titulo);
        formProps.form?.mutators?.setValue(`periodico`,
          arquivoJson.periodico);
        formProps.form?.mutators?.setValue(`paginaInicial`,
          arquivoJson.paginaInicial);
        formProps.form?.mutators?.setValue(`paginaFinal`,
          arquivoJson.paginaFinal);
        formProps.form?.mutators?.setValue(`link`,
          arquivoJson.link);
        formProps.form?.mutators?.setValue(`modoCadastro`,
          'DETALHADO');
        this.setState({}, () => {
          formProps.form?.mutators?.setValue(`modoCadastro`,
            'DETALHADO');
        });
      }
    };

    this.setState({ link: link });
  }


  render() {
    return <Card className="mb-3">
      <Card.Body>
        <Field name={"fontesReferencia"} subscription={{ value: true }}>
          {({ input: { value: fontesReferencia = {} } }) => (
            <SubForm
              keepDirtyOnReinitialize
              tituloForm="Fonte de referência"
              nome="fontesReferencia"
              onSubmit={(alteracoesForm) => this.onSubmitSubForm(alteracoesForm, this.props.formPai.form)}
              colunas={this.colunasFonteReferenciaAtividadeExploracao}
              campos={this.datafieldsFonteReferenciaAtividadeExploracao}
              elementos={
                fontesReferencia
              }
              exibirAsterisco={this.props.modoDefinitivo}
              formSubscription={{}}
              podeExcluirEntidade={this.props.permitirEdicao}
              {...getPropsPermissao(this.props.permitirEdicao)}
              validar={(valores) => this.props.modoDefinitivo ? validaçãoBaseProjetosFonteReferencia(valores) : undefined}
              valoresIniciais={{
                modoCadastro: this.state.modoCadastroFonteReferencia[2].id
              }}
              renderForm={({ formProps }) => {
                return (
                  <>

                    <Field
                      component={HiddenField}
                      name='origem'
                      initialValue={this.props.origem.id}
                    />
                    <Field
                      component={HiddenField}
                      name='origemDescricao'
                      initialValue={this.props.origem.nome}
                    />

                    <Row>
                      <Col md={6}>
                        <Card.Title>Fonte de referência
                          { this.props.modoDefinitivo ? <span style={{ color: "red" }}>*</span> : <></> }
                        </Card.Title>
                      </Col>
                      <Col md={6}>
                        <FileButton
                          disabled={!this.props.permitirEdicao}
                          className="float-right mb-2"
                          titulo="Importar RIS"
                          handleFiles={(files) => {
                            this.handleFiles(files, formProps.form)
                          }
                          } fileTypes={[".ris"]}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3}>
                        <Container fluid className="p-0">
                          <Row>
                            <Col md={12}>
                              <legend className="w-auto small">
                                Modo de cadastro
                              </legend>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <Field name={"modoCadastro"} subscription={{ value: true }}>
                                {({ input: { value: modoCadastro } }) => {
                                  return (
                                    <Field
                                      disabled={!this.props.permitirEdicao}
                                      component={RadioButtonSetField}
                                      name="modoCadastro"
                                      elementos={
                                        this.state.modoCadastroFonteReferencia
                                      }
                                      onChange={() => {
                                        if (modoCadastro === 'SIMPLIFICADO' || modoCadastro === 'DETALHADO') {
                                          this.usarProjetoDefault = false;
                                          formProps?.form?.mutators?.setValue('idProjeto', null);
                                        }
                                      }}
                                    />
                                  )
                                }}</Field>

                            </Col>
                          </Row>
                        </Container>
                      </Col>

                      <Field name={"modoCadastro"} subscription={{ value: true }}>
                        {({ input: { value: modoCadastro } }) => {
                          return <Col md={9} className="p-0 m-0">
                            {modoCadastro === "SIMPLIFICADO" ? <Container
                              fluid
                            >
                              <Row>
                                <Col md={6}>
                                  <Field
                                    disabled={!this.props.permitirEdicao}
                                    label="Link"
                                    dica="Inserir a URL ou link para acesso à publicação (p. ex., DOI)"
                                    required={this.props.modoDefinitivo}
                                    name="link"
                                    initialValue=""
                                    maxLength={255}
                                    component={TextInputField}
                                    validate={validarSe(this.linkObrigatorio(formProps.form.getState().values) && this.props.modoDefinitivo, campoObrigatorioComMsgGenerica("Link"))}
                                    onBlur={(value) => {
                                      this.preencherLink(value, formProps);
                                    }}
                                  />
                                </Col>
                                <Col md={6}>
                                  <Field
                                    disabled={!this.props.permitirEdicao}
                                    label="Descrição"
                                    dica="Descreva de forma clara e sucinta a fonte da informação cadastrada"
                                    required={this.props.modoDefinitivo}
                                    maxLength={255}
                                    name="descricao"
                                    initialValue=""
                                    component={TextInputField}
                                    validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica(
                                      "Descrição"
                                    ))}
                                  />
                                </Col>
                              </Row>
                            </Container> : <></>}
                            {modoCadastro === "DETALHADO" ? <Container
                              fluid
                            >
                              <Row>
                                <Col md={4}>
                                    <Field
                                      disabled={!this.props.permitirEdicao}
                                      name="idTipo"
                                      component={DropdownListField}
                                      label="Tipo"
                                      dica="Selecione o tipo de publicação ou fonte de divulgação"
                                      elementos={this.state.fonteReferenciaTipo}
                                      required={this.props.modoDefinitivo}
                                      validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica(
                                        "Tipo"
                                      ))}
                                    />
                                  </Col>
                                  <Col md={4}>
                                  <Field
                                    disabled={!this.props.permitirEdicao}
                                    label="Autores"
                                    dica="Inserir os autores da publicação na ordem apresentada na mesma"
                                    required={this.props.modoDefinitivo}
                                    name="autores"
                                    initialValue=""
                                    maxLength={255}
                                    component={TextInputField}
                                    validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica(
                                      "Autores"
                                    ))}
                                  />
                                </Col>
                                <Col md={4}>
                                  <Field
                                    disabled={!this.props.permitirEdicao}
                                    component={NumberInputField}
                                    permitirNegativos={false}
                                    name="ano"
                                    label="Ano"
                                    dica="Informar o ano da publicação"
                                    casasInteiros={4}
                                    initialValue=""
                                    required={this.props.modoDefinitivo}
                                    validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica(
                                      "Ano"
                                    ))}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col md={4}>
                                  <Field
                                    disabled={!this.props.permitirEdicao}
                                    label="Título"
                                    dica="Informar o título da publicação"
                                    required={this.props.modoDefinitivo}
                                    name="titulo"
                                    initialValue=""
                                    maxLength={255}
                                    component={TextInputField}
                                    validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica(
                                      "Título"
                                    ))}
                                  />
                                </Col>
                                <Col md={4}>
                                  <Field
                                    disabled={!this.props.permitirEdicao}
                                    label="Periódico/ Veículo de divulgação"
                                    dica="Inserir o nome do periódico ou veículo de divulgação"
                                    name="periodico"
                                    initialValue=""
                                    maxLength={255}
                                    component={TextInputField}
                                  />
                                </Col>
                                <Col md={4}>
                                  <Field
                                    disabled={!this.props.permitirEdicao}
                                    label="Volume"
                                    dica="Informar o volume da publicação"
                                    name="volume"
                                    initialValue=""
                                    component={NumberInputField}
                                    permitirNegativos={false}
                                  />
                                </Col>
                              </Row>

                              <Row>
                                <Col md={4}>
                                  <Field
                                    disabled={!this.props.permitirEdicao}
                                    label="Página inicial"
                                    dica="Informar a página inicial da publicação"
                                    name="paginaInicial"
                                    initialValue=""
                                    casasInteiros={6}
                                    component={NumberInputField}
                                    permitirNegativos={false}
                                  />
                                </Col>
                                <Col md={4}>
                                  <Field
                                    disabled={!this.props.permitirEdicao}
                                    label="Página final"
                                    dica="Informar a página final da publicação"
                                    name="paginaFinal"
                                    initialValue=""
                                    casasInteiros={6}
                                    component={NumberInputField}
                                    permitirNegativos={false}
                                  />
                                </Col>
                                <Col md={4}>
                                  <Field
                                    disabled={!this.props.permitirEdicao}
                                    label="Link ou DOI"
                                    dica="Inserir a URL ou link para acesso à publicação"
                                    name="link"
                                    maxLength={255}
                                    initialValue=""
                                    component={TextInputField}
                                    validate={validarSe(this.linkObrigatorio(formProps.form.getState().values), campoObrigatorioComMsgGenerica("Link"))}
                                    onBlur={(value) => {
                                      this.preencherLink(value, formProps);
                                    }}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col md={4}>
                                  <Field
                                    disabled={!this.props.permitirEdicao}
                                    label="Instituição"
                                    dica="Informe a instituição responsável pela publicação"
                                    name="instituicao"
                                    maxLength={255}
                                    initialValue=""
                                    component={TextInputField}
                                  />
                                </Col>
                              </Row>
                            </Container> : <></>}
                            {
                              modoCadastro === "BASE_PROJETOS" ? <Container>
                                <Row>
                                  <Col md={6}> <Field
                                    disabled={!this.props.permitirEdicao}
                                    label="Projeto SGP"
                                    dica="Informe o projeto cadastrado no Sistema de Gerenciamento de Projetos que foi fonte da informação (pode ser este projeto)"
                                    name="idProjeto"
                                    initialValue={this.setProjetoInitialValue()}
                                    component={DropdownListField}
                                    elementos={this.state.projetos}
                                   
                                  /></Col>
                                  <Col md={6}> <Field
                                    disabled={!this.props.permitirEdicao}
                                    label="Link"
                                    dica="Inserir a URL ou link para acesso à publicação (p. ex., DOI)"
                                    name="link"
                                    maxLength={255}
                                    initialValue=""
                                    component={TextInputField}
                                  /></Col>

                                </Row>
                              </Container> : <></>}
                          </Col>
                        }}</Field>
                    </Row>
                  </>
                );
              }}
            />

          )}
        </Field>
      </Card.Body>
    </Card>

  }
}

export default FonteReferencia;
