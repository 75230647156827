
import aflora_tabela from '../../../../assets/img/aflora_tabela.png';
import amostra_tabela from '../../../../assets/img/amostra_tabela.png';
import recmin_tabela from '../../../../assets/img/recmin_tabela.png';
import petrografia_tabela from '../../../../assets/img/petrografia_tabela.png';

const formataData = (dataCriacao) => {
    const data = new Date(dataCriacao);
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    const localeData = data.toLocaleDateString("pt-BR", options);    
    return `<div class="jqx-grid-cell-left-align" style="margin-top: 8px;">${localeData}</div>`
};

const criaHintNumeroCampo = (rowdata, value) => {
    const responsavelTecnico = "Técnico responsável: " + rowdata.responsavelTecnico;
    return `<div title="${responsavelTecnico}" class=" d-flex justify-content-center align-items-center " style="margin-top: 8px;">${value}</div>`;
}

export const colunasAtividadesPendencias = [
    { text: "Subárea", datafield: "subArea", width: "12%", editable: false },
    {
        text: "Data de Cadastro", datafield: "dataCriacao", width: "15%", editable: false, cellsrenderer: (row, columnfield, value) => {
            return formataData(value);
        }
    },
    { text: "Visita", datafield: "numeroCampo", width: "15%", editable: false,
        cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
        return criaHintNumeroCampo(rowdata, value);
      },
    },
    {
        text: "Atividade", datafield: "tipo", width: "8%", editable: false,
        cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
            let tela = "";
            if (value === "CADASTRO_AMOSTRA") {
                tela = `<img style="height: 30px;width: 30px;" title="Cadastros de  Amostras" src="${amostra_tabela}" />`
            }
            if (value === "AFLORAMENTO") {
                tela = tela + `<img style="height: 30px;width: 30px" title="Cadastros de Afloramentos" src="${aflora_tabela}" />`
            }
            if (value === "RECURSO_MINERAL") {
                tela = tela + `<img style="height: 30px;width: 30px;" title="Cadastros de Recursos Minerais" src="${recmin_tabela}" />`
            }
            if (value === "PETROGRAFIA") {
                tela = tela + `<img style="height: 30px;width: 30px;" title="Cadastrar Análise Petrográfica" src="${petrografia_tabela}" />`
            }

            return `<div class=" d-flex justify-content-center align-items-center " style="margin-top: 2px;">${tela}</div>`;
        }
    },
    { text: "Descrição", datafield: "resumo", width: "30%", editable: false },
    { text: "Modo de Cadastro", datafield: "flagRascunho", width: "20%", editable: false }
];