import React, { useCallback, useEffect, useState } from "react";
import { RadioButtonSet } from "../../Jqx/RadioButtonSet";
import converterVetorParaObjetoIndexado from "../../../util/converterVetorParaObjetoIndexado";

const RadioButtonSetField = ({input, meta, elementos, extratorId,...props}) => {
  const { componentstate, ...finalProps } = props

  const onChange = useCallback((val) => {
    let el = document.querySelector( ':focus' );
    if( el ) el.blur();
    input.onChange(extratorId(val))

    if(props?.componentstate) {
      props?.componentstate(val)
    }
  }, [extratorId, input, props])

  const [cacheElementos, setCacheElementos] = useState({})

  useEffect(() => {
    setCacheElementos(converterVetorParaObjetoIndexado(elementos, {
      extratorId,
    }))
  }, [elementos, extratorId])

  const valor = !!input.value
    ? cacheElementos[input.value]
    : null

  const mensagemErro = meta.touched && !meta.valid && meta.error;

  return (
    <>
    <RadioButtonSet
      {...finalProps}
      nome={input.name}
      valor={valor}
      onClick={onChange}
      elementos={elementos}
      extratorId={extratorId}
      mensagemErro={mensagemErro}
    />
    </>
  )
}

RadioButtonSetField.propTypes = {
  ...RadioButtonSet.propTypes,
}

RadioButtonSetField.defaultProps = {
  ...RadioButtonSet.defaultProps,
}

export default RadioButtonSetField
